import { Component } from "react";
import EspecialidadService from "services/EspecialidadService";
import AsignaturasService from "services/AsignaturasService";
import AlumnosService from "services/AlumnosService";
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import PeriodosService from "services/PeriodosService";



export default class AsignacionMateriasAlumno extends Component{
    constructor(props){
        super(props);
        this.state={
            id_plantel:sessionStorage.getItem("plantel_id"),
            nombre_plantel:sessionStorage.getItem("nombre_plantel"),
            periodo:"",
            especialidades:[],
            asignaturas:[],
            alumnos:[],
            alumnosAsignados:[],
            asig:[],
            nombreEspecialidad:'',
            nombreAsignatura:'',
            alert:true,
            id_especialidad:0,
            id_asignatura:0
        }

        this.change_especialidad=this.change_especialidad.bind(this);
        this.change_asignatura=this.change_asignatura.bind(this);
        this.Asignaturas=this.Asignaturas.bind(this);
        this.AgregarAlumnoAsignatura=this.AgregarAlumnoAsignatura.bind(this);
        this.MoveAlumno=this.MoveAlumno.bind(this);
        this.filtrarbynombre=this.filtrarbynombre.bind(this);
        this.Guardar=this.Guardar.bind(this);
    }

    componentDidMount(){
        PeriodosService.PeridoActivoByPlantel(this.state.id_plantel).then((response)=>{
            console.log("PeriodoActivoByPLantel ",response.data )
            if(response.data.Status=="error"){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No esta activo el periodo que corresponde ',
                 });
            }
            else{
                this.setState({
                    periodo:response.data.id_tipo_periodo,
                    nombre_plantel:response.data.nombre_plantel,
                })
                if(response.data.nombre_plantel === "UNIVERSIDAD"){
                    EspecialidadService.CarrerasByTPeriodo(this.state.periodo).then((res) => {
                        this.setState({especialidades:res.data});
                        this.Asignaturas();   
                    });
                }
                else{
                    this.Asignaturas()
                }
            }
        })

    }



    async Asignaturas(){
        let id=""
        if(this.state.nombre_plantel==="UNIVERSIDAD"){
            var val = document.getElementById("id_especialidad");
            var NombreEspecialidad = val.options[val.selectedIndex].text;
            this.setState({nombreEspecialidad:NombreEspecialidad});
            id=document.getElementById("id_especialidad").value;
        }
        else{
            id = this.state.id_plantel
        }
		AsignaturasService.AsignaturaActivos(id, this.state.nombre_plantel).then((res)=>{
            this.setState({asignaturas:res.data});
            var count = Object.keys(this.state.asignaturas).length;
            console.log("recibe las aginaturas ----------------------------------",this.state.asignaturas)
            if(count>0){
                var valasig = document.getElementById("id_asignatura_docente");
                var Nombreasig = valasig.options[valasig.selectedIndex].text;
                this.setState({ nombreAsignatura:Nombreasig });
                this.AlumnosInscritosbyEsp();
            }
            else{
                this.setState({alumnos:[]});
                Swal.fire({
                    icon: 'warning',
                    title: 'warning',
                    text: 'No existen asignaturas activas',
                 });
            }
		});
	}

    change_especialidad=(event)=>{
        console.log("cambiar especialidad ")
        var val = document.getElementById("id_especialidad");
        var NombreEspecialidad = val.options[val.selectedIndex].text;
        this.setState({success:false, error:false, nombreEspecialidad:NombreEspecialidad, d_esp:event.target.value, id_especialidad:event.target.value}); 
		this.Asignaturas();
	}

    change_asignatura=(event)=>{
        var valasig = document.getElementById("id_asignatura_docente");
        var NombreAsig = valasig.options[valasig.selectedIndex].text; 
        this.setState({success:false, error:false,  nombreAsignatura:NombreAsig, id_asignatura:event.target.value}); 
        this.AlumnosInscritosbyEsp();
        }
  
	async AlumnosInscritosbyEsp(){
        let id_asignatura = document.getElementById("id_asignatura_docente").value
		AlumnosService.AlumnosInscritosbyEsp(document.getElementById("id_especialidad").value, id_asignatura, this.state.id_plantel).then((res)=>{
            this.setState({alumnos:res.data, alumnosAsignados:[], asig: []});
            console.log("alumnos a mostrar ",this.state.alumnos);
            if(res.data==""){
                Swal.fire({
                    icon: 'warning',
                    title: 'warning',
                    text: 'No hay alumnos para inscribir a la asignatura',
                 });
            }
		});
	}

    async AgregarAlumnoAsignatura(id_alumno_usuario,id_alumno,id_usuario,nombre,app_paterno,app_materno,matricula, clave_especialidad){
        let id_esp=0
        if(this.state.nombre_plantel === "UNIVERSIDAD"){
             id_esp=document.getElementById("id_especialidad").value;
        }
        let id_asig_doc=document.getElementById("id_asignatura_docente").value;
        this.state.asig.push({"id_especialidad":id_esp, "id_asignatura_doc":Number(id_asig_doc),"id_alumno_usuario":id_alumno_usuario, "id_alumno":id_alumno,"id_usuario":id_usuario,"nombre":nombre,"app_paterno":app_paterno, "app_materno":app_materno,"matricula":matricula, "clave_especialidad":clave_especialidad, "registerby":sessionStorage.getItem("id") });
        console.log("asig =======> ",this.state.asig)
        const listaentrante = this.state.alumnos.filter(item => item.id_alumno !== id_alumno);
        this.setState({alumnosAsignados:this.state.asig, alumnos:listaentrante});
    }

    async MoveAlumno(id_alumno,id_usuario,nombre,app_paterno,app_materno,matricula, clave_especialidad){
        const filtrarDatos = this.state.alumnosAsignados.filter(item => item.id_alumno !== id_alumno);
        let alumnosTemp = this.state.alumnos;
        alumnosTemp.push({"id_alumno":id_alumno,"id_usuario":id_usuario,"nombre":nombre,"app_paterno":app_paterno, "app_materno":app_materno,"matricula":matricula, "clave_especialidad":clave_especialidad, "registerby":sessionStorage.getItem("id") });
        this.setState({ alumnosAsignados: filtrarDatos, asig:filtrarDatos, alumnos:alumnosTemp });
    }

     filtrarbynombre=(event)=>{
        var textNombre = event.target.value;
        const data = this.state.alumnos;
        const newData = data.filter(function(item){
        const itemData = item.nombre.toUpperCase()
        const textData = textNombre.toUpperCase()
        return itemData.indexOf(textData) > -1
        })
       
        if(textNombre === ""){
			this.AlumnosInscritosbyEsp();
        } else {
          this.setState({
            alumnos: newData,
            textNombre: textNombre,
          })
        }
    }

    async Guardar(){
            console.log("alumnos asigandos ", this.state.alumnosAsignados);
            AlumnosService.CargaAlumnos(this.state.alumnosAsignados).then((res)=>{
            if(res.data===1){
                this.setState({alumnosAsignados:[], asig:[]});
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Se agregó correctamente el registro',
                    });
                    this.componentDidMount();
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al agregar el registro',
                    });
            }
        });
    }

    render(){
        return(
            <div className="bg-primary_2 text-white h-auto pb-20">
                <div className='relative bg-primary_2 p-4 sm:p-6 rounded-sm overflow-hidden mb-8'>
						<div className='relative text-center'>
							<h1 className='text-2xl md:text-3xl text-white  font-bold mb-1 text-center'>
                                Asignación de materias a los alumnos
							</h1>
						</div>
					</div>


                    <div className=" w-full h-24 z-10 bg-primary_2 grid grid-cols-1 ">
                        <div className="col-span- w-full flex items-center">
                            <nav class="flex relative" aria-label="Breadcrumb">
                                <ol class="flex items-center pl-10">
                                    <li class="inline-flex items-center">
                                        <a href="/" class="inline-flex items-center text-sm dark:text-white group">
                                            <svg class="mr-2 w-4 h-4 text-white group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                            <a className="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Home</a >
                                        </a>
                                    </li>
                                    <li>
                                        <div class="flex items-center">
                                            <button >
                                            <NavLink to={{
                                                pathname: '/app/CargaAcademicoByPeriodos'}} 
                                                className="inline-flex sm:ml-3 mt-6 sm:mt-0 items-start justify-start px-1 py-1 bg-blue hover:bg-red focus:outline-none rounded transition transform duration-500 "> 
                                               <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                               <a  class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Nivel académico</a>
                                            </NavLink> 
                                            </button>
                                         </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center">
                                            <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                            <a class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Asignación de asignaturas</a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                {
                    this.state.nombre_plantel === "UNIVERSIDAD" ? 
                    <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 ">
                    <div>
                            <label className="flex item-start md:text-sm text-xs  text-white font-semibold  ">
                                Seleccionar especialidad
                            </label>

                            <select class="flex text-black  items-end py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                name="id_especialidad"  
                                id="id_especialidad" 
                                onChange={this.change_especialidad}
                                required>
                                {
                                    this.state.especialidades.map(
                                        especialidades=>
                                            <option value={especialidades.id_especialidad}>{especialidades.nombre_especialidad}</option> 
                                    )
                                }
                            </select>
                    </div>
                </div> :" "
                }
   

                <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 " >
                    <div>
                        <label className="flex item-start md:text-sm text-xs text-white font-semibold ">
                                Seleccionar asignatura
                            </label>
                            <select class="flex items-end text-black py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                name="id_asignatura_docente"  
                                id="id_asignatura_docente" 
                                onChange={this.change_asignatura}
                                required>
                                {
                                    this.state.asignaturas.map(
                                        asignatura=>
                                        <option value={asignatura.id_asignatura_docente}>{asignatura.Clave_asignatura+" - "+asignatura.nombre_asignatura}</option>
                                    )
                                }
                            </select>   
                    </div>
                </div>

                <div className="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
                    <div><br/>           
                         <h1 className="font-semibold text-white text-center ">Lista de alumnos</h1><br/>
                         <div className=" ">
                            <label className=" font-bold text-white "> Buscar <i class="fa fa-search" aria-hidden="true"></i></label>
                            <input class="bg-purple-white shadow rounded border-0 p-1 ml-2 transition-all duration-300 ease-in-out outline-none focus:ring-2 focus:ring-secondary text-secondary" type="search" name="caja_busqueda" id="caja_busqueda" placeholder="Ingrese la busqueda" title="Presione Esc para supender la búsqueda"  onChange={(text) => this.filtrarbynombre(text)} />
                        </div>

                         <div class="px-2 md:px-10 md:pt-7 pb-5 overflow-y-auto">
                            <table className="w-full border-collapse border border-slate-400 table-auto my_table">
                                <thead className="h-10">
                                    <tr className="h-8 w-full text-sm leading-none text-gray-800 font-semibold">               
                                        <th className="font-semibold text-center pl-1 text-left">Nombre <br/>
                                        </th>
                                        <th className="font-semibold text-center pl-1 ">Matricula</th>
                                        <th className="font-semibold text-center pl-1 ">Especialidad</th>
                                        <th className="font-semibold text-center pl-1 "></th>                 			
                                    </tr>
                                </thead>

                                {
									this.state.alumnos.map(
										(alumno, index) =>
										<tbody className="text-left">
										<tr key={index}>
                                            <td>
                                                <p className="text-left text-sm font-medium leading-none ">{alumno.nombre+" "}{alumno.app_paterno+" "}{alumno.app_materno}</p>  										
                                            </td>

                                            <td className="pl-10">
                                            <p className="font-medium">{alumno.matricula}</p>
                                            </td>

                                            <td className="pl-10">
                                            <p className="font-medium">{alumno.clave_especialidad}</p>
                                            </td>
                                        
                                            <td>
                                                <div class="mr-2 transform hover:text-secondary hover:scale-110">
                                                    <button onClick={()=>this.AgregarAlumnoAsignatura(alumno.id_alumno_usuario ,alumno.id_alumno, alumno.id_usuario,alumno.nombre,alumno.app_paterno,alumno.app_materno,alumno.matricula, alumno.clave_especialidad )} 
                                                        title="Agregar alumno a la asignatura"> 
                                                        <svg class="h-8 w-8 text-teal-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  
                                                        stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"> 
                                                        <polyline points="13 17 18 12 13 7" /><polyline points="6 17 11 12 6 7" /></svg>                                                       
                                                    </button>  
                                                </div>
                                            </td>
										</tr>
									</tbody>
									)
								}
                            </table><br/><br/>
                        </div><br/>     
                    </div>

                    <div><br/>
                        <div className="ml-10">
                            {
                                this.state.nombre_plantel === "UNIVERSIDAD" ? 
                                <div>
                                    <strong>Especialidad seleccionada:</strong>
                                    <h1 className="text-secondary">{" "+this.state.nombreEspecialidad}</h1>
                                </div> :""  
                            }
                            <strong>Asignatura seleccionada:</strong>
                            <h1 className="text-secondary">{" "+this.state.nombreAsignatura}</h1><br/>
                            <p align="center"><strong>Alumnos asignados a la asignatura</strong></p>   
                        </div>

                        <div class="px-2 md:px-10 md:pt-7 pb-5 overflow-y-auto">
                            <table className="w-full border-collapse border border-slate-400 table-auto my_table" >
                                <thead className="h-10">
                                    <tr className="h-8 w-full text-sm leading-none text-gray-800 font-semibold">   
                                        <th className="font-semibold text-center pl-1 "></th>        
                                        <th className="font-semibold text-center pl-1 ">Nombre <br/>
                                        </th>
                                        <th className="font-semibold text-center pl-1 ">Matricula</th>
                                        <th className="font-semibold text-center pl-1 ">Grado académico</th>
                                        <th className="font-semibold text-center pl-1 "></th>                 			
                                    </tr>
                                </thead>
                                    {   this.state.alumnosAsignados.map(
                                        (asignatura_Asignada, index)=>
                                        <tbody>
                                            <tr key={index}>
                                                <td className="pl-10">
                                                    <div class="mr-2 transform hover:text-purple-500 ">
                                                        <button onClick={()=>this.MoveAlumno(asignatura_Asignada.id_alumno, asignatura_Asignada.id_usuario,asignatura_Asignada.nombre,asignatura_Asignada.app_paterno,asignatura_Asignada.app_materno,asignatura_Asignada.matricula,asignatura_Asignada.clave_especialidad)} title="Quitar alumno"> 
                                                        <svg class="h-6 w-6 text-red-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                        </svg>                          
                                                        </button>  
                                                    </div>
                                                </td>
                                                <td className="pl-10">
                                                    <p className="text-left text-sm font-medium leading-none ">{asignatura_Asignada.nombre+" "+asignatura_Asignada.app_paterno+" "+asignatura_Asignada.app_materno}</p>
                                                </td>
                                                <td className="pl-10">
                                                    <p className="font-medium">{asignatura_Asignada.matricula}</p>
                                                </td>
                                                <td className="pl-10">
                                                    <p className="font-medium">{asignatura_Asignada.clave_especialidad}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                        )
                                    }
                            </table><br/><br/>
                        </div><br/>  
                        <div class="mr-10 flex flex-row-start sm:flex items-center flex items-center  justify-between ">
                            <button onClick={this.Guardar} class="bg-color1 hover:bg-secondary text-white font-bold py-2 px-4 rounded">
                                Guardar
                            </button> 
                        </div>
                    </div>

                </div><br/><br/>


            </div>
        );
    }
}