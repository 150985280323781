import { Component, useState } from "react";
import { withRouter } from "react-router";
import GestorPagoService from "services/GestorPagoService";
import AguilaCaida from "../images/aguila_caida.svg"
import swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';

class GestionPagoTutor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            planteles: [],
            tutorados: [],
            orgtableData: [],
            nombret: "",
            id_tutorado: "",
            cantidadt: "",
            folio_ticked: "",
            vacio: true,
            mdalForm: false,
            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageCount: "",

        }

        this.goBack = this.goBack.bind(this);
        // this.change_periodo = this.change_periodo.bind(this);
        this.change_especilidad = this.change_especilidad.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.filter_busqueda = this.filter_busqueda.bind(this);

    }

    goBack() {
        this.props.history.goBack();
    }

    handlePageClick = (e) => {
        
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadMoreData();
        });

    };

    loadMoreData() {
        const data = this.state.orgtableData;

        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            tutorados: slice
        })

    }

    componentDidMount() {
        GestorPagoService.ListarPlanteles().then((res) => {
            if (res.data == 0) {
                swal.fire({
                    imageUrl: AguilaCaida,
                    title: 'Error',
                    text: 'No hay planteles',
                });
                this.goBack();
            }
            else {
                this.setState({ planteles: res.data });
            }
        })
    }

    change_especilidad = (event) => {
        let ide = event.target.value;
        let status = 0;
        GestorPagoService.ListaTutorados(ide, status).then((res) => {
            if (res.data == 0) {
                swal.fire({
                    imageUrl: AguilaCaida,
                    title: 'Error',
                    text: 'No hay alumnos',
                });
                this.setState({ tutorados: [] });

            }
            else {
                var data = res.data;
                var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage);
                this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    orgtableData: res.data,
                    tutorados: slice,
                    vacio: false
                })

            }
        })
    }

    Modalpagar(nombre, id_t, cantidad) {

        this.setState({ mdalForm: true }, () => {
            this.setState({ nombret: nombre, id_tutorado: id_t, cantidadt: cantidad }, () => {

            });
        });


    }

    Pagar = (event) => {
        event.preventDefault();
        var preview = document.querySelector('img');
        this.state.file = document.getElementById('archivo').files[0];
        var reader = new FileReader();
        reader.onload = this.handleFile;
        if (this.state.file) {
            reader.readAsDataURL(this.state.file);
        } else {
            preview.src = "";
            swal.fire({
                imageUrl: "https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
                imageHeight: '150',
                title: 'Error',
                text: 'Falta agregar el comprobante de pago',
            });
        }
    }

    handleFile = (e) => {

        const content = e.target.result;
        var filePreview = document.createElement('img');
        filePreview.id = 'file-preview';

        const parts = this.state.file.name.split('.')
        const extension = parts[parts.length - 1]
        var base64image = e.target.result;
        base64image = base64image.split("base64,")[1];

        var com = null;
        if (extension == 'pdf') {
            com = "data:application/pdf;base64," + base64image;
        }
        else {
            com = "data:image/jpg;base64," + base64image;
        }
        console.log("imagen base64 *** *" + base64image);
        let JSONPago = {
            cantidad: this.state.cantidadt,
            folio_ticked: this.state.folio_ticked,
            id_tutorado: this.state.id_tutorado,
            comprobanteBase64: com,
        }
        console.log("datos a enviar ", JSONPago);
        GestorPagoService.EnviarPago_Tutor(JSONPago).then((res) => {
            if (res.data > 0) {
                swal.fire({
                    imageUrl: "https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
                    imageHeight: '150',
                    text: 'Registro agregado correctamente',
                });
                this.setState({ mdalForm: false });
                this.componentDidMount();
            }
            else {
                swal.fire({
                    imageUrl: "https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
                    imageHeight: '150',
                    title: 'Error',
                    text: 'Error al agregar el registro',
                })
            }
        })
    }

    CalucularMes(mes) {
        switch (mes) {
            case 1:
                {
                    mes = "enero";
                    break;
                }
            case 2:
                {
                    mes = "febrero";
                    break;
                }
            case 3:
                {
                    mes = "marzo";
                    break;
                }
            case 4:
                {
                    mes = "abril";
                    break;
                }
            case 5:
                {
                    mes = "mayo";
                    break;
                }
            case 6:
                {
                    mes = "junio";
                    break;
                }
            case 7:
                {
                    mes = "julio";
                    break;
                }
            case 8:
                {
                    mes = "agosto";
                    break;
                }
            case 9:
                {
                    mes = "septiembre";
                    break;
                }
            case 10:
                {
                    mes = "octubre";
                    break;
                }
            case 11:
                {
                    mes = "noviembre";
                    break;
                }
            case 12:
                {
                    mes = "diciembre";
                    break;
                }
            default:
                {
                    mes = "Error";
                    break;
                }
        }
        return mes;
    }

    filter_busqueda = (event) => {
        var textNombre = event.target.value
        const data = this.state.orgtableData;
        const newData = data.filter(function (item) {
            const itemData = item.Nombre.toUpperCase()
            const textData = textNombre.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        if (textNombre === "") {
            this.componentDidMount();
        } else {
            this.setState({
                tutorados: newData,
                textNombre: textNombre,
            })
        }
    }



    render() {
        return (
            <>
                <div>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">pagos</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">actual</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div className="relative p-4 sm:p-6 rounded-sm overflow-hidden mb-2">
                        <div className="relative text-center">
                            <h1 className="text-2xl uppercase md:text-3xl text-white font-bold mb-1 text-center">
                                PAGO ACTUAL
                            </h1>
                        </div>
                    </div>

                    <div class="grid grid-cols-1 md:grid-cols-2 gap-2 px-8">
                        <div className="grid justify-items-start">
                            <strong className="text-white">Planteles:</strong>
                            <select class="flex items-end py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent"
                                name="especialidad"
                                id="especialidad"
                                onChange={this.change_especilidad}
                                required>
                                <option value="">seleccione una opción</option>
                                {
                                    this.state.planteles.map(
                                        plantel =>
                                            <option value={plantel.ID_Plantel}>{plantel.Nombre}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="grid justify-items-end">
                            <div className="flex flex-wrap items-end">
                                <input onChange={(text) => this.filter_busqueda(text)}
                                    type="search"
                                    name="caja_busqueda"
                                    id="caja_busqueda"
                                    placeholder="Ingrese la busqueda"
                                    title="Presione Esc para supender la búsqueda"
                                    class="form-control relative flex-auto px-3 py-1.5 text-base font-normal text-black bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none"
                                />
                                <label><svg class="h-6 w-6 text-white ml-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg></label>

                            </div>
                        </div>

                    </div>


                    <div className="mx-10 my-4 overflow-x-auto">
                        <table className="w-full whitespace-nowrap my_table">
                            <thead className="h-10">
                                <tr>
                                    <th className="border border-slate-300">Nombre</th>
                                    <th className="border border-slate-300">Cantidad</th>
                                    <th className="border border-slate-300">Matricula</th>


                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.state.tutorados.map(
                                        tutorado =>

                                            <tr className="h-20 text-sm leading-none text-gray-800 border border-slate-300">

                                                <td className="pl-2 text-left">

                                                    <p className="font-medium">{tutorado.Nombre + " " + tutorado.App + " " + tutorado.Apm}</p>

                                                </td>

                                                <td className="pl-2 text-left">

                                                    <p className="font-medium">{"$" + " " + tutorado.Cantidad}</p>


                                                </td>


                                                <td className="pl-2 text-center">

                                                    {
                                                        tutorado.Status == 0 && tutorado.Cantidad != 0 ? (
                                                            <button className="miboton" onClick={() => this.Modalpagar(tutorado.Nombre, tutorado.id_tutorado, tutorado.Cantidad)} title="Detalles alumno">
                                                                <i class="fas fa-money-bill-alt"></i> pagar
                                                            </button>

                                                        ) :
                                                            tutorado.Status == 0 && tutorado.Cantidad == 0 ? (
                                                                <span class="bg-green-800 text-white text-md font-semibold mr-2 px-4 py-2 rounded dark:bg-gray-700 dark:text-gray-300">
                                                                    <i class="fas fa-thumbs-up"></i> pagado
                                                                </span>

                                                            ) :
                                                                <span class="bg-gray-100 text-primary_2 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                                                    <i class="fas fa-exclamation-triangle"></i> próximo a pagar
                                                                </span>

                                                    }


                                                </td>


                                            </tr>
                                    )
                                }

                            </tbody>
                            {this.state.vacio ? (
                                <>
                                    <tr>
                                        <th scope="col" colspan="6" class="px-6 py-3 text-center">
                                            <div class="flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                                                <svg class="inline flex-shrink-0 mr-3 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                                                <div>
                                                    Seleccione una opción.
                                                </div>
                                            </div>
                                        </th>
                                    </tr>

                                </>
                            ) : null}

                        </table>

                        <div className="grid justify-items-end">

                            <div>
                                <ReactPaginate
                                    previousLabel={"anterior"}
                                    nextLabel={"siguiente"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"} />

                            </div>


                        </div>



                        {this.state.mdalForm ? (
                            <>
                                <form onSubmit={this.Pagar}>
                                    <div div class="overflow-y-auto h-32">
                                        <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                                {/*content*/}
                                                <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                                    {/*header*/}
                                                    <div className=' rounded-t-3xl'>
                                                        <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Detalles del pago</p>
                                                    </div>
                                                    {/*body*/}
                                                    <div className='relative flex-auto'>
                                                        <div class='grid grid-cols-1 gap-5 px-6 py-5 '>
                                                            <label className='md:text-sm text-gray-500 text-light font-bold  text-primary text-sm'>
                                                                Nombre completo
                                                            </label>
                                                            <p class='mb-2 font-semibold text-secondary '>
                                                                {this.state.nombret}
                                                            </p>

                                                            <label className='md:text-sm text-gray-500 text-light font-bold text-primary text-sm' >
                                                                Folio del ticket
                                                                <span className='text-red-500 text-xl'>*</span>
                                                            </label>
                                                            <input
                                                                className='mt-1 w-1/2 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                type='text'
                                                                name='folio_ticked'
                                                                onChange={event => this.setState({ folio_ticked: event.target.value })}
                                                                required
                                                            />

                                                            <label className='md:text-sm text-gray-500 text-light font-bold text-primary text-sm'>
                                                                Cantidad de pago mensual
                                                                <span className='text-red-500 text-xl'>*</span>
                                                            </label>
                                                            <input
                                                                className='mt-1 w-1/2 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                type='text'
                                                                name='cantidad'
                                                                onChange={event => this.setState({ cantidadt: event.target.value })}
                                                                required
                                                            />

                                                            <label className='md:text-sm text-gray-500 text-light font-bold text-primary text-sm' >
                                                                Agregar documento
                                                                <span className='text-red-500 text-xl'>*</span>
                                                            </label>
                                                            <input
                                                                type="file"
                                                                src="#"
                                                                name="archivo"
                                                                id="archivo"
                                                                accept=" image/png, .jpeg, .jpg, .pdf "
                                                                required></input>


                                                        </div>
                                                    </div>
                                                    {/*footer*/}
                                                    <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
                                                        <button
                                                            className='bg-emerald-500 text-black active:bg-gray-600 font-bold uppercase text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                            type='submit'>
                                                            pagar
                                                        </button>

                                                        <button
                                                            className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                            type='button'
                                                            onClick={() => this.setState({ mdalForm: false })}>
                                                            Cerrar
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                            <div onClick={() => this.setState({ mdalForm: false })} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
                                        </div>
                                    </div>
                                </form>
                            </>
                        ) : null}

                    </div>

                </div>


            </>
        );
    }

}

export default withRouter(GestionPagoTutor);