import { Component } from "react";
import { withRouter } from "react-router";
import GestorPagoService from "services/GestorPagoService";
import AguilaCaida from "../images/aguila_caida.svg"
import swal from 'sweetalert2';
import { Link } from "react-router-dom";

class GestionPagoActual extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id_plantel:sessionStorage.getItem("id_plantel"),
            nombre_plantel: sessionStorage.getItem("nombre_plantel"),
            especialidades: [],
            periodos: [],
            pagos: [],
            id_periodo: "",
            vacio: false,
            vacio_busca: false,
        }

        this.goBack = this.goBack.bind(this);
        // this.change_periodo = this.change_periodo.bind(this);
        this.change_especilidad = this.change_especilidad.bind(this);

    }

    goBack() {
        this.props.history.goBack();
    }

    componentDidMount() {
        console.log("id_plantel "+this.state.id_plantel)
        console.log("nombre_plantel "+this.state.nombre_plantel)
        this.PagoActual()   
    }

    PagoActual = async() =>{
        let status = "A"

        GestorPagoService.GestionHistorialPagos(status,this.state.id_plantel).then((res) => {
            if (res.data == 0) {
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No hay registros de pagos',
                });
                this.goBack()
            }
            else {
                this.setState({ periodos: res.data })
                if(this.state.nombre_plantel === "UNIVERSIDAD"){
                    GestorPagoService.ListarCarreras().then((res) => {
                        this.setState({ especialidades: res.data });
                    })  
                }
                else{
                    var json = Object.assign({}, this.state.periodos)
                    let id_periodo2 = json[0].ID_Periodo
                    GestorPagoService.HistorialPagosGestionEscolarizado(id_periodo2, this.state.id_plantel).then((res)=>{
                        var countdatos = Object.keys(res.data).length;
                        if( countdatos > 0){
                            console.log("pagos escolarizado == ",res.data)
                            this.setState({pagos:res.data})
                            
                        }
                        else{
                            swal.fire({
                                icon: 'warning',
                                title: 'Warning',
                                text: 'No existen pagos de periodo actual',
                            });
                        }
                    })
                }

            }
        })
    }

 

    change_especilidad = (event) => {
        let ide = event.target.value;
        var json = Object.assign({}, this.state.periodos);
        let id_periodo2 = json[0].ID_Periodo
        GestorPagoService.HistorialPagosGestion(ide, id_periodo2).then((res) => {
                if (res.data == 0) {
                    this.setState({ vacio: true, vacio_busca: false });
                } else {
                    this.setState({ vacio: false, vacio_busca: true });
                }
                console.log("pagos =========== ",res.data)
                this.setState({ pagos: res.data });
            })
    }

    CambiarFormato = (fecha)=>{
		var f =new Date(fecha);
		var anio=f.getFullYear();
		var mes=f.getMonth() + 1;
		var dia=f.getDate()+1;

        var date;
        if(mes>=10 && dia>=10){
			date = anio + '-' + (mes) + '-' + dia;  
        }
        else if(mes<10 && dia<10){
			date = anio + '-0' + (mes) + '-' +  '0' + dia ; 
        }
        else if(mes>=10 && dia<10){
			date = anio + '-' + (mes) + '-' + '0' +dia ; 
        }
        else if(mes<10 && dia>=10){
			date = anio + '-0' + (mes) + '-' + dia;    
        }
        return date;
    }

    render() {
        return (
            <>
                <div>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                <div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">pagos</a>
								</div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">actual</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div className="relative p-4 sm:p-6 rounded-sm overflow-hidden mb-2">
                        <div className="relative text-center">
                            <h1 className="text-2xl uppercase md:text-3xl text-white font-bold mb-1 text-center">
                                PAGO ACTUAL
                            </h1>
                        </div>
                    </div>

                   {
                       this.state.nombre_plantel === "UNIVERSIDAD" ?  <div class="grid grid-cols-2 gap-2 px-8">
                       <div>
                           <strong className="text-white">Especialidad:</strong><br />
                           <select class="flex items-end py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent"
                               name="especialidad"
                               id="especialidad"
                               onChange={this.change_especilidad}
                               required>
                               <option value="">seleccione una opción</option>
                               {
                                   this.state.especialidades.map(
                                       (especialidad, index )=>
                                           <option key={index} value={especialidad.ID_Especialidad}>{especialidad.Nombre_Especialidad}</option>
                                   )
                               }
                           </select>
                       </div>
                   </div> :" "
                   }
{/* 
                    {this.state.vacio_busca ? (
                        <div className="mx-10 mt-6 flex-row-reverse sm:flex items-center lg:flex grid grid-cols-1  justify-between">
                            <div className="cont">
                                <label>Buscar <i class="fa fa-search" aria-hidden="true"></i></label>
                                <input onChange={(text) => this.filter_busqueda(text)} type="search" name="caja_busqueda" id="caja_busqueda" placeholder="Ingrese la busqueda" title="Presione Esc para supender la búsqueda" />
                            </div>
                        </div>
                    ) : null}
 */}


                    <div className="mx-10 my-4 overflow-x-auto">
                        <table className="w-full whitespace-nowrap my_table">
                            <thead className="h-10">
                                <tr>
                                    <th className="border border-slate-300">Nombre</th>
                                    <th className="border border-slate-300">Matricula</th>
                                    <th className="border border-slate-300">Periodo</th>
                                    <th className="border border-slate-300">Acciones</th>

                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.state.pagos.map(
                                        (pago,index )=>

                                            <tr key={index} className="h-20 text-sm leading-none text-gray-800 border border-slate-300">

                                                <td className="pl-2 text-left">
                                                    <p className="font-medium">{pago.nombre + " " + pago.app_paterno + " " + pago.app_materno}</p>
                                                </td>

                                                <td className="pl-2 text-left">
                                                    <p className="font-medium">{pago.matricula}</p>
                                                </td>

                                                <td className="pl-2 text-left">
                                                    <p className="font-medium">{this.CambiarFormato(pago.fecha_inicio) +" - "+this.CambiarFormato( pago.fecha_fin)}</p>
                                                </td>
                                               {/*
                                                <td className="pl-2 text-left">
                                                    <p className="font-medium">{(pago.Fecha_Inicio.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(pago.Fecha_Inicio.split("-")[1])) + "-" + pago.Fecha_Inicio.split("-")[0] + " " + "al" + " " + (pago.Fecha_Fin.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(pago.Fecha_Fin.split("-")[1])) + "-" + pago.Fecha_Fin.split("-")[0]}</p>
                                                </td> */}
                                                <td className="pl-2 text-left">
                                                    <div className="grid grid-cols-1 content-center">
                                                    <p className="font-medium">{pago.Matricula}</p>
                                                        <Link class="flex justify-center items-center no-underline" to={{
                                                            pathname: '/app/GestionMensualidades',
                                                            state: {
                                                                id_periodo: pago.id_periodo,
                                                                id_au: pago.id_alumno_usuario,
                                                                nombre: pago.nombre + " " + pago.app_paterno+ " " + pago.app_materno
                                                            }
                                                        }} rel="noopener noreferrer" >
                                                            <div className="miboton">
                                                       
                                                                <p className="text-sm font-medium leading-none"><i class="fas fa-calendar-alt"></i>  ver mensualidades</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </td>

                                            </tr>
                                    )
                                }

                            </tbody>
                            {this.state.vacio ? (
                                <>
                                    <tr>
                                        <th scope="col" colspan="6" class="px-6 py-3 text-center">
                                            <div class="flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                                                <svg class="inline flex-shrink-0 mr-3 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                                                <div>
                                                    No hay pagos registrados.
                                                </div>
                                            </div>
                                        </th>
                                    </tr>

                                </>
                            ) : null}

                        </table>

                    </div>

                </div>


            </>
        );
    }

}

export default withRouter(GestionPagoActual);