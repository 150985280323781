import React, { Component } from "react";
import { withRouter } from "react-router";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import CatalogoDireccionService from "services/CatalogoDireccionService";
import EspecialidadService from "services/EspecialidadService";
import DocentesService from "services/DocentesService";
import UsuariosService from "services/UsuariosService";

class NuevoDocente extends Component{
    constructor(props) {
		super(props);
		this.state = {
            datos:{
                nombre: "",
                app_paterno: "",
                app_materno: "",
                curp: "",
                rfc: "",
                fecha_nacimiento: "",
                nac_estado: "",
                nac_municipio: "",
                nac_localidad: '',
                nacionalidad: "",
                genero: "DEFAULT",
                correo_electronico:'',
                telefono1:'',
                telefono2:'',

                clave_docente:'',
                perfil_prof:'',
                cedula_prof:'',
                grado_academico:'',
                password:'',
            },
            Estados: [],
            Municipios: [],
            Localidades: [],
            roles:[],
            log:localStorage.getItem('roles'),
            especialidades:[],
        }
        this.RegistrarDocente = this.RegistrarDocente.bind(this);
        this.change_Estado=this.change_Estado.bind(this);
        this.change_Municipio=this.change_Municipio.bind(this);
        this.change_localidad=this.change_localidad.bind(this);
        this.rol=this.rol.bind(this);
    }

    change_Estado =(event)=>{ 
        this.setState({nac_estado:event.target.value});
        console.log("id estado",this.state.nac_estado);
        this.cargaCatalogo("municipios", this.state.nac_estado);
    }
    change_Municipio =(event)=>{ 
        this.setState({nac_municipio:event.target.value});
        console.log("id municipio",this.state.nac_municipio);
        this.cargaCatalogo("localidades",{estado:this.state.nac_estado,municipio:this.state.nac_municipio});
    }
    change_localidad=(event)=>{
        console.log("localidad entrante "+event.target.value);
        this.state.nac_localidad=event.target.value;
        this.setState({nac_localidad:event.target.value});
        console.log("localidad "+this.state.nac_localidad);
    }

    
	cargaCatalogo(opt, id) {
		switch (opt) {
			case "estados":
				CatalogoDireccionService.getEstados().then((res) => {
					//console.log(`estat: `, res.data);
					this.setState({Estados: res.data});
				});
				break;

			case "municipios":
				CatalogoDireccionService.getMunicipios(id).then((res) => {
					this.setState({Municipios: res.data});
				});
				break;
			case "localidades":
				CatalogoDireccionService.getLocalidades(id.estado, id.municipio).then((res) => {
                    //console.log("id municipio ", id);
					this.setState({Localidades: res.data});
                    //console.log("localidades ",this.state.Localidades);
				});
				break;

			default:
				break;
		}
	}


    componentDidMount() {
        EspecialidadService.ListarCarreras().then((res) => {
			this.setState({especialidades:res.data});
		});    
       this.cargaCatalogo("estados", {name: "Estados"});
       this.rol();
	}

    rol(){
        UsuariosService.listarRoles().then((res)=>{
			console.log("listar roles ",res.data);
			this.setState({roles:res.data});
		});
    }

    RegistrarDocente =(event)=>{
        event.preventDefault();
        let docente={
			nombre:this.state.nombre,
			app_paterno:this.state.app_paterno,
			app_materno: this.state.app_materno,
			curp:this.state.curp,
            rfc:this.state.rfc,
			fecha_nacimiento:this.state.fecha_nacimiento,
			nac_estado:this.state.nac_estado,
			nac_municipio:this.state.nac_municipio,
            nac_localidad:this.state.nac_localidad,
            sexo:this.state.sexo,
            nacionalidad:this.state.nacionalidad,
            correo_electronico: this.state.correo_electronico,
            telefono1:this.state.telefono1,
            telefono2:this.state.telefono2,

            clave_docente:this.state.clave_docente,
            perfil_prof:this.state.perfil_prof,
            cedula_prof:this.state.cedula_prof,
            grado_academico:this.state.grado_academico,
            password:this.state.password,
		};
        console.log("docente a registrar ",docente);
        DocentesService.NuevoDocente(docente).then((res)=> {
            if(res.data>0){
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Registro agregado correctamente',
                });
                this.props.history.push("/app/ListaDocentes");
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al agregar el registro',
                  })
            }
        });
    }

    render() {
		return (
            <>
                <div className="px-4 md:px-10 py-4 md:py-7 bg-color1">
					<div className="sm:flex items-center justify-between">
                        <h1 className="md:text-3xl text-2xl font-bold text-white">Agregar docente</h1>
					</div>
				</div>

			
				<form className="newUserForm px-5 bg-color4 pt-5" onSubmit={this.RegistrarDocente}>
					<h2 className="md:text-xl text-md text-gray-500 text-light font-semibold mt-5 mb-5 text-center">
						Datos generales
					</h2>

                    <div className='relative p-6 flex-auto'>                      
                            <div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 '>
                                <div >
                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Nombre(s){" "}
                                        <span className='text-red-500 text-xl'>*</span><br/>
                                    </label>
                                    <input
                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                        type='text'
                                        name='nombre'
                                        value={this.state.nombre}
                                        onChange={event => this.setState({nombre:event.target.value})}
                                        required
                                    />
                                </div>

                                <div >
                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Apellido paterno{" "}
                                        <span className='text-red-500 text-xl'></span><br/>
                                    </label>
                                    <input
                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                        type='text'
                                        name='app_Paterno'
                                        value={this.state.app_paterno}
                                        onChange={event=>this.setState({app_paterno:event.target.value})}
                                        required
                                    />
                                </div>

                                <div >
                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Apellido Materno{" "}
                                        <span className='text-red-500 text-xl'></span><br/>
                                    </label>
                                    <input
                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                        type='text'
                                        name='app_Materno'
                                        maxLength='45'
                                        value={this.state.app_materno}
                                        onChange={event=>this.setState({app_materno:event.target.value})}
                                        required
                                    />
                                </div>
                            </div>

                            <div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-2'>
                                <div>
                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Fecha de nacimiento
                                        <span className='text-red-500 text-xl'></span><br/>
                                    </label>
                                    <input
                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                        type='date'
                                        name='fechaNacimiento'
                                        value={this.state.fecha_nacimiento}
                                        onChange={event=>this.setState({fecha_nacimiento:event.target.value})}
                                        required
                                    />
                                </div>

                                <div>
                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Curp <span className='text-red-500 text-xl'></span><br/>
                                    </label>
                                    <input
                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                        type='text'
                                        name='curp'
                                        maxLength='18'
                                        value={this.state.curp}
                                        onChange={event=>this.setState({curp:event.target.value})}
                                    />
                                </div>

                                <div>
                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Rfc <span className='text-red-500 text-xl'></span><br/>
                                    </label>
                                    <input
                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                        type='text'
                                        name='rfc'
                                        maxLength='18'
                                        value={this.state.rfc}
                                        onChange={event=>this.setState({rfc:event.target.value})}
                                    />
                                </div>
                                
                            </div>
                        

                        <div className='grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-2'>
                            <div >
                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Nacionalidad{" "}
                                    <span className='text-red-500 text-xl'></span><br/>
                                </label>
                                <input
                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                    type='text'
                                    name='nacionalidad'
                                    value={this.state.nacionalidad}
                                    onChange={event=>this.setState({nacionalidad:event.target.value})}
                                />
                            </div>

                            <div >
                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Sexo <span className='text-red-500 text-xl'></span><br/>
                                </label> 
                                <input
                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                    type='text'
                                    name='sexo'
                                    value={this.state.sexo}
                                    onChange={event=>this.setState({sexo:event.target.value})}
                                />
                            </div>
                        </div><br/>

                        
                        <div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-1'>
                                <div>
                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Estado de nacimiento
                                        <span className='text-red-500 text-xl'></span><br/>
                                    </label>
                                    <select
                                        class='py-2  rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent'
                                        value={this.state.nac_estado}
                                        onChange={this.change_Estado}
                                        required>
                                        {this.state.Estados.map(estado => (
                                            <option value={estado.id_Estado}>{estado.entidad_Federativa}</option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Municipio de nacimiento
                                        <span className='text-red-500 text-xl'></span><br/>
                                    </label>
                                    <select
                                        class='py-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent'
                                        value={this.state.nac_municipio}
                                        onChange={this.change_Municipio}
                                        required>
                                        {this.state.Municipios.map(Municipio => (
                                            <option value={Municipio.c_mnpio}> {Municipio.nombre_Municipio} </option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Localidad de nacimiento
                                        <span className='text-red-500 text-xl'></span><br/>
                                    </label>
                                    <select
                                        class='py-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent'   
                                        value={this.state.nac_localidad}
                                        onChange={this.change_localidad}
                                        required>
                                        {this.state.Localidades.map(Localidad => (
                                            <option value={Localidad.id_Localidad}>
                                                {Localidad.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div><br/>

                    
                        <div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-3'>
                            <div >
                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Correo electrónico{" "}
                                    <span className='text-red-500 text-xl'></span><br/>
                                </label>
                                <input
                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                    type='text'
                                    name='correo'
                                    value={this.state.correo_electronico}
                                    onChange={event=>this.setState({correo_electronico:event.target.value})}
                                />
                            </div>

                            <div >
                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Numero telefónico 1{" "}
                                    <span className='text-red-500 text-xl'></span><br/>
                                </label>
                                <input
                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                    type='number'
                                    name='telefono1'
                                    value={this.state.telefono1}
                                    onChange={event=>this.setState({telefono1:event.target.value})}
                                />
                            </div>

                            <div >
                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Numero telefónico 2{" "}
                                    <span className='text-red-500 text-xl'></span><br/>
                                </label>
                                <input
                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                    type='number'
                                    name='telefono2'
                                    value={this.state.telefono2}
                                    onChange={event=>this.setState({telefono2:event.target.value})}
                                />
                            </div>
                        </div>
                        <br/><strong>Dato profesional</strong>

                        
                        <div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-3'>
                            <div >
                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Clave del docente<span className='text-red-500 text-xl'></span><br/>
                                </label>
                                <input
                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                    type='text'
                                    value={this.state.clave_docente}
                                    onChange={event=>this.setState({clave_docente:event.target.value})}
                                />
                            </div>
                            <div>
                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Cédula profesional<span className='text-red-500 text-xl'></span><br/>
                                </label>
                                <input
                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                    type='text'
                                    value={this.state.cedula_prof}
                                    onChange={event=>this.setState({cedula_prof:event.target.value})}
                                />
                            </div>

                            <div>
                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Grado académico<span className='text-red-500 text-xl'></span><br/>
                                </label>
                                <input
                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                    type='text'
                                    value={this.state.grado_academico}
                                    onChange={event=>this.setState({grado_academico:event.target.value})}
                                />
                            </div>
                        </div>

                        <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                            <div >
                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Perfil profesional <span className='text-red-500 text-xl'></span><br/>
                                </label>
                                <input
                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                    type='text'
                                    name='matricula'
                                    maxLength='10'
                                    value={this.state.perfil_prof}
                                    onChange={event=>this.setState({perfil_prof:event.target.value})}
                                />
                            </div>
                        </div>

                        <div className='grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
                            <div >
                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Usuario <span className='text-red-500 text-xl'></span><br/>
                                </label>
                                <input
                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                    type='text'
                                    readOnly
                                    value={this.state.correo_electronico}
                                    onChange={event=>this.setState({correo_electronico:event.target.value})}
                                />
                            </div>
                            <div >
                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Contraseña <span className='text-red-500 text-xl'></span><br/>
                                </label>
                                <input
                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
                                    type='password'
                                    maxLength='10'
                                    value={this.state.password}
                                    onChange={event=>this.setState({password:event.target.value})}
                                />
                            </div>
                        </div>
                    </div>
					
                <div className="flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5">
						<div>    
							<button
								type="submit"
								className="focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" >
								Guardar
							</button>
						</div>	
						<div>    
							<button
								onClick={()=>{this.props.history.push("/app/ListaDocentes");}}
								className="focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110"
							>
								Cancelar
							</button>
						</div>											
					</div>
				</form>
            </>

		);
	}
}

export default withRouter (NuevoDocente);