import axios from "axios"
import { APIURL } from "./Host"

class GestorPagoService {

	GestionHistorialPagos(status,id_plantel){
		return axios.get(APIURL + "/gestion_historial_pago/"+status+"/"+id_plantel);
	}

	ListarCarreras(){
		return axios.get(APIURL + "/listar_carreras/");
	}
   
    GestionPagosActual(id_plantel){
		return axios.get(APIURL + "/gestion_pagos_actual/"+id_plantel);
	}

    HistorialPagosGestion(id_esp,id_periodo){
		return axios.get(APIURL + "/historial_pagos_gestion/" + id_esp + "/" + id_periodo)
	}

	HistorialPagosGestionEscolarizado(id_periodo, id_plantel){
		console.log(APIURL + "/historial_pagos_gestion_escolarizado/" + id_periodo + "/" + id_plantel)
		return axios.get(APIURL + "/historial_pagos_gestion_escolarizado/" + id_periodo + "/" + id_plantel)
	}

	GestionMensualidades(id_periodo,id_au){
		return axios.get(APIURL + "/gestion_mensualidades/" + id_periodo + "/" +id_au)
	}

	DetallesDescuento(id_pago){
		return axios.get(APIURL + "/detalles_descuento/" + id_pago)
	}

	DetallesCargo(id_pago){
		return axios.get(APIURL + "/detalles_cargo/" + id_pago)
	}

	GeneraDescuento(descuentos) {
		//console.log("datos a actualizar ", prospecto)
		return axios.post(APIURL + "/genera_descuento", descuentos);
	}

	GeneraCargo(cargos) {
		return axios.post(APIURL + "/genera_cargo", cargos);
	}

	/*=========METODOS DEL PAGO CON TUTOR===========*/

	ListarPlanteles(){
		return axios.get(APIURL + "/listar_planteles/");
	}
	ListarPlantelesUEA(){
		return axios.get(APIURL + "/listar_plantelesUEA/");
	}
	ListaTutorados(idplantel,status) {
		return axios.get(APIURL + "/lista_tutorados/"+ idplantel + "/" + status );
	}

	EnviarPago_Tutor(JSONPago){
		return axios.put(APIURL+"/agregar_pago_tutor",JSONPago);
	}

	MostrarTutoradosPagos(status_pago,usuario_id) {
		return axios.get(APIURL + "/MostrarTutorados_Pagos/" + status_pago + "/" + usuario_id)
	}

	
}
export default new GestorPagoService()
