import { Component } from "react";
import { withRouter } from "react-router";
import TutorService from "services/TutorService";
import swal from 'sweetalert2';
import { CalucularMes } from "./common/FECHA";
import image from "../images/logotipo_color.svg"

class HistorialPagosTutor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pagos: [],
            vacio: true,
            cantidad: "",
            fecha: "",
            comprobante_pago: "",
            modalPago: "",
            metodo_pago: "",
            name_card: "",
            banco:"",
            ultimos4:"",
            concepto:"",
            tutorados: [],

        }
        this.goBack = this.goBack.bind(this);

    }

    goBack() {
        this.props.history.goBack();
    }


    componentDidMount() {
        sessionStorage.setItem("id_a_u","" )
        sessionStorage.setItem("id_p","" )
    /* let usuario_id = sessionStorage.getItem('id');
        let status_pago = 1;
        TutorService.MisTutorados(status_pago, usuario_id).then((res) => {
            console.log("LOQUELLEGAHISTORIALDELTUTOR", res.data);
            if (res.data == 0) {
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Aún no tiene pagos registrados en su historial',
                });
                this.goBack();
            }
            else {
                this.setState({ pagos: res.data, vacio: false });
            }
        })*/
        let usuario_id = sessionStorage.getItem('id');
        console.log("eantra en historial pagos tutor "+usuario_id)
        let status_pago =  1;
        TutorService.MisTutorados(status_pago,usuario_id).then((res) => {
            console.log("ALUMNOS DEL TUTOR", res.data);
            if (res.data == 0) {
                this.setState({ vacio: true });
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No hay registros en historial de pagos',
                });
                this.goBack();
            } else {
                this.setState({ vacio: false });
                this.setState({ tutorados: res.data });
            }

        })
    }


    CambiarFormato = (fecha)=>{
		var f =new Date(fecha);
		var anio=f.getFullYear();
		var mes=f.getMonth() + 1;
		var dia=f.getDate();

        var date;
        if(mes>=10 && dia>=10){
            date = dia + '-' + (mes) + '-' + anio;  
        }
        else if(mes<10 && dia<10){
            date = '0' + dia  + '-0' + (mes) + '-' +  anio;
        }
        else if(mes>=10 && dia<10){
            date =  '0' +dia + '-' + (mes) + '-' + anio; 
        }
        else if(mes<10 && dia>=10){
            date = dia + '-0' + (mes) + '-' + anio;   
        }
        return date;
    }

    ListaAlumnosTutor = async()=> {
        let usuario_id = sessionStorage.getItem('id');
        let status_pago =  1;
        TutorService.MisTutorados(status_pago,usuario_id).then((res) => {
            console.log("ALUMNOS DEL TUTOR", res.data);
            if (res.data == 0) {
                this.setState({ vacio: true });
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No tiene historial de pagos',
                });
                this.goBack();
            } else {
                this.setState({ vacio: false });
                this.setState({ tutorados: res.data });
            }

        })
    }

    MostrarPago(id_tutorado) {
        TutorService.DetallesPagoTutor(id_tutorado).then((res) => {
            console.log("pago by id ", res.data);
            let pago = res.data;
            this.setState({
                cantidad: pago.cantidad,
                fecha: (pago.fecha.split("-")[2]).split("T")[0] + "-" + CalucularMes(parseInt(pago.fecha.split("-")[1])) + "-" + pago.fecha.split("-")[0],
                comprobante_pago: pago.comprobante_pago,
                modalPago: true,
                metodo_pago: pago.metodo,
                name_card: pago.nombre_tarjeta,
                banco:pago.banco,
                ultimos4:pago.ultimos,
                concepto:pago.concepto

            });
            console.log("data:application/pdf;base64," + this.state.comprobante_pago);
        })

    }

    PagoHistorial = async(id_user)=>{
        sessionStorage.setItem("id_a_u",id_user)
        this.props.history.push("/app/HistorialPagos")
    }


    render() {
        return (
            <>
                <div>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-200" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-white hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">pagos</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">historial</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="relative p-4 sm:p-6 rounded-sm overflow-hidden mb-2">
                        <div className="relative text-center">
                            <h1 className="text-2xl uppercase md:text-3xl text-white font-bold mb-1 text-center">
                                historial de pagos
                            </h1>
                        </div>
                    </div>

                    <div class="grid grid-cols-1 m-4 mx-10" >
                        {/* <div>
                            <strong className="text-white">Especialidad:</strong><br />
                            <select class="flex items-end py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent"
                                name="periodo"
                                id="periodo"
                                onChange={this.change_periodo}
                                required>
                                <option value="">seleccione una opción</option>
                                {
                                    this.state.periodos.map(
                                        periodo =>
                                            <option value={periodo.ID_AlumnoUser + "," + periodo.ID_Periodo}>{periodo.Especialidad}{" - "}{(periodo.Inicio.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(periodo.Inicio.split("-")[1])) + "-" + periodo.Inicio.split("-")[0] + " " + "al" + " " + (periodo.Inicio.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(periodo.Fin.split("-")[1])) + "-" + periodo.Fin.split("-")[0]}</option>
                                    )
                                }
                            </select>
                        </div> */}
                        {/* <div>
                            <strong>Especialidad:</strong><br />
                            <select class="flex items-end py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent"
                                name="especialidad"
                                id="especialidad"
                                onChange={this.change_especilidad}
                                required>
                                <option value="">seleccione una opción</option>
                                {
                                    this.state.especialidades.map(
                                        especialidad =>
                                            <option value={especialidad.ID_Esp}>{especialidad.Nombre_Esp}</option>
                                    )
                                }
                            </select>
                        </div> */}

                    </div>

                    <div className="mx-10 my-4 overflow-x-auto">
                    <table className="w-full whitespace-nowrap my_table">
                            <thead className="h-10">
                                <tr>
                                    <th className="border border-slate-300">Nombre</th>

                                    <th className="border border-slate-300">Matricula</th>
                                    <th className="border border-slate-300">Nivel</th>
                                    <th className="border border-slate-300">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.tutorados.map(
                                        (alumno, index) =>
                                            <tr className="h-20 text-sm leading-none text-gray-800 group border border-slate-300">

                                                <td className="pl-2 text-left ">
                                                    <p className="font-medium ">{alumno.Nombre + " " + alumno.App + " " + alumno.Apm}</p>
                                                </td>
                                                <td className="pl-2 text-left ">
                                                    <p className="font-medium ">{alumno.matricula}</p>
                                                </td>
                                                <td className="pl-2 text-left ">
                                                    <p className="font-medium ">{alumno.Plantel}</p>
                                                </td>

                                                <td className="pl-2 text-left">
                                                    <div className="grid grid-cols-1 content-center">
                                                        <button className="miboton" onClick={()=>this.PagoHistorial(alumno.id_usuario)}>
                                                        <p className="text-sm font-medium leading-none"><i class="fas fa-calendar-alt"></i>  Ver mensualidades</p>
                                                    </button>
                                                    </div>
                                                </td>
                                            </tr>
                                    )

                                }


                            </tbody>
                            {this.state.vacio ? (
                                <>
                                    <tr>
                                        <th scope="col" colspan="6" class="px-6 py-3 text-center">
                                            <div class="flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                                                <svg class="inline flex-shrink-0 mr-3 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                                                <div>
                                                    Sin tutorados.
                                                </div>
                                            </div>
                                        </th>
                                    </tr>

                                </>
                            ) : null}

                        </table>

                    </div>


                    {this.state.modalPago ? (
                        <>
                            <div div class="overflow-y-auto h-32">
                                <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                    <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                        {/*content*/}
                                        <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                            {/*header*/}
                                            <div className=' rounded-t-3xl'>
                                                <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Detalles del pago</p>
                                            </div>
                                            {/*body*/}
                                            <div className='relative flex-auto '>
                                                <div class='grid grid-cols-3 gap-3 px-6 py-5 '>
                                                    <div>
                                                        <p class="mb-2 font-bold text-primary text-lg">Fecha pago: </p>
                                                        <p class="mb-2 font-semibold text-secondary text-lg">{this.state.fecha}</p>
                                                    </div>

                                                    <div>
                                                        <p class="mb-2 font-bold text-primary text-lg ">Cantidad pago: </p>
                                                        <p class="mb-2 font-semibold text-secondary text-lg">{"$" + " " + this.state.cantidad}</p>
                                                    </div>

                                                    <div>
                                                        <p class="mb-2 font-bold text-primary text-lg ">Método pago: </p>
                                                        <p class="mb-2 font-semibold text-secondary text-lg">{this.state.metodo_pago}</p>
                                                    </div>
                                                </div>

                                                <p class="ml-10 mb-2 font-bold text-primary text-lg ">Comprobante de pago </p>

                                                {this.state.comprobante_pago ? ( <iframe src={this.state.comprobante_pago}> </iframe> ) : null}

                                                {!this.state.comprobante_pago ? ( <div className="flex justify-center">
                                                    <div class="ticket">
                                                        <img
                                                            src={image}
                                                            alt="Logotipo" />

                                                        <table id="ticket">
                                                            <thead>
                                                                <tr>
                                                                    <td>Nombre tarjeta</td>
                                                                    <td colSpan={2}>{this.state.name_card}</td>

                                                                </tr>
                                                            </thead>
                                                            <thead>
                                                                <tr>
                                                                    <td>Banco</td>
                                                                    <td colSpan={2}>{this.state.banco}</td>

                                                                </tr>
                                                            </thead>
                                                            <thead>
                                                                <tr>
                                                                    <td>Tarjeta</td>
                                                                    <td colSpan={2}>{"************"+this.state.ultimos4}</td>

                                                                </tr>
                                                            </thead>

                                                            <thead>
                                                                <tr>
                                                                    <th class="cantidad">CANT</th>
                                                                    <th class="producto">CONCEPTO</th>
                                                                    <th class="precio">$$</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr>
                                                                    <td class="cantidad">1</td>
                                                                    <td class="producto">{this.state.concepto}</td>
                                                                    <td class="precio">{"$" + " " + this.state.cantidad}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="cantidad"></td>
                                                                    <td class="producto">TOTAL</td>
                                                                    <td class="precio">{"$" + " " + this.state.cantidad}</td>
                                                                </tr>
                                                              
                                                            </tbody>
                                                        </table>
                                                        <p class="centrado">¡GRACIAS POR SU COMPRA!</p>
                                                    </div>

                                                </div> ) : null}

                                                



                                            </div>
                                            {/*footer*/}
                                            <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                                <button
                                                    className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                    type='button'
                                                    onClick={() => this.setState({ modalPago: false })}>
                                                    Cerrar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={() => this.setState({ modalPago: false })} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
                                </div>

                            </div>
                        </>
                    ) : null}

                </div>


            </>
        );
    }

}

export default withRouter(HistorialPagosTutor);