import React from 'react'
import UsuariosService from 'services/UsuariosService';
import AgenteVentaService from 'services/AgenteVentaService';

class UsuariosModal extends React.Component {

  constructor(){
    super();
    this.state = {
      usuarios: [],
      itemSelect: 0,
    }
  }

  cerrarModal = () =>{
    this.props.cerrarModal();
  }

  componentDidMount(){
    this.getUsuarios();
  }

  getUsuarios = () =>{
    AgenteVentaService.ListaAgentesCeeas().then(response =>{
      this.setState({usuarios: response.data});
      console.log(response.data);
    }).catch(err =>{
      console.log("Error en listarUsuario ", err);
    })

  }

  itemSeleccionado(item){
    this.props.cambiarUsuario(item);
    this.cerrarModal();
  }


  render(){
    return (
      <>
      <div className='fixed inset-0 z-50 flex flex-wrap justify-center'>
            <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
            
            <div className='h-auto max-h-9/12 w-10/12 z-100 relative top-14 flex flex-wrap justify-evenly bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white overflow-auto'>
              <div className='w-full p-2'>
                <div className='flex flex-row gap-1'>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg text-white'>Nombre</h4>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg text-white'>Apellidos</h4>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg text-white'>Puesto</h4>
                </div>
                {
                  this.state.usuarios.map((item, index) =>{
                    return (
                      <div key={item.id_usuario} className='text-black flex flex-row gap-1 w-full mt-5 cursor-pointer hover:bg-gray-500 rounded-2xl bg-primary_2' onClick={ () => this.itemSeleccionado(item) }>
                        <p className={`w-full text-center mt-1 p-2 bg-transparent text-white`} >
                          {item.nombre}
                        </p>
                        <p className={`w-full text-center mt-1 p-2 bg-transparent text-white`} >
                          {`${item.app_paterno} ${item.app_materno}`}
                        </p>
                        <p className={`w-full text-center mt-1 p-2 bg-transparent text-white`} >
                          {item.puesto_agente}
                        </p>
                      </div>
                    )
                  })
                }
                
              </div>
            </div>



            <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
        </div>
      </>
      
    );  
  }
  
}

export default UsuariosModal;
