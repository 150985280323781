import { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { withRouter } from "react-router";
import ProspectosService from "services/ProspectosService";
import CatalogoDireccionService from "services/CatalogoDireccionService";
import EspecialidadService from "services/EspecialidadService";
import { Progress } from 'antd';
import swal from 'sweetalert2';
import 'antd/dist/antd.css';

class MisProspectos extends Component {

	constructor(props) {
		super(props);
		this.state = {
			prospectos: [],
			show: [],
			dirnom: false,
			activ: "1",
			vacio: false,
			ocultar: "",
			modaldetalles: false,
			modalUpdate: false,
			Estados: [],
			Municipios: [],
			Localidades: [],
			especialidades: [],
			Nombre: "",
			Apellidop: "",
			Apellidom: "",
			Curp: "",
			Rfc: "",
			Nacionalidad: "",
			Sexo: "",
			Telefono: "",
			Fecha_Nacimiento: "",
			Correo: "",
			Anio_Egreso: "",
			Procedencia: "",
			Procedencia_Tipo: "",
			Promedio: "",
			EstadoN: "",
			EstadoM: "",
			EstadoL: "",
			Correo: "",
			Telefono: "",
			Telefono2: "",
			Opcion_E: "",

			nombre: '',
			app_paterno: '',
			app_materno: '',
			curp: '',
			rfc: '',
			fecha_nacimiento: '',
			nac_estado: '',
			nac_municipio: '',
			nac_localidad: '',
			nombre_localidad: '',
			nombre_municipio: '',
			nombre_estado: '',
			sexo: '',
			nacionalidad: '',
			correo_electronico: '',
			telefono1: '',
			telefono2: '',
			usuario_id: '',
			prospecto_id: '',
			dni: '',
			escuela_procedencia: '',
			anio_egreso: '',
			OpcionEstudio: '',
			id_usuario: this.props.newParams ? this.props.newParams.id_usuario : "",
		}

		this.inputChange = this.inputChange.bind(this);
		this.deleteCliente = this.deleteCliente.bind(this);
		this.enviar_revision = this.enviar_revision.bind(this);
		this.carga = this.carga.bind(this);
		this.goBack = this.goBack.bind(this);
		this.ModalDetalles = this.ModalDetalles.bind(this);
		this.ModalDetallesCerrar = this.ModalDetallesCerrar.bind(this);
		this.ModalUpdate = this.ModalUpdate.bind(this);
		this.ModalUpdateCerrar = this.ModalUpdateCerrar.bind(this);
		this.ActualizarDatos = this.ActualizarDatos.bind(this);
		this.ListaEstado = this.ListaEstado.bind(this);
		this.ListaMunicipios = this.ListaMunicipios.bind(this);
		this.ListaLocalidades = this.ListaLocalidades.bind(this);
		this.filter_busqueda=this.filter_busqueda.bind(this);


	}

	goBack() {
		this.props.history.goBack();
	}

	inputChange = (event) => {
		//let ob = {};
		let ob = { ...this.state};
		ob[event.target.name] = event.target.value;
		this.setState(ob, function () {
			switch (event.target.name) {
				case "busqueda":
					if (event.target.value !== "") {
						this.filterType("text");
					} else {
						this.filterType();
					}
					break;

				case "dirnom":
					this.filterType("nom");
					break;
				case "nombre":
					this.setState({ nombre: event.target.value });
					break;
				case "app_paterno":
					this.setState({ app_paterno: event.target.value });
					break;
				case "app_Materno":
					this.setState({ app_materno: event.target.value });
					break;
				case "fechaNacimiento":
					this.setState({ fecha_nacimiento: event.target.value });
					break;
				case "curp":
					this.setState({ curp: event.target.value });
					break;
				case "rfc":
					this.setState({ rfc: event.target.value });
					break;
				case "nacionalidad":
					this.setState({ nacionalidad: event.target.value });
					break;
				case "dni":
					this.setState({ dni: event.target.value });
					break;
				case "sexo":
					this.setState({ sexo: event.target.value });
					break;
				case "nac_estado":
					this.setState({ nac_estado: event.target.value });
					this.ListaMunicipios(this.state.nac_estado);
					break;
				case "nac_municipio":
					this.setState({ nac_municipio: event.target.value });
					this.ListaLocalidades(this.state.nac_estado, this.state.nac_municipio);
					break;
				case "nac_localidad":
					this.setState({ nac_localidad: event.target.value })
					break;
				case "email":
					this.setState({ correo_electronico: event.target.value });
					break;
				case "telefono1":
					this.setState({ telefono1: event.target.value });
					break;
				case "escuela_procedencia":
					this.setState({ escuela_procedencia: event.target.value });
					break;
				case "anio_egreso":
					this.setState({ anio_egreso: event.target.value });
					break;
				case "carreras":
					this.setState({ OpcionEstudio: event.target.value });
					break;
				
				default:
			}
		});
	};

	carga(usuario_id) {
		ProspectosService.listaProspectos(this.state.activ, usuario_id).then((response) => {
			if (response.data.length == 0) {
				this.setState({ vacio: true });
			}
			this.setState(
				{ prospectos: response.data, show: response.data }
			);

		});
	}

	filter_busqueda=(event)=>{
		var textNombre = event.target.value   
        const data = this.state.prospectos;
        const newData = data.filter(function(item){
        const itemData = item.Nombre.toUpperCase()
        const textData = textNombre.toUpperCase()
        return itemData.indexOf(textData) > -1
        })
        if(textNombre === ""){
			this.componentDidMount();
        } else {
          this.setState({
            prospectos: newData,
            textNombre: textNombre,
          })
        }
	}

	componentDidMount() {
		
		this.ListaEstado();
		EspecialidadService.ListarCarreras().then((res) => {
			this.setState({ especialidades: res.data });
		});
		let user_id = sessionStorage.getItem('id');
		this.setState({ id_usuario: user_id });
		this.carga(user_id);
	}

	ListaEstado() {
		CatalogoDireccionService.getEstados().then(res => {
			this.setState({ Estados: res.data })
		})
	}
	ListaMunicipios(id) {
		CatalogoDireccionService.getMunicipios(id).then(res => {
			this.setState({ Municipios: res.data })
		})
	}

	ListaLocalidades(id_estado, id_municipio) {
		CatalogoDireccionService.getLocalidades(id_estado, id_municipio).then((res) => {
			this.setState({ Localidades: res.data })
		}
		)
	}

	ModalUpdate(id_prospecto) {
		let prospecto;
		this.ListaEstado();
		ProspectosService.detalles_prospecto(id_prospecto).then((res) => {
			prospecto = res.data[0];
		
			this.setState({

				nombre: prospecto.Nombre,
				app_paterno: prospecto.Apellidop,
				app_materno: prospecto.Apellidom,
				curp: prospecto.Curp,
				rfc: prospecto.Rfc,
				fecha_nacimiento: prospecto.Fecha_Nacimiento,
				nac_estado: prospecto.Nac_Estado,
				nac_municipio: prospecto.Nac_Municipio,
				nac_localidad: prospecto.Nac_Localidad,
				sexo: prospecto.Sexo,
				nacionalidad: prospecto.Nacionalidad,
				correo_electronico: prospecto.Correo,
				telefono1: prospecto.Telefono,
				escuela_procedencia: prospecto.Procedencia,
				dni: prospecto.Dni,
				anio_egreso: prospecto.Anio_Egreso,

			});
            
			this.setState({ prospecto_id: prospecto.ID_Prospecto });
			this.setState({ usuario_id: prospecto.UsuarioID });

			this.ListaMunicipios(this.state.nac_estado);
			this.ListaLocalidades(this.state.nac_estado, this.state.nac_municipio);
		});
		this.setState({ modalUpdate: !this.state.modalUpdate });
	}

	ModalUpdateCerrar() {
		this.setState({ modalUpdate: !this.state.modalUpdate })
	}

	ActualizarDatos = (event) => {
		event.preventDefault();
		let ActualizarDatos = {
			nombre: this.state.nombre,
			app_paterno: this.state.app_paterno,
			app_materno: this.state.app_materno,
			curp: this.state.curp,
			rfc: this.state.rfc,
			dni: this.state.dni,
			fecha_nacimiento: this.state.fecha_nacimiento,
			nac_estado: this.state.nac_estado,
			nac_municipio: this.state.nac_municipio,
			nac_localidad: this.state.nac_localidad,
			sexo: this.state.sexo,
			nacionalidad: this.state.nacionalidad,
			correo_electronico: this.state.correo_electronico,
			telefono1: this.state.telefono1,
			OpcionEstudio: this.state.OpcionEstudio,
			usuario_id:this.state.usuario_id,
			prospecto_id:this.state.prospecto_id,
			escuela_procedencia: this.state.escuela_procedencia,
			anio_egreso: this.state.anio_egreso,


		};
		ProspectosService.updateProspectos(ActualizarDatos).then((res) => {

			if (res.data === 1) {
				swal.fire({
					icon: 'success',
					title: 'Éxito',
					text: 'Dato actualizado correctamente',
				});
				this.setState({ modalUpdate: !this.state.modalUpdate });
				this.componentDidMount();
			}
			else {
				swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Error al actualizar los datos',
				});

			}

		});

	}


	ModalDetalles(ID_Prospecto) {
		let prospecto;
		this.setState({ modaldetalles: !this.state.modaldetalles });
		ProspectosService.detalles_prospecto(ID_Prospecto).then((res) => {
			prospecto = res.data[0];

			this.setState({
				Nombre: prospecto.Nombre,
				Apellidop: prospecto.Apellidop,
				Apellidom: prospecto.Apellidom,
				Curp: prospecto.Curp,
				Rfc: prospecto.Rfc,
				Sexo: prospecto.Sexo,
				Fecha_Nacimiento: prospecto.Fecha_Nacimiento,
				Nacionalidad: prospecto.Nacionalidad,
				EstadoN: prospecto.EstadoN,
				EstadoM: prospecto.EstadoM,
				EstadoL: prospecto.EstadoL,
				Correo: prospecto.Correo,
				Telefono: prospecto.Telefono,
				Procedencia: prospecto.Procedencia,
				Procedencia_Tipo: prospecto.Procedencia_Tipo,
				Promedio: prospecto.Promedio,
				Anio_Egreso: prospecto.Anio_Egreso,
				Opcion_E: prospecto.Opcion_E
			});


		});
	}
	ModalDetallesCerrar() {
		this.setState({ modaldetalles: !this.state.modaldetalles })
	}

	deleteCliente(id) {

		swal.fire({
			title: "Eliminar",
			text: "Estas seguro que deseas eliminar al prospecto?",
			icon: "warning",
			buttons: ["No", "Si"],
		}).then((respuesta) => {

			if (respuesta.isConfirmed) {
				ProspectosService.deleteCliente(id).then((res) => {
					swal.fire({
						text: "El usuario a sido de baja exitosamente",
						icon: "success",
						showConfirmButton: false,
						timer: 1500,
					}).then(() => {

						this.carga(
							sessionStorage.getItem('id')
						);

					})
				});
			}
			return false;
		});
	}

	enviar_revision(id) {
		swal.fire({
			title: "Aviso",
			text: "El prospecto será enviado a revisión de documentos ¿Continuar?",
			icon: "warning",
			buttons: ["No", "Si"],
		}).then((respuesta) => {
			if (respuesta.isConfirmed) {
				ProspectosService.enviar_revision(id).then((res) => {
					swal.fire({
						text: "El prospecto ha sido enviado correctamente",
						icon: "success",
						showConfirmButton: false,
						timer: 1500,
					}).then(() => {
						this.carga(
							sessionStorage.getItem('id')
						);
					})

				});

			}
			return false;
		});
	}


	renderAlert() {
		return (
			<Progress
				strokeColor={{
					from: '#09C700',
					to: '#09C700',
				}}
				trailColor='#C1C1C1'
				percent={0} steps={6} />

		);
	}

	renderAlert1() {
		return (
			<Progress
				strokeColor='#D7803B'
				trailColor='#C1C1C1'
				percent={16} steps={6} />

		);
	}
	renderAlert2() {
		return (
			<Progress
				strokeColor='#D7803B'
				trailColor='#C1C1C1'
				percent={32} steps={6} />

		);
	}
	renderAlert3() {
		return (
			<Progress
				strokeColor='#96A430'
				trailColor='#C1C1C1'
				percent={48} steps={6} />

		);
	}
	renderAlert4() {
		return (
			<Progress
				strokeColor='#96A430'
				trailColor='#C1C1C1'
				percent={64} steps={6} />

		);
	}
	renderAlert5() {
		return (
			<Progress
				strokeColor='#007B0D'
				trailColor='#C1C1C1'
				percent={80} steps={6} />

		);
	}
	renderAlert6() {
		return (
			<div class="flex justify-center">
				<div class="bg-green-600 rounded-full w-24 h-4"><span className="text-gray-100">completado</span></div><span className="ml-2">100%</span>
			</div>
		);
	}

	//Función para filtrar según el tipo de usuario seleccionado
	filterType(op) {
		let order;
		switch (op) {

			case "text":
				order = this.state.prospectos.filter(
					itm => {
						let nom = itm.Nombre.trim().toLowerCase();
						return nom.includes(this.state.busqueda.trim().toLowerCase());
					}
				);
				break;

			case "nom":
				let arr = this.state.show;
				if (this.state.dirnom) {
					arr.sort((a, b) => {
						if (a.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") < b.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) { return -1; }
						if (a.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) { return 1; }
						return 0;
					});
				} else {
					arr.sort((a, b) => {
						if (b.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") < a.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) { return -1; }
						if (b.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) { return 1; }
						return 0;
					});
				}
				order = arr;
				break;

			default:
				order = this.state.prospectos;
				break;
		}

		this.setState({ show: order });

	}


	render() {
		return (
			<>
				<div className="flex justify-end mx-10 mt-4">

					<nav class="flex" aria-label="Breadcrumb">
						<ol class="inline-flex items-center space-x-1">
							<li class="flex justify-end">
								<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
									<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
									Home
								</a>
							</li>
							<li>
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">prospectos</a>
								</div>
							</li>
							<li aria-current="page">
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">mis prospectos</span>
								</div>
							</li>
						</ol>
					</nav>
				</div>

				<div className="container mx-auto h-16 text-center uppercase tracking-widest">
					<div className=" text-center">
						<h1 className="text-2xl md:text-3xl text-white font-bold mb-1 text-center">
							Mis prospectos
						</h1>
					</div>
				</div>

				<div className="mx-10 flex-row-reverse sm:flex items-center lg:flex grid grid-cols-1  justify-between">
					<div className="flex justify-center">
						<NavLink to="/app/NuevoProspecto" className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
							<span className="text-sm font-medium leading-none text-white"><i class="fas fa-plus-circle"></i> nuevo</span>
						</NavLink>
					</div>

					<div className="cont">
						<label>Buscar <i class="fa fa-search" aria-hidden="true"></i></label>
						<input onChange={(text) => this.filter_busqueda(text)} type="search" name="caja_busqueda" id="caja_busqueda" placeholder="Ingrese la busqueda" title="Presione Esc para supender la búsqueda" />
					</div>
				</div>

				<div className="mx-4 my-4 overflow-x-auto">
					<table className="w-full whitespace-nowrap my_table">
						<thead className="h-10">
							<tr>
								<th className="border border-slate-300">
									<div class="flex items-center justify-center">
										Nombre
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className={`icon cursor-pointer icon-tabler icon-tabler-chevron-down text-gray-800"}`}
											width={20}
											height={20}
											viewBox="0 0 24 24"
											strokeWidth="1.8"
											stroke="currentColor"
											fill="none"
											strokeLinecap="round"
											strokeLinejoin="round"
											onClick={() => {
												this.inputChange(
													{ target: { name: "dirnom", value: !this.state.dirnom } });
											}
											}
										>
											<path stroke="none" d="M0 0h24v24H0z" />
											{this.state.dirnom ?
												<polyline points="6 15 12 8 18 15" />
												: <polyline points="6 9 12 15 18 9" />}

										</svg>
									</div>
								</th>
								<th className="border border-slate-300">Télefono</th>
								<th className="border border-slate-300">Correo</th>
								<th className="border border-slate-300">Proceso</th>
								<th className="border border-slate-300">Documentos</th>
								<th className="border border-slate-300">Acciones</th>
							</tr>
						</thead>
						<tbody className="w-full">
							{
								this.state.prospectos.map(
									prospectos =>
									<tr className="h-20 text-sm leading-none text-gray-800 group border border-slate-300">

											<td className="pl-2 text-left">
												<p className="font-medium">{prospectos.Nombre + " "}{prospectos.Apellidop + " "}{prospectos.Apellidom}</p>
											</td>

											<td className="pl-2 text-left ">
												<p className="font-medium">{prospectos.Telefono}</p>
											</td>

											<td className="pl-2 text-left ">
												<p className="font-medium">{prospectos.Correo}</p>
											</td>

											<td className="pl-2 text-center">
												<p className="font-medium">{prospectos.Documentos == 1 ? (
													this.renderAlert1()
												)
													:
													prospectos.Documentos == 2 ?
														this.renderAlert2()
														:
														prospectos.Documentos == 3 ?
															this.renderAlert3()
															:
															prospectos.Documentos == 4 ?
																this.renderAlert4()
																:
																prospectos.Documentos == 5 ?
																	this.renderAlert5()
																	:
																	prospectos.Documentos == 6 ?
																		this.renderAlert6()
																		:

																		<p className="font-medium">{this.renderAlert()}</p>

												}</p>
											</td>
											<td className="pl-4 cursor-pointer">
												{prospectos.Documentos != 6 ? (
													<Link class="flex justify-center items-center" to={{
														pathname: '/app/DocsProspecto',
														state: {
															id_prospecto: prospectos.ID_Prospecto,
															nombre: prospectos.Nombre + " " + prospectos.Apellidop + " " + prospectos.Apellidom
														}
													}} rel="noopener noreferrer" >
														<div className="focus:outline-none focus:ring-2 w-24 bg-green-800 hover:bg-green-600 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110">
															<p className="text-sm font-medium leading-none"><i class="fas fa-file-upload"></i>  agregar</p>
														</div>
													</Link>
												) :


													<Link class="flex justify-center items-center" to={{
														pathname: '/app/DocsProspecto',
														state: {
															id_prospecto: prospectos.ID_Prospecto,
															nombre: prospectos.Nombre + " " + prospectos.Apellidop + " " + prospectos.Apellidom
														}
													}} rel="noopener noreferrer" >
														<div className="focus:outline-none focus:ring-2 w-24 bg-blue-800 hover:bg-blue-600 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110">
															<p className="text-sm font-medium leading-none"><i class="fas fa-eye"></i>  ver</p>
														</div>
													</Link>


												}

												{prospectos.Documentos == 6 ? (


													<button
														onClick={() => this.enviar_revision(prospectos.ID_Prospecto
														)}
														title="enviar revision">
														<div className="focus:outline-none focus:ring-2 w-24 mt-1 bg-sky-600 hover:bg-sky-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110">
															<p className="text-sm font-medium leading-none"><i class="fas fa-file-import"></i>  enviar</p>
														</div>
													</button>
												) :

													<p></p>

												}
											</td>

											<td class="pl-2 text-left">

												<div class="flex justify-center items-center">
													<div className="flex items-center">
														<div class="mr-2 transform hover:text-purple-500 hover:scale-110">
															<button hidden={this.state.ocultar} onClick={() => this.ModalDetalles(prospectos.ID_Prospecto)} title="Detalles alumno">
																<svg class="h-7 w-7 text-green-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
															</button>
														</div>

														<div class="mr-2 transform hover:text-purple-500 hover:scale-110">
															<button hidden={this.state.ocultar} onClick={() => this.ModalUpdate(prospectos.ID_Prospecto)} title="Actualizar alumno">
																<svg class="h-6 w-6 text-blue-500" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
															</button>
														</div>
														<div class="mr-2 transform hover:text-purple-500 hover:scale-110">
															<button
																onClick={() => this.deleteCliente(prospectos.ID_Prospecto
																)}
																title="Eliminar alumno">
																<svg
																	class="h-8 w-8 text-red-500"
																	fill="none"
																	viewBox="0 0 24 24"
																	stroke="currentColor"
																>
																	<path
																		stroke-linecap="round"
																		stroke-linejoin="round"
																		stroke-width="2"
																		d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
																	/>
																</svg>
															</button>
														</div>

													</div>
												</div>


											</td>



										</tr>
								)
							}


						</tbody>
					</table>
					{this.state.vacio ? (
						<>

							<div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-4 grid justify-items-center rounded" role="alert">
								<strong class="font-bold">Aún no ha registrado prospectos!</strong>

							</div>

						</>
					) : null}

{this.state.modalUpdate ? (
						<>
							<form onSubmit={this.ActualizarDatos}>
								<div div class="overflow-y-auto h-32">
									<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
										<div className='relative w-auto my-6 mx-auto max-w-3xl'>
											{/*content*/}
											<div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2'>
												{/*header*/}
												<div className='rounded-t-3xl'>
													<h3 className='text-primary text-3xl text-center my-5 font-bold uppercase'>
														Actualizar Datos
													</h3>
												</div>
												{/*body*/}

												<div className='relative flex-auto'>
													<div className='grid grid-cols-1 gap-5 px-6 py-5  '>
														<div className="shadow-sm2 px-5 py-2 rounded-3xl">
															<div className="grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5 ">
																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Nombre(s)
																	</label>
																	<input
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
																		type='text'
																		name='nombre'
																		value={this.state.nombre}
																		onChange={this.inputChange}

																	/>
																</div>

																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Apellido paterno
																	</label>
																	<input
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
																		type='text'
																		name='app_paterno'
																		value={this.state.app_paterno}
																		onChange={this.inputChange}

																	/>
																</div>

																<div >
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Apellido Materno
																		
																	</label>
																	<input
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
																		type='text'
																		name='app_materno'
																		maxLength='45'
																		value={this.state.app_materno}
																		onChange={this.inputChange}
																	/>
																</div>
															</div>

															<div className='grid grid-cols-3 md:grid-cols-3 gap-5 md:gap-8 mt-5'>
																<div >
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Fecha de nacimiento
																		<span className='text-red-500 text-xl'></span>
																	</label>
																	<input
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
																		type='date'
																		name='fecha_nacimiento'
																		value={this.state.fecha_nacimiento}
																		onChange={this.inputChange}
																	/>
																</div>

																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Curp <span className='text-red-500 text-xl'></span><br />
																	</label>
																	<input
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
																		type='text'
																		name='curp'
																		maxLength='18'
																		value={this.state.curp}
																		onChange={this.inputChange}
																	/>
																</div>
																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Rfc<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<input
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
																		type='text'
																		name='rfc'
																		value={this.state.rfc}
																		onChange={this.inputChange}
																	/>
																</div>
															</div>


															<div className='grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-8 mt-5'>
																<div >
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Nacionalidad
																		<span className='text-red-500 text-xl'></span>
																	</label>
																	<input
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
																		type='text'
																		name='nacionalidad'
																		value={this.state.nacionalidad}
																		onChange={this.inputChange}
																	/>
																</div>
																<div >
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		DNI <span className='text-red-500 text-xl'></span>
																	</label>
																	<input
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
																		type='text'
																		name='dni'
																		value={this.state.dni}
																		onChange={this.inputChange}
																	/>
																</div>

																<div >
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Sexo <span className='text-red-500 text-xl'></span>
																	</label>
																	<input
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
																		type='text'
																		name='sexo'
																		value={this.state.sexo}
																		onChange={this.inputChange}
																	/>
																</div>

															</div>

															<div className='grid grid-cols-2 md:grid-cols-2 gap-5 md:gap-8 mt-5'>
																<div >
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Estado de nacimiento
																		<span className='text-red-500 text-xl'></span>
																	</label><br />
																	<select
																		name='nac_estado'
																		class='py-2 px-1 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent'
																		value={this.state.nac_estado}
																		onChange={this.inputChange}
																	>
																		{this.state.Estados.map(estado => (
																			<option value={estado.id_Estado}>{estado.entidad_Federativa}</option>
																		))}
																	</select><br />



																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Municipio de nacimiento
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<select
																		name='nac_municipio'
																		class='py-2  rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent'
																		value={this.state.nac_municipio}
																		onChange={this.inputChange}
																	>
																		{this.state.Municipios.map(Municipio => (
																			<option value={Municipio.c_mnpio}> {Municipio.nombre_Municipio} </option>
																		))}
																	</select><br />

																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Localidad de nacimiento
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<select
																		name='nac_localidad'
																		class='py-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent'
																		value={this.state.nac_localidad}
																		onChange={this.inputChange}
																	>
																		{this.state.Localidades.map(Localidad => (
																			<option value={Localidad.id_Localidad}>
																				{Localidad.nombre}
																			</option>
																		))}
																	</select>
																</div>

																<div >
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Correo electrónico
																		<span className='text-red-500 text-xl'></span>
																	</label><br />
																	<input
																		className='rounded-lg p-2 px-7 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
																		type='email'
																		name='correo'
																		value={this.state.correo_electronico}
																		onChange={this.inputChange}
																		disabled
																	/><br />

																	<label className='md:text-sm text-xs text-left text-gray-500 text-light font-semibold'>
																		Número telefónico
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<input
																		className=' rounded-lg p-2 px-7 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
																		type='text'
																		name='telefono1'
																		value={this.state.telefono1}
																		onChange={this.inputChange}
																	/><br />


																</div>
															</div>


															<br /><strong>Dato escolar</strong>

															<div className='grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-8 mt-5'>
																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Escuela de procedencia <span className='text-red-500 text-xl'></span><br />
																	</label>
																	<input
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
																		type='text'
																		name='escuela_procedencia'
																		value={this.state.escuela_procedencia}
																		onChange={this.inputChange}
																	/>
																</div>
																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Año egreso<span className='text-red-500 text-xl'></span>
																	</label>
																	<input
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out '
																		type='text'
																		name='anio_egreso'
																		value={this.state.anio_egreso}
																		onChange={this.inputChange}
																	/>
																</div>
															</div>

														</div>
													</div>
												</div>
												{/*footer*/}
												<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
													<button
														className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
														type='button'
														onClick={() => this.ModalUpdateCerrar(false)}>
														Cerrar
													</button>
													<button
														className='bg-emerald-500 text-black active:bg-gray-600 font-bold uppercase text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
														type='submit'>
														Actualizar
													</button>
												</div>
											</div>
										</div>
									</div>
									<div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
								</div>
							</form>
						</>
					) : null}


					{this.state.modaldetalles ? (
						<>
							<div div class="overflow-y-auto h-32">
								<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
									<div className='relative w-auto my-6 mx-auto max-w-3xl'>
										{/*content*/}
										<div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2'>
											{/*header*/}
											<div className=' rounded-t-3xl'>
												<p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Información del prospecto</p>
											</div>
											{/*body*/}
											<div className='relative flex-auto'>
												<div class='grid grid-cols-1 gap-5 px-6 py-5 '>
													<div className="shadow-sm2 px-5 py-2 rounded-3xl">
														<p class='mb-5 font-bold text-primary uppercase text-center text-lg'>
															Datos generales
														</p>
														<div class='grid grid-cols-2 gap-4'>
															<div>
																<p class='mb-2 font-bold text-primary '>
																	Nombre del usuario:{" "}
																</p>
																<p class='mb-2 font-semibold text-primary'>
																	{this.state.Nombre +
																		" " +
																		this.state.Apellidop +
																		" " +
																		this.state.Apellidom}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>Curp:</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Curp}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary '>Rfc:</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Rfc}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>Sexo:</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Sexo}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Fecha de nacimiento:
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Fecha_Nacimiento}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Nacionalidad:
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Nacionalidad}
																</p>
															</div>
														</div>
													</div>
													<div className="shadow-sm2 px-5 py-2 rounded-3xl">
														<p class='mb-5 font-bold text-primary uppercase text-center text-lg'>
															Lugar de nacimiento
														</p>

														<div class='grid grid-cols-3 gap-4'>
															<div>
																<p class='mb-2 font-bold text-primary '>
																	Estado de nacimiento{" "}
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.EstadoN}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Municipio de nacimiento{" "}
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.EstadoM}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Localidad de nacimiento{" "}
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.EstadoL}
																</p>
															</div>
														</div>
													</div>
													<div className="shadow-sm2 px-5 py-2 rounded-3xl">
														<p class='mb-5 font-bold text-primary uppercase text-center text-lg'>
															Datos de contacto
														</p>

														<div class='grid grid-cols-3 gap-4'>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Correo electrónico
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Correo}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Número de teléfono 1
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Telefono}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Número de teléfono 2
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Telefono2}
																</p>
															</div>
														</div>
													</div>
													<div className="shadow-sm2 px-5 py-2 rounded-3xl">
														<p class='mb-5 font-bold text-primary uppercase text-center text-lg'>
															Datos escolares
														</p>
														<div class='grid grid-cols-3 gap-4'>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Escuela de procedencia
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Procedencia}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Sistema escolar
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Procedencia_Tipo}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary '>
																	Promedio
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Promedio}
																</p>
															</div>

														</div>
														<div class='grid grid-cols-2 gap-4'>
															<div>
																<p class='mb-2 font-bold text-primary'>
																	Año egreso
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Anio_Egreso}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary '>
																	Opción de estudio
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Opcion_E}
																</p>
															</div>

														</div>
													</div>
												</div>
											</div>
											{/*footer*/}
											<div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
												<button
													className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
													type='button'
													onClick={() => this.ModalDetallesCerrar(false)}>
													Cerrar
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
							</div>
						</>
					) : null}
				</div>

			</>
		);
	}

}

export default withRouter(MisProspectos);