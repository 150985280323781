import { Component } from "react";
import EspecialidadService from "services/EspecialidadService";
import { NavLink } from 'react-router-dom';
import GradoAcademicoService from "services/GradoAcademicoService";
import PeriodosService from "services/PeriodosService";
import swal from 'sweetalert2'
import open from "../images/open.png"
import { withRouter } from "react-router-dom";

class ListaEspecialidades extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id_especialidad: '',
            clave_especialidad: '',
            nombre_especialidad: '',
            creditos: '',
            total_asignaturas: '',
            total_horas_academico: '',
            total_horas_ind: '',
            gradoacademico_id: '',
            tipo_periodo_id: '',
            tipo_periodo:'',
            grado_academico: '',
            total_periodo: '',

            Especialidades: [],
            Tipo_periodos: [],
            Grado_academico: [],
            modaldetalles: false,
            modalactualizar: false,
            txtBuscar:'',
        }

        this.ModalDetalles=this.ModalDetalles.bind(this);
        this.EspecialidadById=this.EspecialidadById.bind(this);
        this.ModalActualizar=this.ModalActualizar.bind(this);
        this.change_texto_buscar=this.change_texto_buscar.bind(this);
    }


	change_texto_buscar=(event)=>{
		var text=event.target.value;
		if(text==""){
			this.componentDidMount();
		}
		this.setState({txtBuscar:event.target.value})
	}

    componentDidMount() {
        EspecialidadService.ListarCarreras().then((res) => {
            this.setState({ Especialidades: res.data });
            //console.log("especialidades ", this.state.Especialidades)
        });
    }

    ModalDetalles(id_especialidad) {
        this.EspecialidadById(id_especialidad);
        this.setState({ modaldetalles: !this.state.modaldetalles });
    }

    ModalActualizar(id_especialidad) {
        PeriodosService.Lista_periodos().then((res) => {
            //console.log("periodos ", res.data);
            this.setState({ Tipo_periodos: res.data });
            //console.log("peirodos ", this.state.Tipo_periodos);
        });
        GradoAcademicoService.ListaGradoAcademico().then((res) => {
            this.setState({ Grado_academico: res.data })
            //console.log("grados ", res.data);
        })
        this.EspecialidadById(id_especialidad);
        this.setState({ modalactualizar: !this.state.modalactualizar });
    }

    EspecialidadById(id_especialidad) {
        let esp;
        EspecialidadService.EspecialidadById(id_especialidad).then((res) => {
            esp = res.data;
            //console.log("esp ", esp);
            this.setState({
                clave_especialidad: esp.clave_especialidad,
                creditos: esp.creditos,
                grado_academico: esp.grado_academico,
                gradoacademico_id: esp.gradoacademico_id,
                id_especialidad: esp.id_especialidad,
                nombre_especialidad: esp.nombre_especialidad,
                tipo_periodo_id: esp.tipo_periodo_id,
                tipo_periodo:esp.tipo_periodo,
                total_asignaturas: esp.total_asignaturas,
                total_horas_academico: esp.total_horas_academico,
                total_horas_ind: esp.total_horas_ind,
                total_periodo: esp.total_periodo,
            });
        });
    }

    ActualizarDatos = (event) => {
        event.preventDefault();
        let id_grado = document.getElementById("gradoacademico_id").value;
        let id_periodo = document.getElementById("id_periodo").value;
        let especialidad = {
            id_especialidad: this.state.id_especialidad,
            clave_especialidad: this.state.clave_especialidad,
            nombre_especialidad: this.state.nombre_especialidad,
            creditos: this.state.creditos,
            total_asignaturas: this.state.total_asignaturas,
            total_horas_academico: this.state.total_asignaturas,
            total_horas_ind: this.state.total_horas_ind,
            gradoacademico_id:id_grado,
            tipo_periodo_id: id_periodo,
            total_periodo: this.state.total_periodo,
        }
        console.log("especialidad agregar ",especialidad);
        EspecialidadService.EspecialidadUpdate(especialidad).then((res) => {
            //console.log("registro ingresado " + res.data);
            if (res.data === 1) {
                this.setState({ modalactualizar: !this.state.modalactualizar });
                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Actualizado',
                });
                this.componentDidMount();
            }
            else {
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al actualizar los datos',
                });
            }
        })
    }

    busqueda=()=>{
		var txt=document.getElementById("txtbuscar").value;
		if(txt!=""){
			EspecialidadService.BusquedaEspecialidad(txt).then((res)=>{
				this.setState({Especialidades:res.data});
			})
        }
	}

    actualizarDatos=()=>{
		this.setState({modaldetalles:!this.state.modaldetalles});
        this.ModalActualizar();
	}


    goBack=async()=> {
        this.props.history.push("/app/PlantelesAsignaturas");
    }


    NuevaAsignatura = async(id_especialidad, nombre_especialidad)=>{
        console.log("guardar parametros de especialidad en storeg "+id_especialidad +" "+nombre_especialidad)
        sessionStorage.setItem("id_e",id_especialidad)
        sessionStorage.setItem("n_e",nombre_especialidad)
        this.props.history.push("/app/ListaAsignaturas")
    }


    render(){
        return(
            <div className="bg-transparent h-auto pb-20">
                <div className="w-full  p-4 sm:p-6 overflow-hidden h-20 z-10">
					<div className="relative text-center">
						<h1 className="text-2xl uppercase md:text-3xl text-white font-bold mb-1 text-center font-barlow tracking-widest">
                        Lista de especialidades y licenciaturas
						</h1>
					</div>
				</div>

                <div className="flex justify-start mx-10 mt-4">
					<nav class="flex" aria-label="Breadcrumb">
						<ol class="inline-flex items-center space-x-1">
							<li class="flex justify-end">
								<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
									<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
									Home
								</a>
							</li>
							<li>
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<a onClick={()=>this.goBack()} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Planteles</a>
								</div>
							</li>
							<li aria-current="page">
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Lista de especialidades</span>
								</div>
							</li>
						</ol>
					</nav>
				</div>

                <div className="sm:flex lg:flex grid grid-cols-1 justify-between mb-5">
                    <div className="flex justify-start mx-10">
                        <div class="xl:w-96 mt-8">
                            <div class="input-group relative flex flex-wrap items-stretch w-full ">
                                <input type="search"
                                    class="form-control relative flex-auto px-3 py-1.5 text-base font-normal text-black bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none"
                                    placeholder="buscar"
                                    aria-label="Search"
                                    id="txtbuscar"
                                    name="txtbuscar"
                                    onKeyPress={this.busqueda}
                                    onChange={this.change_texto_buscar} 
                                    aria-describedby="button-addon2" />

                                <button
                                    onClick={this.busqueda}
                                ><svg class="h-6 w-6 text-white " width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className=" flex flex-items-reverse px-10 py-1 ">
                        <div className="mx-8 my-8 overflow-x-auto">
                            <div className=" flex-row-reverse sm:flex items-center lg:flex grid grid-cols-1  justify-between mb-5">
                                <div className="flex justify-center">
                                <NavLink to="/app/NuevaEspecialidad" className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
                                    <p className="text-sm font-medium leading-none text-white"><i class="fas fa-plus-circle"></i> Agregar</p>
                                </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>   

                <div className=" overflow-y-auto">
                    <table className="bg-color4 w-full border-collapse border border-slate-400 table-auto my_table" >
                        <thead className="h-10">
                            <tr>
                                <th className="font-semibold text-center text-lg">Clave</th>

                                <th className="font-semibold text-center text-lg"> Nombre </th>

                                <th className="font-semibold text-center text-lg"> Grado académico </th>

                                <th className="font-semibold text-center text-lg">Créditos </th>

                                <th className="font-semibold text-center text-lg">Mostrar</th>

                                <th className="font-semibold text-center text-lg">Asignaturas</th>
                            </tr>
                        </thead>
                        {
                            this.state.Especialidades.map(
                                (especialidad,index )=>
                                    <tbody  key={index}>
                                        <tr >
                                            <td> 
                                                <h1 className="font-semibold text-primary text-left pl-5 text-lg">{especialidad.clave_especialidad}</h1>
                                            </td>
    
                                            <td >
                                                <h1 className="font-semibold text-primary text-left pl-5 text-lg">{especialidad.nombre_especialidad}</h1>
                                            </td>

                                            <td >
                                                <h1 className="font-semibold text-primary text-left pl-5 text-lg">{especialidad.grado_academico}</h1>
                                            </td>

                                            <td >
                                                <h1 className="font-semibold text-primary text-left pl-5 text-lg">{especialidad.creditos}</h1>
                                            </td>

                                            <td >
                                                <div class="flex justify-center items-center">
                                                    <div class="mt-2 ml-3 transform hover:text-purple-500 hover:scale-110">
                                                        <button hidden={this.state.ocultar}  onClick={() => this.ModalDetalles(especialidad.id_especialidad)}  title="Detalles especialidad">
                                                            <svg class="h-7 w-7 text-alternative1" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
                                                        </button>
                                                    </div>

                                                    <div class="mt-2 ml-3 transform hover:text-purple-500 hover:scale-110">
                                                        <button hidden={this.state.ocultar} onClick={() => this.ModalActualizar(especialidad.id_especialidad)} title="Actualizar especialidad">
                                                            <svg class="h-6 w-6 text-alternative2" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>

                                            <td>
                                                <button onClick={()=>this.NuevaAsignatura( especialidad.id_especialidad, especialidad.nombre_especialidad)}>
                                                    <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />  <line x1="10" y1="14" x2="20" y2="4" />  <polyline points="15 4 20 4 20 9" /></svg>													
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                            )
                        }
                    </table>
                </div>
    

                {this.state.modaldetalles ? (
                    <>
                        <div div class="overflow-y-auto h-32">
                            <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                    {/*content*/}
                                    <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                        {/*header*/}
                                        <div className=' rounded-t-3xl'>
                                            <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Detalles</p>
                                        </div>
                                        {/*body*/}

                                        <div className="flex justify-end mx-10 ">
											<nav class="flex" aria-label="Breadcrumb">
												<ol class="inline-flex items-center space-x-1 md:space-x-3">
												<button onClick={this.actualizarDatos} class="bg-secondary hover:bg-primary text-white font-bold py-1 px-4 rounded-full">Editar especialidad</button>
												
												</ol>
											</nav>
										</div>

                                        <div className='relative flex-auto'>
                                            <div class='grid grid-cols-1 gap-5 px-6 py-5  '>
                                                <div className="shadow-sm2 px-5 py-2 rounded-3xl">
                                                    <div class='grid grid-cols-3 gap-3'>
                                                        <div>
                                                            <p class='mb-2 font-bold text-primary  '>
                                                                Nombre de la especialidad
                                                            </p>
                                                            <p class='mb-2 font-semibold text-secondary '>
                                                                {this.state.nombre_especialidad} </p>
                                                        </div>
                                                        <div>
                                                            <p class='mb-2 font-bold text-primary '>Clave
                                                            </p>
                                                            <p class='mb-2 font-semibold text-secondary '>
                                                                {this.state.clave_especialidad}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p class='mb-2 font-bold text-primary  '>
                                                                Créditos
                                                            </p>
                                                            <p class='mb-2 font-semibold text-secondary '>
                                                                {this.state.creditos}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div class='grid grid-cols-3 gap-3'>

                                                        <div>
                                                            <p class='mb-2 font-bold text-primary '>
                                                                Total de asignaturas
                                                            </p>
                                                            <p class='mb-2 font-semibold text-secondary '>
                                                                {this.state.total_asignaturas}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p class='mb-2 font-bold text-primary '>
                                                                Total de horas académico
                                                            </p>
                                                            <p class='mb-2 font-semibold text-secondary'>
                                                                {this.state.total_horas_academico}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p class='mb-2 font-bold text-primary  '>
                                                                Total de horas independiente
                                                            </p>
                                                            <p class='mb-2 font-semibold text-secondary '>
                                                                {this.state.total_horas_ind}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div class='grid grid-cols-3 gap-3'>
                                                        <div>
                                                            <p class='mb-2 font-bold text-primary '>
                                                                Tipo de periodo
                                                            </p>
                                                            <p class='mb-2 font-semibold text-secondary'>
                                                                {this.state.tipo_periodo}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p class='mmb-2 font-bold text-primary '>
                                                                Total de periodo
                                                            </p>
                                                            <p class='mb-2 font-semibold text-secondary'>
                                                                {this.state.total_periodo}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p class='mb-2 font-bold text-primary '>
                                                                Grado académico
                                                            </p>
                                                            <p class='mb-2 font-semibold text-secondary'>
                                                                {this.state.grado_academico}
                                                            </p>
                                                        </div>

                                                    </div><br />

                                                </div>
                                            </div>
                                        </div>
                                        {/*footer*/}
                                        <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                            <button
                                                className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                type='button'
                                                onClick={() => this.setState({ modaldetalles: false })}>
                                                Cerrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div  onClick={() => this.setState({ modaldetalles: false })} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
                            </div>
                        </div>
                    </>
                ) : null}


                {this.state.modalactualizar ? (
                    <>
                        <form onSubmit={this.ActualizarDatos}>
                            <div div class="overflow-y-auto h-32">
                                <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                    <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                        {/*content*/}
                                        <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                            {/*header*/}
                                            <div className='rounded-t-3xl'>
                                                <h3 className='text-primary text-3xl text-center my-5 font-bold uppercase'>
                                                    Actualizar Datos
                                                </h3>
                                            </div>
                                            {/*body*/}

                                            <div className='relative p-6 flex-auto'>
                                                <div class='grid grid-cols-1 gap-5 px-6 py-5 '>
                                                   
                                                        <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 '>
                                                            <div>
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Nombre
                                                                    <span className='text-red-500 text-xl'>*</span><br />
                                                                </label>
                                                                <input
                                                                    className='mt-1 w-full rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                    type='text'
                                                                    name='nombre'
                                                                    value={this.state.nombre_especialidad}
                                                                    onChange={event => this.setState({ nombre_especialidad: event.target.value })}
                                                                    required
                                                                />
                                                            </div>

                                                        </div>
                                                        <div className='grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 '>
                                                            <div>
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Clave
                                                                    <span className='text-red-500 text-xl'>*</span><br />
                                                                </label>
                                                                <input
                                                                    className='uppercase mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                    type='text'
                                                                    name='clave_especialidad'
                                                                    value={this.state.clave_especialidad}
                                                                    onChange={event => this.setState({ clave_especialidad: event.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                            <div>
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Créditos
                                                                    <span className='text-red-500 text-xl'>*</span><br />
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                    type='number'
                                                                    name='creditos'
                                                                    value={this.state.creditos}
                                                                    onChange={event => this.setState({ creditos: event.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div><br />

                                                        <div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3  md:grid-cols-3 sm:grid-cols-3'>

                                                            <div>
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Total de asignaturas
                                                                    <span className='text-red-500 text-xl'>*</span><br />
                                                                    
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                    type='number'
                                                                    name='total_asignaturas'
                                                                    value={this.state.total_asignaturas}
                                                                    onChange={event => this.setState({ total_asignaturas: event.target.value })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div >
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Total de horas académico
                                                                    <span className='text-red-500 text-xl'>*</span><br />
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                    type='number'
                                                                    name='total_horas_academico'
                                                                    value={this.state.total_horas_academico}
                                                                    onChange={event => this.setState({ total_horas_academico: event.target.value })}
                                                                    required
                                                                />
                                                            </div>

                                                            <div>
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Total de horas independientes
                                                                    <span className='text-red-500 text-xl'>*</span><br />
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                    type='number'
                                                                    name='total_horas_ind'
                                                                    value={this.state.total_horas_ind}
                                                                    onChange={event => this.setState({ total_horas_ind: event.target.value })}
                                                                    required
                                                                />
                                                            </div>

                                                        </div><br />

                                                        <div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-3'>
                                                            <div>
                                                                <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                                                    Seleccionar el grado académico
                                                                    <span className='text-red-500 text-xl'>*</span><br />
                                                                </label>

                                                                <select class=" py-2 px-3 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                                                    name="gradoacademico_id"
                                                                    id="gradoacademico_id"
                                                                    value={this.state.gradoacademico_id}
                                                                    onChange={event => this.setState({ gradoacademico_id: event.target.value })}
                                                                    required>
                                                                    {
                                                                        this.state.Grado_academico.map(
                                                                            grado =>
                                                                                <option value={grado.id_grado_ac}>{grado.grado_academico}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>

                                                            <div>
                                                                <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                                                    Escoger tipo de periodo
                                                                    <span className='text-red-500 text-xl'>*</span><br />
                                                                </label>
                                                                <select class="py-2 px-3 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                                                    name="id_periodo"
                                                                    id="id_periodo"
                                                                    value={this.state.tipo_periodo_id}
                                                                    onChange={event => this.setState({tipo_periodo_id: event.target.value })}
                                                                    required>
                                                                    {
                                                                        this.state.Tipo_periodos.map(
                                                                            tip_periodos =>
                                                                                <option value={tip_periodos.id_tipo_periodo}>{tip_periodos.tipo_periodo}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>

                                                            <div >
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Total de periodos
                                                                    <span className='text-red-500 text-xl'>*</span><br />
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                    type='number'
                                                                    name='total_periodos'
                                                                    value={this.state.total_periodo}
                                                                    onChange={event => this.setState({ total_periodo: event.target.value })}
                                                                    required
                                                                />
                                                            </div>
                                                        </div><br />
                                                   
                                                </div>
                                            </div>
                                            {/*footer*/}
                                            <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
                                                <button
                                                    className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                    type='button'
                                                    onClick={() => this.setState({ modalactualizar: false })}>
                                                    Cerrar
                                                </button>
                                                <button
                                                    className='bg-emerald-500 text-black active:bg-gray-600 font-bold uppercase text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                    type='submit'>
                                                    Actualizar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div  onClick={() => this.setState({ modalactualizar: false })} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
                                </div>
                            </div>
                        </form>
                    </>
                ) : null}
            </div>
        )
    }
}
export default  withRouter(ListaEspecialidades)