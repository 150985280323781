import axios from "axios";
import { APIURL } from "./Host";
import { Suspense } from "react";

class CeeasService{


        listaCEEASmain(rol){
            console.log("Recibiodo en el service:"+rol);
            return axios.get(APIURL+`/lista-ceeas/lista?rol=${rol}`);
        }

        listaCeeasByID(id_ceeas){
            console.log("Recibiodo en el service:"+id_ceeas);
            return axios.get(APIURL+"/lista-ceeasByID/"+id_ceeas)
        }

        InsertarSucursal(sucursales){
            console.log("lo que llega al insertar suc",sucursales);
            const data = {
                // "nombre": sucursales.nombre_ceeas,
                // "estado_id": sucursales.estado_id,
                // "mun_id":sucursales.mun_id,
                // "localidad_id": sucursales.localidad_id,
                // "calle": sucursales.calle,
                // "numero": sucursales.numero,
                // "observaciones":sucursales.observaciones,
                // "status": sucursales.status,
                // "register_by":sucursales.register_by,
                // "email":sucursales.email,
                "nombre": sucursales.nombre,
                "estado_id": sucursales.estado_id,
                "mun_id": sucursales.mun_id,
                "localidad_id": sucursales.localidad_id,
                "email":sucursales.email,
                "colonia":sucursales.colonia,
                "calle": sucursales.calle,
                "numero": sucursales.numero,
                "register_by": sucursales.register_by,
                "responsable": sucursales.responsable,
                "telefono_principal": sucursales.telefono_principal,
                "telefono_secundario": sucursales.telefono_secundario,
                "facebook": sucursales.facebook,
                "instagram": sucursales.instagram,
                "tiktok": sucursales.tiktok,
                "referencias": sucursales.referencias,
                "latitud": sucursales.latitud,
                "longitud": sucursales.longitud,
                
            }
            return axios.post(`${APIURL}/ceeas/insert`, data);
        }
        ActualizarSucursal(sucursales){
            const data = {
                "nombre": sucursales.nombre,
                "estado_id": sucursales.estado_id,
                "mun_id":sucursales.mun_id,
                "localidad_id": sucursales.localidad_id,
                "calle": sucursales.calle,
                "numero": sucursales.numero,
                "observaciones":sucursales.observaciones,
                "status": sucursales.status,
                "register_by":sucursales.register_by,
                "lu_by": sucursales.lu_by,
                "id_sucursal": sucursales.id_sucursal
            }
            return axios.post(`${APIURL}/sucursales/update`, data);
        }
}

export default new CeeasService()