import axios from 'axios';
import { APIURL } from './Host';


class Ceeas_AV_RelacionService{


    ListarSucUsRelaciones(){
        return axios.post(`${APIURL}/sucUsRelacion`);
    }

    ListarSucUsRelacionesByUsuario(id_usuario){
        return axios.post(`${APIURL}/sucUsRelacion/byUsuario/${id_usuario}`);
    }

    ListarSucUsRelacionesBySucursal(id_sucursal){
        return axios.post(`${APIURL}/sucUsRelacion/bySucursal/${id_sucursal}`);
    }

    SucUsRelacionById(idSucUsRelacion){
        return axios.post(`${APIURL}/sucUsRelacion/${idSucUsRelacion}`);
    }

    InsertarSucUsRelacion(sucUsRelacion){
        const data = {
            "usuario_id": sucUsRelacion.usuario_id,
            "sucursal_id": sucUsRelacion.sucursal_id,
        }
        return axios.post(`${APIURL}/sucUsRelacion/insert`, data);
    }

    ActualizarSucUsRelacion(sucUsRelacion){
        const data = {
            "usuario_id": sucUsRelacion.usuario_id,
            "sucursal_id": sucUsRelacion.sucursal_id,
            "id_suc_us_relacion": sucUsRelacion.id_suc_us_relacion
        }
        return axios.post(`${APIURL}/sucUsRelacion/update`, data);
    }

    EliminarSucUsRelacion(idSucUsRelacion){
        return axios.post(`${APIURL}/sucUsRelacion/delete/${idSucUsRelacion}`);
    }

}

export default new Ceeas_AV_RelacionService();