import { Component } from "react";
import PeriodosService from "services/PeriodosService";
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import Swal from 'sweetalert2/dist/sweetalert2.js'

class AgregarPeriodo extends Component{
    constructor(props){
        super(props);
        this.state={
            tipo_periodo:'0',
            fecha_inicio_general:'',
            fecha_fin_general:'',
            fecha_1_1:'',
            fecha_1_2:'',
            fecha_2_1:'',
            fecha_2_2:'',
            fecha_3_1:'',
            fecha_3_2:'',
            fecha_4_1:'',
            fecha_4_2:'',
            fecha_5_1:'',
            fecha_5_2:'',
            tipo_periodos:[],
            componentes:'',

        }
        this.AgregarPeriodo=this.AgregarPeriodo.bind(this);
        this.change_tipo_periodo=this.change_tipo_periodo.bind(this);
        this.MostrarMsj=this.MostrarMsj.bind(this)
        this.change_tipo_periodo = this.change_tipo_periodo.bind(this)
        this.FormMostrar = this.FormMostrar.bind(this)
    }

    change_fecha_inicio_general=(event)=>{
        this.setState({fecha_inicio_general:event.target.value});
    }

    change_tipo_periodo=(event)=>{
        let selectIndex = event.target.selectedIndex
        var nombre = event.target.options[selectIndex].text;
        console.log("id ============== "+nombre)
        this.setState({tipo_periodo:event.target.value, componentes:nombre })

    }
  
     FormMostrar (){

        console.log("entra en forma mostar "+this.state.tipo_periodo)
        switch (this.state.tipo_periodo) {
			case "1":
                console.log("entra en cuatrimestre  ")
				return this.formCuatrimestre
			case "2":
                console.log("entra en SEMESTRE  ")
				return this.formSemestre
			case "3":
				return this.formCicloEscolar
            case "4":
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No es necesario crear un periodo para talleres',
                 });
                return this.Vacio
			default:
                console.log("return 0 ")
				return this.Vacio
		}
    }


   async MostrarMsj(){
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'La fecha no es correcta',
         });
    }

    componentDidMount(){
        console.log("entra ")
        PeriodosService.Tipo_PeriodoNoActivo().then((res)=>{
		this.setState({tipo_periodos:res.data})
		})
    }

    AgregarPeriodo=(event)=>{
        event.preventDefault();
        let nombre = document.getElementsByName("periodo").value
        console.log("select nombre "+nombre)

         let NewPeriodo={
                nombre_periodo:this.state.componentes,
                tipo_periodo_id:Number(this.state.tipo_periodo),
                fecha_inicio_general:this.state.fecha_inicio_general,
                fecha_fin_general:this.state.fecha_fin_general,
                fecha_1_1: this.state.fecha_1_1,
                fecha_1_2:this.state.fecha_1_2,
                fecha_2_1: this.state.fecha_2_1,
                fecha_2_2:this.state.fecha_2_2,
                fecha_3_1:this.state.fecha_3_1,
                fecha_3_2: this.state.fecha_3_2,
                fecha_4_1:this.state.fecha_4_1,
                fecha_4_2: this.state.fecha_4_2,
                fecha_5_1:this.state.fecha_5_1,
                fecha_5_2:this.state.fecha_5_2,
             }

    
         console.log("nuevo periodo ",NewPeriodo);
         PeriodosService.AgregarPeriodo(NewPeriodo).then((res)=>{
             //console.log("tamaño del nuevo periodo "+res.data);
             if(res.data>0){
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Se agregó correctamente el registro',
                 });
                this.props.history.push("/app/ListaPeriodos");
             }
             else{
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al agregar el registro',
                 });
             }
         });
    }

    Vacio
    formCuatrimestre
    formCicloEscolar
    formSemestre


    goBack = () => {
		this.props.history.push("/app/ListaPeriodos");
	}

    render(){
        this.Vacio = (
                <div></div>
            )
        
        this.formCuatrimestre = (
                <div>
                     <form className="" onSubmit={this.AgregarPeriodo}>
                            <div className="md:px-20 md:py-3 lg:px-30 lg:py-10 2xl:px-60">

                                <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2 justify-items-center"> <div/>                           
                                    <div >
                                            <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                                Fecha inicio del periodo <br/>
                                            </label>
                                            <input
                                                className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2  w-full text-primary_2 "
                                                type="date"
                                                name="fecha_inicio_general"
                                                value={this.state.fecha_inicio_general}
                                                onChange={(event)=>this.setState({fecha_inicio_general:event.target.value})}
                                                required
                                            />
                                    </div>
                                
                                    <div >
                                        <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                                Fecha fin del periodo<br/>
                                            </label>
                                            <input
                                                className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2  w-full text-primary_2 "
                                                type="date"
                                                name="fecha_fin_general"
                                                value={this.state.fecha_fin_general}
                                                onChange={(event=>this.setState({fecha_fin_general:event.target.value}))}
                                                required
                                            />
                                    </div>
                                </div><br/>
                                <hr/><hr/>
                                <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                                    <p className="font-semibold">Parcial 1</p>          
                                    <div>
                                        <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                            Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                        </label>
                                        <input
                                            className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2  w-full text-primary_2 "
                                            type="date"
                                            id="fecha_inicio_parcial1"
                                            name="fecha_inicio_parcial1"
                                            value={this.state.fecha_1_1}
                                            onChange={(event)=>this.setState({fecha_1_1:event.target.value})}
                                            required
                                        />
                                    </div>
                                    
                                    <div>
                                        <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                            Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                        </label>
                                        <input
                                            className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                            type="date"
                                            name="fecha_fin_parcial1"
                                            value={this.state.fecha_1_2}
                                            onChange={(event)=>this.setState({fecha_1_2:event.target.value})}
                                            required
                                        />
                                    </div>
                                </div>
    
                            <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                                <p className="font-semibold">Parcial 2</p>          
                                    <div>
                                        <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                            Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                        </label>
                                        <input
                                            className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                            type="date"
                                            name="fecha_inicio_parcial2"
                                            value={this.state.fecha_2_1}
                                            onChange={(event)=>this.setState({fecha_2_1:event.target.value})}
                                            required
                                        />
                                    </div>
                                
                                    <div>
                                        <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                            Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                        </label>
                                        <input
                                            className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                            type="date"
                                            name="fecha_fin_parcial2"
                                            value={this.state.fecha_2_2}
                                            onChange={(event)=>this.setState({fecha_2_2:event.target.value})}
                                            required
                                        />
                                    </div>
                            </div>
    
                            <div   className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                                <p className="font-semibold">Ordinario</p>          
                                <div>
                                        <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                            Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                        </label>
                                        <input
                                            className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                            type="date"
                                            name="fecha_inicio_ordinario"
                                            value={this.state.fecha_3_1}
                                            onChange={(event)=>this.setState({fecha_3_1:event.target.value})}
                                            required
                                        />
                                    </div>
                                
                                <div>
                                    <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                            Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                        </label>
                                        <input
                                            className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                            type="date"
                                            name="fecha_fin_ordinario"
                                            value={this.state.fecha_3_2}
                                            onChange={(event)=>this.setState({fecha_3_2:event.target.value})}
                                            required
                                        />
                                </div>
                            </div>
    
                            <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                                <p className="font-semibold">Título</p>          
                                    <div>
                                        <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                            Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                        </label>
                                        <input
                                            className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                            type="date"
                                            name="fecha_inicio_titulo"
                                            value={this.state.fecha_4_1}
                                            onChange={(event)=>this.setState({fecha_4_1:event.target.value})}
                                            required
                                        />
                                    </div>
                                
                                    <div>
                                        <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                            Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                        </label>
                                        <input
                                            className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                            type="date"
                                            name="fecha_fin_titulo"
                                            value={this.state.fecha_4_2}
                                            onChange={(event)=>this.setState({fecha_4_2:event.target.value})}
                                            required
                                        />
                                    </div>
                            </div>
                        </div>
    
                        <div className="flex items-center justify-center md:gap-8 gap-4 pt-5 pb-5 justify-items-center">
                                <div>   
                                    <button
                                        type="submit"
                                        className="focus:outline-none focus:ring-2 w-auto bg-primary hover:bg-primary rounded-lg font-medium text-white px-5 py-3 transition duration-500 transform hover:scale-110"
                                        >
                                        Guardar
                                    </button>
                                </div>	
                                <div>    
                                    <button
                                    onClick={()=>{this.props.history.push("/app/ListaPeriodos");}}
                                    className="focus:outline-none focus:ring-2 w-auto bg-secondary hover:bg-secondary rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110"
                                >
                                    Cancelar
                                </button>    
                                </div>											
                            </div>
                    </form>
                </div>
                
            )
        
    
        this.formCicloEscolar = (
                <div>
                <form className="" onSubmit={this.AgregarPeriodo}>
                       <div className="md:px-20 md:py-3 lg:px-30 lg:py-10 2xl:px-60">
                           <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2 justify-items-center"> <div/>                           
                               <div>
                                    <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                        Fecha inicio del ciclo escolar <br/>
                                    </label>
                                    <input
                                        className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2  w-full text-primary_2 "
                                        type="date"
                                        name="fecha_inicio_general"
                                        value={this.state.fecha_inicio_general}
                                        onChange={(event)=>this.setState({fecha_inicio_general:event.target.value})}
                                        required
                                    />
                               </div>
                           
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                           Fecha fin del ciclo escolar<br/>
                                       </label>
                                       <input
                                           className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2  w-full text-primary_2 "
                                           type="date"
                                           name="fecha_fin_general"
                                           value={this.state.fecha_fin_general}
                                           onChange={(event)=>this.setState({fecha_fin_general:event.target.value})}
                                           required
                                       />
                               </div>
                           </div><br/>
                           <hr/><hr/>
                           <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                               <p className="font-semibold">Bimestre 1</p>          
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2  w-full text-primary_2 "
                                       type="date"
                                       id="fecha_inicio_parcial1"
                                       name="fecha_inicio_parcial1"
                                       value={this.state.fecha_1_1}
                                       onChange={(event)=>this.setState({fecha_1_1:event.target.value})}
                                       required
                                   />
                               </div>
                               
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_fin_parcial1"
                                       value={this.state.fecha_1_2}
                                       onChange={(event)=>this.setState({fecha_1_2:event.target.value})}
                                       required
                                   />
                               </div>
                           </div>
    
                       <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                           <p className="font-semibold">Bimestre 2</p>          
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_inicio_parcial2"
                                       value={this.state.fecha_2_1}
                                       onChange={(event)=>this.setState({fecha_2_1:event.target.value})}
                                       required
                                   />
                               </div>
                           
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_fin_parcial2"
                                       value={this.state.fecha_2_2}
                                       onChange={(event)=>this.setState({fecha_2_2:event.target.value})}
                                       required
                                   />
                               </div>
                       </div>
    
                       <div className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                           <p className="font-semibold">Bimestre 3</p>          
                            <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_inicio_ordinario"
                                       value={this.state.fecha_3_1}
                                       onChange={(event)=>this.setState({fecha_3_1:event.target.value})}
                                       required
                                   />
                            </div>
                           
                           <div>
                               <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_fin_ordinario"
                                       value={this.state.fecha_3_2}
                                       onChange={(event)=>this.setState({fecha_3_2:event.target.value})}
                                       required
                                   />
                           </div>
                       </div>
    
                       <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                           <p className="font-semibold">Bimestre 4</p>          
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_inicio_titulo"
                                       value={this.state.fecha_4_1}
                                       onChange={(event)=>this.setState({fecha_4_1:event.target.value})}
                                       required
                                   />
                               </div>
                           
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_fin_titulo"
                                       value={this.state.fecha_4_2}
                                       onChange={(event)=>this.setState({fecha_4_2:event.target.value})}
                                       required
                                   />
                               </div>
                       </div>
    
                       <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                           <p className="font-semibold">Bimestre 5</p>          
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_inicio_titulo"
                                       value={this.state.fecha_5_1}
                                       onChange={(event)=>this.setState({fecha_5_1:event.target.value})}
                                       required
                                   />
                               </div>
                           
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_fin_titulo"
                                       value={this.state.fecha_5_2}
                                       onChange={(event)=>this.setState({fecha_5_2:event.target.value})}
                                       required
                                   />
                               </div>
                       </div>
                   </div>
    
                   <div className="flex items-center justify-center md:gap-8 gap-4 pt-5 pb-5 justify-items-center">
                           <div>   
                               <button
                                   type="submit"
                                   className="focus:outline-none focus:ring-2 w-auto bg-primary hover:bg-primary rounded-lg font-medium text-white px-5 py-3 transition duration-500 transform hover:scale-110"
                                   >
                                   Guardar
                               </button>
                           </div>	
                           <div>    
                               <button
                               onClick={()=>{this.props.history.push("/app/ListaPeriodos");}}
                               className="focus:outline-none focus:ring-2 w-auto bg-secondary hover:bg-secondary rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110"
                           >
                               Cancelar
                           </button>    
                           </div>											
                       </div>
               </form>
           </div>
            )
        
    
        this.formSemestre = (
                <div>
                <form className="" onSubmit={this.AgregarPeriodo}>
                       <div className="md:px-20 md:py-3 lg:px-30 lg:py-10 2xl:px-60">
                           <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2 justify-items-center"> <div/>                           
                               <div>
                                    <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                        Fecha inicio del periodo <br/>
                                    </label>
                                    <input
                                        className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2  w-full text-primary_2 "
                                        type="date"
                                        name="fecha_inicio_general"
                                        value={this.state.fecha_inicio_general}
                                        onChange={(event)=>this.setState({fecha_inicio_general:event.target.value})}
                                        required
                                    />
                               </div>
                           
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                           Fecha fin del periodo<br/>
                                       </label>
                                       <input
                                           className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2  w-full text-primary_2 "
                                           type="date"
                                           name="fecha_fin_general"
                                           value={this.state.fecha_fin_general}
                                            onChange={(event)=>this.setState({fecha_fin_general:event.target.value})}
                                           required
                                       />
                               </div>
                           </div><br/>
                           <hr/><hr/>
                           <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                               <p className="font-semibold">Parcial 1</p>          
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2  w-full text-primary_2 "
                                       type="date"
                                       id="fecha_inicio_parcial1"
                                       name="fecha_inicio_parcial1"
                                       value={this.state.fecha_1_1}
                                       onChange={(event)=>this.setState({fecha_1_1:event.target.value})}
                                       required
                                   />
                               </div>
                               
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_fin_parcial1"
                                       value={this.state.fecha_1_2}
                                       onChange={(event)=>this.setState({fecha_1_2:event.target.value})}
                                       required
                                   />
                               </div>
                           </div>
    
                       <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                           <p className="font-semibold">Parcial 2</p>          
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_inicio_parcial2"
                                       value={this.state.fecha_2_1}
                                       onChange={(event)=>this.setState({fecha_2_1:event.target.value})}
                                       required
                                   />
                               </div>
                           
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_fin_parcial2"
                                       value={this.state.fecha_2_2}
                                       onChange={(event)=>this.setState({fecha_2_2:event.target.value})}
                                       required
                                   />
                               </div>
                       </div>
    
                       <div className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                           <p className="font-semibold">Parcial 3</p>          
                            <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_inicio_ordinario"
                                       value={this.state.fecha_3_1}
                                       onChange={(event)=>this.setState({fecha_3_1:event.target.value})}
                                       required
                                   />
                            </div>
                           
                           <div>
                               <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_fin_ordinario"
                                       value={this.state.fecha_3_2}
                                       onChange={(event)=>this.setState({fecha_3_2:event.target.value})}
                                       required
                                   />
                           </div>
                       </div>
    
                       <div  className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2  justify-items-center">    
                           <p className="font-semibold">Extraordinario</p>          
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Inicio <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_inicio_titulo"
                                       value={this.state.fecha_4_1}
                                       onChange={(event)=>this.setState({fecha_4_1:event.target.value})}
                                       required
                                   />
                               </div>
                           
                               <div>
                                   <label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
                                       Fecha Fin <span className="text-red-500 text-xl"></span><br/>
                                   </label>
                                   <input
                                       className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 "
                                       type="date"
                                       name="fecha_fin_titulo"
                                       value={this.state.fecha_4_2}
                                       onChange={(event)=>this.setState({fecha_4_2:event.target.value})}
                                       required
                                   />
                               </div>
                       </div>
                   </div>
    
                   <div className="flex items-center justify-center md:gap-8 gap-4 pt-5 pb-5 justify-items-center">
                           <div>   
                               <button
                                   type="submit"
                                   className="focus:outline-none focus:ring-2 w-auto bg-primary hover:bg-primary rounded-lg font-medium text-white px-5 py-3 transition duration-500 transform hover:scale-110"
                                   >
                                   Guardar
                               </button>
                           </div>	
                           <div>    
                               <button
                               onClick={()=>{this.props.history.push("/app/ListaPeriodos");}}
                               className="focus:outline-none focus:ring-2 w-auto bg-secondary hover:bg-secondary rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110"
                           >
                               Cancelar
                           </button>    
                           </div>											
                       </div>
               </form>
           </div>
        )
        
    
        return(
            <div className="bg-primary_2 text-white h-auto pb-20">       
               
                    <div className='relative bg-primary_2 p-4 sm:p-6 rounded-sm overflow-hidden mb-8'>
						<div className='relative text-center'>
							<h1 className='text-2xl md:text-3xl text-white  font-bold mb-1 text-center'>
                            Nuevo periodo
							</h1>
						</div>
					</div>
                    <div className="flex justify-start mx-10 mt-1">
                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1">
                                <li class="flex justify-end">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Periodo Actual</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Nuevo periodo</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
				    </div>


                    <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-8 mt-10 ">
                        <label className="md:text-sm text-xs text-gray-500 text-light text-center font-semibold">
                        Escoger tipo de periodo<div/>
                        </label>

                        <select class="py-2 px-3 rounded-lg border-2 text-gray-700 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                        name="periodo"  
                        id="periodo" 
                        onChange={this.change_tipo_periodo}
                        
                        required>
                              <option value={"---"}>---</option>
                            {
                            this.state.tipo_periodos.map(
                                tip_periodos=>
                                    <option value={tip_periodos.id_tipo_periodo}>{tip_periodos.tipo_periodo}</option>
                                )
                            }
                        </select>
                    </div><br/>
                    {this.FormMostrar()}
            </div>
        )
    }
}
export default withRouter(AgregarPeriodo);