import { Component } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom"
import moneyg from "../images/give-money.png"
import timep from "../images/timep.png"
import adminp from "../images/moneyadmin.png"
import GestorPagoService from "services/GestorPagoService";
import swal from 'sweetalert2';
import AguilaCaida from "../images/aguila_caida.svg"
import { Link } from "react-router-dom";
import maternal from "../images/maternal.png"
import preescolar from "../images/preescolar.png"
import primaria from "../images/primaria.png"
import secundaria from "../images/secundaria.png"
import bachillerato from "../images/bachillerato.png"
import universidad from "../images/universidad.png"
import diplomado from "../images/diplomado.png"
import close from '../images/cancel.svg'

class GestionPagos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalproyecto: false,
            planteles: [],
            clickboton:""
        }

        this.goBack = this.goBack.bind(this)
        this.ModalProyecto = this.ModalProyecto.bind(this);

    }

    componentDidMount(){
            sessionStorage.setItem("id_plantel","")
            sessionStorage.setItem("nombre_plantel","")
    }

    goBack() { this.props.history.goBack(); }

    ModalProyecto(nombre) {
        this.ListarPlanteles()
        this.setState({ modalproyecto: !this.state.modalproyecto, clickboton:nombre });
    }


    ListarPlanteles = async()=>{
        GestorPagoService.ListarPlanteles().then((res) => {
            if (res.data == 0) {
                swal.fire({
                    imageUrl: AguilaCaida,
                    title: 'Error',
                    text: 'No hay planteles',
                });
                this.goBack();
            }
            else {
                console.log("planteles ", res.data)
                this.setState({ planteles: res.data });
            }
        })
    }

    ClickHistorialPagos = async(id_plantel, nombre)=>{
        sessionStorage.setItem("id_plantel",id_plantel)
        sessionStorage.setItem("nombre_plantel",nombre)
        if(this.state.clickboton === "Historial"){
            this.props.history.push('/app/GestionHistorialPagos');
        }
        else if(this.state.clickboton === "Encurso"){
            this.props.history.push('/app/GestionPagoActual');
        }
        else if(this.state.clickboton === "Administrar"){
            this.props.history.push('/app/AdministrarMensualidades');
        }
    }

    render() {
        return (
            <>
                <div>
                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>

                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">gestión pagos</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>

                    <div>
                        <div className='relative p-4 sm:p-6 rounded-sm overflow-hidden mb-4'>
                            <div className='relative text-center'>
                                <h1 className='text-2xl md:text-3xl text-white font-bold mb-1 text-center'>
                                    GESTIÓN PAGOS
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div className=" w-full mt-8">

                        <div class=' px-2 md:px-10 pt-4 md:pt-3 pb-5 overflow-y-auto'>
                            <div class='grid grid-cols-3 gap-2'>
                                <div className='mr-10 shadow-2xl rounded-md bg-secondary_2'>
                                    <br />
                                    <div class='flex justify-center'>
                                        <img
                                            src={adminp}
                                            class='w-10 h-10 sm:w-16 sm:h-16'
                                            alt=''></img>
                                    </div>
                                    <div class='flex justify-center'>
                                        <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
                                            {this.state.totalAlumnos}
                                        </h1>
                                    </div>
                                    <div class='flex justify-center'>
                                        <h1 class='text-white text-xl sm:text-xl'>Administrar</h1>
                                    </div>

                                  
                                    <div class='flex justify-center mt-2'>
                                        <div class="mr-2 transform hover:text-purple-500 hover:scale-110">
                                            <button onClick={() => this.ModalProyecto("Administrar")} className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
                                                <p className='text-sm font-medium leading-none text-white text-center'>
                                                    <i class="fas fa-dollar-sign"></i> ir
                                                </p>
                                            </button>
                                        </div>

                                    </div>
                                    <br />
                                    <br />

                                </div>

                                <div className='mr-10 shadow-2xl rounded-md bg-secondary_2'>
                                    <br />
                                    <div class='flex justify-center'>
                                        <img
                                            src={timep}
                                            class='w-10 h-10 sm:w-16 sm:h-16'
                                            alt=''></img>
                                    </div>
                                    <div class='flex justify-center'>
                                        <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
                                            {this.state.totalAlumnos}
                                        </h1>
                                    </div>
                                    <div class='flex justify-center'>
                                        <h1 class='text-white text-xl sm:text-xl'>Historial</h1>
                                    </div>

                                
                                    <div class='flex justify-center mt-2'>
                                        <div class="mr-2 transform hover:text-purple-500 hover:scale-110">
                                            <button onClick={() => this.ModalProyecto("Historial")} className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
                                                <p className='text-sm font-medium leading-none text-white text-center'>
                                                    <i class="fas fa-dollar-sign"></i> ir
                                                </p>
                                            </button>
                                        </div>

                                    </div>

                                    <br />
                                    <br />

                                </div>

                                <div className='mr-10 shadow-2xl rounded-md bg-secondary_2'>
                                    <br />
                                    <div class='flex justify-center'>
                                        <img
                                            src={moneyg}
                                            class='w-10 h-10 sm:w-16 sm:h-16'
                                            alt=''></img>
                                    </div>
                                    <div class='flex justify-center'>
                                        <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>

                                        </h1>
                                    </div>
                                    <div class='flex justify-center'>
                                        <h1 class='text-white text-xl sm:text-xl'>En curso</h1>
                                        <br />
                                    </div>

                                    <div class='flex justify-center mt-2'>
                                        <div class="mr-2 transform hover:text-purple-500 hover:scale-110">
                                            <button onClick={() => this.ModalProyecto("Encurso")} className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
                                                <p className='text-sm font-medium leading-none text-white text-center'>
                                                    <i class="fas fa-dollar-sign"></i> ir
                                                </p>
                                            </button>
                                        </div>

                                    </div>
                                    <br />
                                    <br />
                                </div>



                            </div>
                        </div>

                    </div>

                </div>

                {this.state.modalproyecto ? (
                    <>
                        <div div class="overflow-y-auto h-32">
                            <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                <div className='relative w-auto my-6 mt-40 mx-auto max-w-3xl'>
                                    {/*content*/}
                                    <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2'>
                                        {/*header*/}
                                        <div className='flex items-center justify-end p-1 absolute -top-3 -right-3'>
                                            <button
                                                className='text-black-500 background-transparent font-bold uppercase px-3 py-3 rounded-full text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 bg-red-500'
                                                type='button'
                                                onClick={() => this.setState({modalproyecto:!this.state.modalproyecto})}>
                                                <img src={close} className="w-7" />
                                            </button>
                                        </div>
                                        <div className=' rounded-t-3xl'>
                                            <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>seleccione el plantel <i class="fas fa-project-diagram"></i></p>
                                        </div>
                                     
                                        <div class=' px-2 md:px-10 pt-4 md:pt-3  overflow-y-auto'>
                                            <div class='grid grid-cols-3 gap-5'>
                                                
                                                {
                                                    this.state.planteles.map(
                                                        plantel =>
                                                            <div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
                                                            
                                                                <div class='flex justify-center'>
                                                                    {
                                                                        plantel.Nombre === "MATERNAL" ?  <img
                                                                        src={maternal}
                                                                        class='w-8 h-8 lg:w-16 lg:h-16'
                                                                        alt=''></img>  :
                                                                        plantel.Nombre === "PREESCOLAR" ? <img
                                                                        src={preescolar}
                                                                        class='w-8 h-8 lg:w-16 lg:h-16'
                                                                        alt=''></img> :
                                                                        plantel.Nombre === "PRIMARIA" ? <img
                                                                        src={primaria}
                                                                        class='w-8 h-8 lg:w-16 lg:h-16'
                                                                        alt=''></img> :
                                                                        plantel.Nombre === "SECUNDARIA" ? <img
                                                                        src={secundaria}
                                                                        class='w-8 h-8 lg:w-16 lg:h-16'
                                                                        alt=''></img> :
                                                                        plantel.Nombre === "BACHILLERATO" ? <img
                                                                        src={bachillerato}
                                                                        class='w-8 h-8 lg:w-16 lg:h-16'
                                                                        alt=''></img> :
                                                                        plantel.Nombre === "UNIVERSIDAD" ? <img
                                                                        src={universidad}
                                                                        class='w-8 h-8 lg:w-16 lg:h-16'
                                                                        alt=''></img> :
                                                                        plantel.Nombre === "DIPLOMADOS" ? <img
                                                                        src={diplomado}
                                                                        class='w-8 h-8 lg:w-16 lg:h-16'
                                                                        alt=''></img> : " "
                                                                    }
                                                                
                                                                </div>                                         
                                                            
                                                                <div className=' flex justify-center  pt-3'>
                                                                <button
                                                                    className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '
                                                                    onClick={()=>this.ClickHistorialPagos(plantel.ID_Plantel, plantel.Nombre)}>
                                                                            <h1 class='text-gray-500 text-xl sm:text-xl font-barlow tracking-widest text-white'>{plantel.Nombre} </h1>
                                                                </button>
                                                                </div>
                                                            </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                            <button
                                                className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                type='button'
                                                onClick={() => this.setState({modalproyecto:!this.state.modalproyecto})}>
                                                Cerrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
                        </div>
                    </>
                ) : null}



            </>
        );
    }

}

export default withRouter(GestionPagos);