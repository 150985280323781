
import axios from 'axios'; 
import {APIURL} from "./Host";

class AcademicoDocenteService {
   /* EspecialidadesDocente(id_docente){
       console.log(APIURL+"/especialidades_docente/"+id_docente);
        return axios.get(APIURL+"/especialidades_docente/"+id_docente);
    }
*/
    AsignaturasByEspDoc(id_docente){
        return axios.get(APIURL+"/asignatura_especialidad_docente/"+id_docente);
    }

    TipoParciales(){
        return axios.get(APIURL+"/tipo_parciales");
    }
    FiltradoAlumnosbyParcial(id_tipo_evaluacion, id_asignatura_docente){
        return axios.get(APIURL+"/alumnos_by_parcial/"+id_tipo_evaluacion+"/"+id_asignatura_docente);
    }
    GuardarCalificaciones(ArrayCalif){
        return axios.post(APIURL+"/guardar_calificaciones",ArrayCalif);
    }
    FiltradoAlumnosTitulo(id_tipo_evaluacion, id_asignatura_docente){
        return axios.get(APIURL+"/alumnos_by_titulo/"+id_tipo_evaluacion+"/"+id_asignatura_docente);
    }
   
	BusquedaGeneral(txtbuscar, id_docente){
		return axios.get(APIURL + "/busqueda_general/"+txtbuscar+"/"+id_docente);
	}
} 
export default new AcademicoDocenteService();