import React, { Component, Fragment } from 'react'
import UsuariosService from 'services/UsuariosService';
import { withRouter } from "react-router";
import logo_white from '../images/logotipo_white.png';
import ReactPlayer from 'react-player/lazy'
import imgNoHayFoto from '../images/user.png';
import swal from 'sweetalert2';
import imgane from '../images/12.png'
import ImgCargando from "../images/icono_cargando.svg"
import alumno from "../images/gestion_de_usuarios/graduado.svg"
import mis_asignaturas from "../images/mis_asignaturas.png"
import bibliotecas from "../images/mis_bibliotecas.png"
import cursos_euro from "../images/cursos_euro.png"
import beneficios from "../images/bene_alumno.png"
import bolsa from "../images/bolsa_trabajo.png"
import servicios from "../images/servicios_escolares.png"
import becas from "../images/solicitud_becas.png"
import tutoriales from "../images/tutores.png"
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
class Landing extends Component {
    constructor(props){
        super(props);
        this.state={
            id_usuario:sessionStorage.getItem("id"),
            mostrarModalMsj: false,
            correo: "",
            bloquearBotones : false,
            errors : {},
        }
    }

    componentDidMount() {
        let list = document.querySelectorAll('.topNavList');
        for (let i = 0; i < list.length; i++) {
            //console.log("FOR: ", list[i])
            list[i].onclick = function () {
                let j = 0;
                while (j < list.length) {
                    list[j++].className = 'topNavList';
                }
                list[i].className = 'topNavList active'
            }
        }

        UsuariosService.obtenerCorreoUsuarioSession(this.state.id_usuario).then((res)=>{
            if (res.data["correo"]!=undefined){
                if (!res.data["correo"]){
                    this.setState({mostrarModalMsj:true},()=>{ document.getElementById("inputCorreo").focus(); });
                } else {
                    if (!res.data["info"]){
                        swal.fire({
                            text: "Falta agregar su información personal.",
                            icon: "info",
                            showConfirmButton: true,
                            //allowOutsideClick: false,
                            //allowEscapeKey: false
                        }).then(() => {
                            //this.props.history.push("/app/PerfilUsuario");
                        });
                    }
                }
            }
		});
    }

    asignarValor =(event)=>{ this.setState({ [event.target.name] : event.target.value }); }

    blurCorreo =()=>{
		let expReg = /^(\w+[/./-]?){1,}@[a-z0-9.-]+[/.]\w{2,4}$/;
		if (!this.state.correo.trim()){
			this.state.errors.correo = "Correo electrónico requerido";
		} else if (this.state.correo.trim() != "" && (!expReg.test(this.state.correo.trim()))) {
			this.state.errors.correo = "Correo electrónico no valido";
		} else {
			this.state.errors.correo = "";
			delete this.state.errors.correo;
		}
	}

    ActualizarCorreo = async(e)=>{
        e.preventDefault();
        this.setState({bloquearBotones : true});
        this.blurCorreo();
        if (Object.keys(this.state.errors).length === 0){
            UsuariosService.VerificarCorreoRegistrado(this.state.correo).then((response) => {
                if (response.data){
                    this.state.errors.correo = "Correo electrónico ya esta registrado";
                    this.setState({bloquearBotones : false});
                } else {
                    this.state.errors.correo = "";
                    delete this.state.errors.correo;
                    UsuariosService.agregarCorreoElectronicoUsuario({id: this.state.id_usuario,correo:this.state.correo}).then((res)=>{
                        if(res.data){
                            swal.fire({
                                icon: "success",
                                title: 'Success',
                                text: 'Correo electrónico actualizado correctamente',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            });
                            this.setState({mostrarModalMsj:false});
                        } else {
                            swal.fire({
                                icon: "error",
                                title: 'Error',
                                text: 'Error al actualizar el correo electrónico',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            });
                        }
                        this.setState({bloquearBotones : false});
                    }).catch(error => {
                        swal.fire({
                            text: "No se pudo agregar el correo electrónico, inténtelo mas tarde.",
                            icon: "error",
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        })
                        this.setState({bloquearBotones : false});
                    });
                }
            });
        } else {
            swal.fire({
                title:"Error",
                text: "Falta correo electrónico valido",
                icon: "error",
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            this.setState({bloquearBotones : false});
        }
    }

    render() {
        return (
            <div  className="w-screen h-screen px-2 flex-col  text-color5 ">
                <div className='xl:w-full xl:h-full xl:items-center xl:justify-center xl:py-10 
                                lg:w-full lg:h-full lg:items-center lg:justify-center lg:py-10
                                md:w-full md:h-full  md:items-center md:justify-center md:px-10 lg:relative    
                                w-full h-full content-center items-center justify-center'>
							<img src={logo_white} className='w-full h-full py-0'/>
						
                </div>
				{ (sessionStorage.getItem("roles")==='["ALUMNO"]')&&
							<div className='xl:w-full xl:h-full xl:bg-white xl:items-center xl:justify-center xl:px-40
											lg:w-full lg:h-full lg:bg-white lg:items-center lg:justify-center lg:px-10 
											md:w-full md:h-full md:bg-white md:items-center md:justify-center md:px-10 lg:relative
											w-full h-full bg-landing-2 bg-cover px-40 py-20 shrink'>
											<ReactPlayer className='react-player h-full w-full'  url='https://plataforma.instituto-euroamericano.edu.mx:9443/videos/Video_Universidad_Euro.mp4' 
														playing='true'
														controls='true'
														muted 
														volume='1'  
														loop='false' 
														width='90%' 
														height='90%'
														config={{ 
															file: { 
															attributes: {
																	controlsList: 'nodownload'
																} 
														} 
														}}
														/>
							</div>
				}
				{(sessionStorage.getItem("roles")==='["ALUMNO"]')&&
                <div className='xl:w-full xl:h-full xl:bg-white xl:items-center xl:justify-center xl:px-40
                                lg:w-full lg:h-full lg:bg-white lg:items-center lg:justify-center lg:px-10 
                                md:w-full md:h-full md:bg-white md:items-center md:justify-center md:px-10
                                w-full h-full bg-landing-3 bg-cover justify-center items-center flex     '>
                    <div className='grid grid-cols-4 gap-5 px-auto w-full h-1/2 px-2 md:px-10 pt-4 md:pt-3  flex flex-row  '>
                            {
								<div className='w-full h-full shadow-3xl rounded-md bg-gray-400 relative py-5'>
										<div class='flex justify-center'>
										<img
										src={mis_asignaturas}
										class='w-1/4 h-1/4 sm:w-30 sm:h-20'
										alt=''></img>
										</div>
										<div class='flex justify-center py-2'>
				
									</div>
									<div class='flex justify-center'>
										<h1 class='text-white text-xl sm:text-xl font-barlow'>MIS ASIGNATURAS</h1>
									</div>
									{/* <div class='flex justify-center'>
									<h1 class='text-activo text-xl sm:text-xl'>ACTIVOS</h1>
									</div> */}
									<div class='flex justify-center pt-3'>
										<NavLink
											to='/app/AcademicoAlumnosAsignaturas'
											className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
											<p className='text-sm font-medium leading-none text-white text-center font-barlow '>
											Ir
											</p>
										</NavLink>
									</div>
								</div>
								
							}
                            {
								<div className='w-full h-full shadow-3xl rounded-md bg-gray-400 relative py-5'>
										<div class='flex justify-center'>
										<img
										src={bibliotecas}
										class='w-1/4 h-1/4 sm:w-30 sm:h-20'
										alt=''></img>
										</div>
										<div class='flex justify-center py-2'>
				
									</div>
									<div class='flex justify-center'>
										<h1 class='text-white text-xl sm:text-xl font-barlow '>MIS BIBLIOTECAS</h1>
									</div>
									{/* <div class='flex justify-center'>
									<h1 class='text-activo text-xl sm:text-xl'>ACTIVOS</h1>
									</div> */}
									<div class='flex justify-center pt-3'>
										<NavLink
											to='/app/bibliotecas'
											className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
											<p className='text-sm font-medium leading-none text-white text-center font-barlow '>
											Ir
											</p>
										</NavLink>
									</div>
								</div>
								
							}
                            {
								<div className='w-full h-full shadow-3xl rounded-md bg-gray-400 relative py-5'>
										<div class='flex justify-center'>
										<img
										src={cursos_euro}
										class='w-1/4 h-1/4 sm:w-30 sm:h-20'
										alt=''></img>
										</div>
										<div class='flex justify-center py-2'>
				
									</div>
									<div class='flex justify-center'>
										<h1 class='text-white text-xl sm:text-xl font-barlow'>CURSOS EUROAMERICANO</h1>
									</div>
									{/* <div class='flex justify-center'>
									<h1 class='text-activo text-xl sm:text-xl'>ACTIVOS</h1>
									</div> */}
									<div class='flex justify-center pt-3'>
										<NavLink
											to='/app/cursoseuro'
											className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
											<p className='text-sm font-medium leading-none text-white text-center font-barlow '>
											Ir
											</p>
										</NavLink>
									</div>
								</div>
								
							}
                            {
								<div className='w-full h-full shadow-3xl rounded-md bg-gray-400 relative py-5'>
										<div class='flex justify-center'>
										<img
										src={beneficios}
										class='w-1/4 h-1/4 sm:w-30 sm:h-20'
										alt=''></img>
										</div>
										<div class='flex justify-center py-2'>
				
									</div>
									<div class='flex justify-center'>
										<h1 class='text-white text-xl sm:text-xl font-barlow '>¿YA CONOCERS LOS BENEFICIOS DE SER ALUMNO EURO?</h1>
									</div>
									{/* <div class='flex justify-center'>
									<h1 class='text-activo text-xl sm:text-xl'>ACTIVOS</h1>
									</div> */}
									<div class='flex justify-center pt-3'>
										<NavLink
											to='/app/cursoseuro'
											className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
											<p className='text-sm font-medium leading-none text-white text-center font-barlow '>
											Ir
											</p>
										</NavLink>
									</div>
								</div>
								
							}
                            {
								<div className='w-full h-full shadow-3xl rounded-md bg-gray-400 relative py-5'>
										<div class='flex justify-center'>
										<img
										src={bolsa}
										class='w-1/4 h-1/4 sm:w-30 sm:h-20'
										alt=''></img>
										</div>
										<div class='flex justify-center py-2'>
				
									</div>
									<div class='flex justify-center'>
										<h1 class='text-white text-xl sm:text-xl font-barlow '>BOLSA DE TRABAJO</h1>
									</div>
									{/* <div class='flex justify-center'>
									<h1 class='text-activo text-xl sm:text-xl'>ACTIVOS</h1>
									</div> */}
									<div class='flex justify-center pt-3'>
										<NavLink
											to='/app/cursoseuro'
											className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
											<p className='text-sm font-medium leading-none text-white text-center font-barlow '>
											Ir
											</p>
										</NavLink>
									</div>
								</div>
								
							}
                            {
								<div className='w-full h-full shadow-3xl rounded-md bg-gray-400 relative py-5'>
										<div class='flex justify-center'>
										<img
										src={servicios}
										class='w-1/4 h-1/4 sm:w-30 sm:h-20'
										alt=''></img>
										</div>
										<div class='flex justify-center py-2'>
				
									</div>
									<div class='flex justify-center'>
										<h1 class='text-white text-xl sm:text-xl font-barlow '>SERVICIOS ESCOLARES</h1>
									</div>
									{/* <div class='flex justify-center'>
									<h1 class='text-activo text-xl sm:text-xl'>ACTIVOS</h1>
									</div> */}
									<div class='flex justify-center pt-3'>
										<NavLink
											to='/app/cursoseuro'
											className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
											<p className='text-sm font-medium leading-none text-white text-center font-barlow '>
											Ir
											</p>
										</NavLink>
									</div>
								</div>
								
							}
                             {
								<div className='w-full h-full shadow-3xl rounded-md bg-gray-400 relative py-5'>
										<div class='flex justify-center'>
										<img
										src={becas}
										class='w-1/4 h-1/4 sm:w-30 sm:h-20'
										alt=''></img>
										</div>
										<div class='flex justify-center py-2'>
				
									</div>
									<div class='flex justify-center'>
										<h1 class='text-white text-xl sm:text-xl font-barlow '>SOLICITUD DE BECAS</h1>
									</div>
									{/* <div class='flex justify-center'>
									<h1 class='text-activo text-xl sm:text-xl'>ACTIVOS</h1>
									</div> */}
									<div class='flex justify-center pt-3'>
										<NavLink
											to='/app/cursoseuro'
											className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
											<p className='text-sm font-medium leading-none text-white text-center font-barlow '>
											Ir
											</p>
										</NavLink>
									</div>
								</div>
								
							}
                             {
								<div className='w-full h-full shadow-3xl rounded-md bg-gray-400 relative py-5'>
										<div class='flex justify-center'>
										<img
										src={tutoriales}
										class='w-1/4 h-1/4 sm:w-30 sm:h-20'
										alt=''></img>
										</div>
										<div class='flex justify-center py-2'>
				
									</div>
									<div class='flex justify-center'>
										<h1 class='text-white text-xl sm:text-xl font-barlow '>TUTORIALES</h1>
									</div>
									{/* <div class='flex justify-center'>
									<h1 class='text-activo text-xl sm:text-xl'>ACTIVOS</h1>
									</div> */}
									<div class='flex justify-center pt-3'>
										<NavLink
											to='/app/cursoseuro'
											className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
											<p className='text-sm font-medium leading-none text-white text-center font-barlow '>
											Ir
											</p>
										</NavLink>
									</div>
								</div>
								
							}
				
                            
                    </div>
                </div>
								
			}		


                
                
                

            </div>
        )
    }

    
}


export default withRouter(Landing);
