import React, { Component, Fragment } from "react";
import { NavLink } from 'react-router-dom';
import EspecialidadService from "services/EspecialidadService";
import CatalogoDireccionService from "services/CatalogoDireccionService";
import { Link } from 'react-router-dom';
import swal from 'sweetalert2/dist/sweetalert2.js'
import open from "../images/open.png"
import TutoresAdminService from "services/TutoresAdminService";
import IconoHome from "../images/icono_home.svg"
import IconoFlechaSig from "../images/icono_flecha_sig.svg"
import ImgCargando from "../images/icono_cargando.svg"
import AguilaOk from "../images/aguila_ok.svg"
import AguilaCaida from "../images/aguila_caida.svg"

export default class ListaTuroresAdmin extends Component{
	constructor(props) {
		super(props)
		this.state = {
			lista_tutores : [],
			htmlPaginacion : "",
			datoBuscar: "",
			mostrarModalTutorados: false,
			tutoradosSelect: [],
			nombreTutorSelect: "",
		}
		this.registroSiguiente = 0;
		this.registrosPorPagina = 50;
		this.registrosTotales = 0;
		this.paginaActual = 1;
	}
	
	ListaTurores(registroInicial=0){
		TutoresAdminService.ListaTurores({"registroInicial":registroInicial, "registrosPorPagina":this.registrosPorPagina,"datoBuscar":this.state.datoBuscar}).then((res)=>{
			this.setState({lista_tutores:res.data[0]});
			this.registrosTotales = res.data[1]["total_registros"];
			this.registroSiguiente = registroInicial;
			this.paginacionTabla();
		});
	}

	componentDidMount() {
		this.ListaTurores();
	}

	asignarValor =(event)=>{ this.setState({ [event.target.name] : event.target.value }); }

	paginacionTabla =()=>{
		let htmlPaginacion = (
			<div className='flex items-center justify-end pt-2 pb-2 border-t border-solid border-blueGray-200 rounded-b'>
				<p className="font-bold mr-3">Página {this.paginaActual} de {(Math.floor(parseInt(this.registrosTotales)/parseInt(this.registrosPorPagina))+1)}</p>
				{(parseInt(this.registroSiguiente)-parseInt(this.registrosPorPagina))>=0 && (
					<button className='bg-emerald-500 text-black active:bg-gray-600 font-bold text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 p-2 text-white' type='button' onClick={()=>{this.ListaTurores(0); this.paginaActual=1; }} style={{"background": "#18253F"}} >Primero</button>
				)}
				{(parseInt(this.registroSiguiente)-parseInt(this.registrosPorPagina))>=0 && (
					<button className='bg-emerald-500 text-black active:bg-gray-600 font-bold text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 p-2 text-white' type='button' onClick={()=>{ let numAnt = (parseInt(this.registroSiguiente)-parseInt(this.registrosPorPagina)); this.ListaTurores(numAnt>0?numAnt:0); this.paginaActual=parseInt(this.paginaActual)-1 }} style={{"background": "#18253F"}} >{"<"}</button>
				)}
				{parseInt(this.registroSiguiente)+parseInt(this.registrosPorPagina)<=(parseInt(this.registrosTotales)) && (
					<button className='bg-emerald-500 text-black active:bg-gray-600 font-bold text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 p-2 text-white' type='button' onClick={()=>{ this.ListaTurores(parseInt(this.registroSiguiente)+parseInt(this.registrosPorPagina)); this.paginaActual=parseInt(this.paginaActual)+1; }} style={{"background": "#18253F"}}>{">"}</button>
				)}
				{parseInt(this.registroSiguiente)+parseInt(this.registrosPorPagina)<=(parseInt(this.registrosTotales)) && (
					<button className='bg-emerald-500 text-black active:bg-gray-600 font-bold text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 p-2 text-white' type='button' onClick={()=>{ this.ListaTurores(((Math.floor(parseInt(this.registrosTotales)/parseInt(this.registrosPorPagina)))*parseInt(this.registrosPorPagina))); this.paginaActual=(Math.floor(parseInt(this.registrosTotales)/parseInt(this.registrosPorPagina))+1); }} style={{"background": "#18253F"}}>Último</button>
				)}
			</div>
		);
		this.setState({htmlPaginacion:(parseInt(this.registrosPorPagina)<parseInt(this.registrosTotales) ? htmlPaginacion : "")});
	}

	separadorNumero(val){
		if (val !== undefined && val !== null && val!==""){
			let arr=val.toString().split("");
			let sep=arr.indexOf(".");
			if(sep<0){arr.push(".","0","0"); sep=arr.indexOf(".");}
			let cont=0;
			if(sep>=4){
				cont=sep-1;
				while(cont+1>3){
					cont=cont-3;
					arr.splice(cont+1,0,",");
				}
			}
			return (arr.join(""));
		} else { return ""; }
	}

	render() {
		return (
			<>
				<div className="bg-primary_2 text-white h-auto pb-20">
					<Fragment>
					<div className="w-full bg-primary_2 p-4 sm:p-6 overflow-hidden h-20 z-10">
						<div className="relative text-center">
							<h1 className="text-2xl md:text-3xl text-white font-bold mb-1 text-center font-barlow tracking-widest">
							Gestión de tutores
							</h1>
						</div>
					</div>

				
						<div className="col-span-5 lg:col-span-6 w-full flex items-center">
							<nav class="flex relative" aria-label="Breadcrumb">
								<ol class="flex items-center pl-10">
									<li class="inline-flex items-center">
										<Link to="/app" className="inline-flex items-center text-sm text-white dark:text-white "> 
											<svg class="mr-2 w-4 h-4 text-white group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
											<a class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Home</a>
										</Link>
									</li>
									<li>
										<div class="flex items-center">
											<Link to="/app/ListaUsuarios" className="inline-flex items-center text-sm text-white dark:text-white ">
												<svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
												<a onClick={this.goBack} class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Usuarios</a>
											</Link>
										</div>
									</li>
									<li aria-current="page">
                                        <div class="flex items-center">
                                            <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                            <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Gestión de tutores</span>
                                        </div>
                                    </li>
								
								</ol>
							</nav>
						</div>

						<div className="col-span-2">
                            <div className="flex justify-start ">
                                <div class="xl:w-96 mt-8">
								<div class="ml-10 input-group relative flex flex-wrap items-stretch w-full mb-4 ">
										<input 
										type="search" 
										class="form-control relative flex-auto px-3 py-1.5 text-base font-normal text-black bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none"
										placeholder="Buscar..." 
										name="datoBuscar"
										value={this.state.datoBuscar} 
										onChange={event => this.asignarValor(event)} onKeyPress={e => { if (e.key=="Enter"){ this.ListaTurores(); } }}/>
										<button 
											onClick={()=>{ this.ListaTurores(); }}
											><svg class="h-6 w-6 text-white ml-2 mt-5 "  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
										</button>
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-row-reverse mr-10'>
							<NavLink to={{ pathname: '/app/FormUsuario', state: { idTipoUsuarioSelect: 8 } }} rel="noopener noreferrer" className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 h-9'>
									<p className='text-sm font-medium leading-none text-white'><i className='fas fa-plus-circle'></i> Agregar tutores</p>
								</NavLink>
                            </div>
                        </div>
					
					<div className="px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto" >
						<div className="w-full ">
                            <div className="w-full text-sm grid grid-cols-3 text-gray-800 font-semibold">
								<div className="font-semibold text-center ml-3 px-6 py-3 text-white">
									<div className="flex justify-start items-center">
										<div>
											<label className="text-lg font-barlow tracking-widest" htmlFor="">Nombre</label><br/>
										</div>
									</div>
								</div>
								<div className="font-semibold text-center ml-3 px-6 py-3 text-white">
									<div className="flex justify-start items-center">
										<div>
											<label className="text-lg font-barlow tracking-widest" htmlFor="">Teléfono</label><br/>
										</div>
									</div>
								</div>
								<div className="font-semibold text-center ml-3 px-6 py-3 text-white">
									<div className="flex justify-start items-center">
										<div>
											<label className="text-lg font-barlow tracking-widest" htmlFor="">Correo electrónico</label><br/>
										</div>
									</div>
								</div>
							</div>
							{this.state.lista_tutores.map(item =>
								<div key={item.id_usuario} className="w-full flex justify-center items-center cursor-pointer" onClick={()=>{ this.setState({mostrarModalTutorados:true, tutoradosSelect: item.tutorados, nombreTutorSelect: item.nombre}); }}>
									<div className="mt-5 rounded-2xl w-10/12 md:w-full lg:grid grid-cols-3 h-auto px-5 py-5 bg-secondary_2 relative">
										<div className="flex justify-start items-center">
											<h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.nombre}</h1>
										</div>
										<div className="flex justify-start items-center">
											<h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.telefono}</h1>
										</div>
										<div className="flex justify-start items-center">
											<input type="text" className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest w-full outline-none" style={{"background":"none"}} readonly value={item.correo}/>
										</div>
									</div>
								</div>
							)}
							{this.state.htmlPaginacion}
						</div>
					</div>
					{this.state.mostrarModalTutorados && (
						<Fragment>
							<form onSubmit={this.cambiarFotoUsuario}>
								<div className="overflow-y-hidden">
									<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
										<div className='relative w-auto my-6 mx-auto max-w-6xl z-20'>
											<div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2'>
												<div className='rounded-t-3xl'>
													<h3 className='text-primary text-3xl text-center my-5 font-bold uppercase'>Tutorados de {this.state.nombreTutorSelect}</h3>
												</div>
												<div className='relative flex justify-center items-center text-center '>
													<div className='grid grid-cols-1 gap-1 px-6 py-5  justify-center items-center '>
														<div className="w-full text-sm grid grid-cols-4 text-gray-800 font-semibold">
															<div className="font-semibold text-center ml-3 px-6 py-3">
																<div className="flex justify-start items-center">
																	<div>
																		<label className="text-lg font-barlow tracking-widest text-black" htmlFor="">Nombre</label><br/>
																	</div>
																</div>
															</div>
															<div className="font-semibold text-center ml-3 px-6 py-3">
																<div className="flex justify-start items-center">
																	<div>
																		<label className="text-lg font-barlow tracking-widest text-black" htmlFor="">Matrícula</label><br/>
																	</div>
																</div>
															</div>
															<div className="font-semibold text-center ml-3 px-6 py-3">
																<div className="flex justify-start items-center">
																	<div>
																		<label className="text-lg font-barlow tracking-widest text-black" htmlFor="">Cantidad</label><br/>
																	</div>
																</div>
															</div>
															<div className="font-semibold text-center ml-3 px-6 py-3">
																<div className="flex justify-start items-center">
																	<div>
																		<label className="text-lg font-barlow tracking-widest text-black" htmlFor="">Plantel</label><br/>
																	</div>
																</div>
															</div>
														</div>
														{this.state.tutoradosSelect.map(item =>
															<div key={item.id_usuario} className="w-full flex justify-center items-center">
																<div className="mt-5 rounded-2xl w-10/12 md:w-full lg:grid grid-cols-4 h-auto px-5 py-5 bg-secondary_2 relative">
																	<div className="flex justify-start items-center">
																		<h1 className="text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.nombre}</h1>
																	</div>
																	<div className="flex justify-start items-center">
																		<h1 className="text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.matricula}</h1>
																	</div>
																	<div className="flex justify-start items-center">
																		<h1 className="text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{this.separadorNumero(item.cantidad)}</h1>
																	</div>
																	<div className="flex justify-start items-center">
																		<h1 className="text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.plantel}</h1>
																	</div>
																</div>
															</div>
														)}
													</div>
												</div>
												<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
													<button className='text-green-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150' type='button' onClick={ ()=> { this.setState({mostrarModalTutorados:false, fotoFile:null}); }} >Cerrar</button>
												</div>
											</div>
										</div>
										<div onClick={ ()=> { this.setState({mostrarModalTutorados:false, fotoFile:null}); }} className='cursor-pointer opacity-25 fixed inset-0 bg-black' style={{"right":"12px"}}></div>
									</div>
								</div>
							</form>
						</Fragment>
					)}
				</Fragment>
				</div>
			</>
		)
	}
	

}