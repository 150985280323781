import { Component } from "react-image-crop";
import PeriodosService from "services/PeriodosService";
import AsignacionMateriasDocente from "./AsignacionMateriasDocente";
import { NavLink } from "react-router-dom";
import GestorPagoService from "services/GestorPagoService";
import swal from 'sweetalert2';
import AguilaCaida from "../images/aguila_caida.svg"
import { withRouter } from "react-router-dom";
import docente from "../images/gestion_de_usuarios/profesor.svg"
import alumno from "../images/gestion_de_usuarios/graduado.svg"

class CargaAcademicoByPeriodos extends Component{

    constructor(props){
        super(props);
        this.state={
            planteles:[]
        }
    }

    componentDidMount(){
        sessionStorage.setItem("plantel_id", 0)
        sessionStorage.setItem("nombre_plantel", "")

        GestorPagoService.ListarPlanteles().then((res) => {
            if (res.data == 0) {
                swal.fire({
                    imageUrl: AguilaCaida,
                    title: 'Error',
                    text: 'No hay planteles',
                });
                this.goBack();
            }
            else {
                //console.log("planteles ", res.data)
                this.setState({ planteles: res.data });
                sessionStorage.setItem("plantel_id",res.data[0].ID_Plantel);
                sessionStorage.setItem("nombre_plantel",res.data[0].Nombre);
            
            }
        })
    }

    CargaAcademico = async(id_plantel, nombre_plantel, usuario)=>{
        console.log("clic "+nombre_plantel)
        //window.$plantel_id=id_plantel
        //window.$nombre_plantel=nombre_plantel
        sessionStorage.setItem("plantel_id", id_plantel)
        sessionStorage.setItem("nombre_plantel", nombre_plantel)
        if(usuario=== "Docente"){
            this.props.history.push("/app/AsignacionMateriasDocente")
        }
        else if(usuario === "Alumno"){
            this.props.history.push("/app/AsignacionMateriasAlumno")
        }
        
    }


    render(){
        return(
            <div className="bg-transparent text-white h-auto pb-20">
                <div className='relative  p-4 sm:p-6 rounded-sm overflow-hidden mb-8'>
                    <div className='relative text-center'>
                        <h1 className='text-2xl uppercase md:text-3xl text-white  font-bold mb-1 text-center'>
                           Carga académica
                        </h1>
                    </div>
                </div>

                <div className=" w-full h-24 z-10  grid grid-cols-1 ">
                    <div className="col-span- w-full flex items-center">
                        <nav class="flex relative" aria-label="Breadcrumb">
                            <ol class="flex items-center pl-10">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm dark:text-white group">
                                        <svg class="mr-2 w-4 h-4 text-white group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        <a className="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Home</a >
                                    </a>
                                </li>
                               
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Carga académico</a>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>

                {
                    this.state.planteles.map(
                        (plantel, index)=>
                        <div key={index} className="  rounded-2xl shadow-sm2 grid h-auto px-8 py-3  mt-2 text-white ">
                            <div className="grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-3  sm:grid-cols-3">
                                <div class='text-white text-3xl text-center my-5 font-bold uppercase mt-10 '>
                                    {plantel.Nombre} 
                                </div>
                                <div>
                                    <button className="bg-secondary rounded px-20" onClick={()=>this.CargaAcademico(plantel.ID_Plantel,plantel.Nombre, "Docente")} > 
                                        <img
                                        src={docente}
                                        class='w-8 h-8 lg:w-16 lg:h-16'
                                        alt=''>
                                        </img>
                                        <div class=' text-white text-xl text-center my-5 font-bold uppercase'>DOCENTE</div>
                                    </button>
                                </div>

                                    {
                                        plantel.Nombre !== "DIPLOMADOS" ?
                                        
                                        <div>
                                            <button className="bg-primary rounded px-20" onClick={()=>this.CargaAcademico(plantel.ID_Plantel,plantel.Nombre, "Alumno")} > 
                                                <img
                                                src={alumno}
                                                class='w-8 h-8 lg:w-16 lg:h-16'
                                                alt=''>
                                                </img>
                                                <div class=' text-white text-xl text-center my-5 font-bold uppercase'>Alumno</div>
                                            </button>   
                                        </div> : " "
                                    }
                            
                               <div>
                                   
                            </div>
                            </div>
                        </div>
                    )
                }
               
            </div>
           
        )
    }
}
export default withRouter(CargaAcademicoByPeriodos)