import axios from "axios"

import { APIURL } from "./Host"

class ProspectosAdminService {
	insertNuevoProspecto(prospecto) {
		return axios.post(APIURL + "/nuevo_prospecto_admin", prospecto)
	}

	Total_Prospectos() {
		return axios.get(APIURL + "/total_prospecto")
	}

	ListaProspectos(datos){
		return axios.post(APIURL+"/lista_prospectos_admin",datos);
	}

	ProspectoById(id){
		return axios.get(APIURL+"/datos_prospecto_admin/"+id);
	}

	updateProspecto(prospecto) {
		return axios.put(APIURL + "/prospectos_update_admin/", prospecto)
	}

	async EliminarProspecto(id){
		try { return await axios.get(APIURL + "/eliminar_prospecto_admin/" + id); } catch (error) { if (error.response) { return false; } else if (error.request) { return false; } else { return false; } }
	}
	
}
export default new ProspectosAdminService()