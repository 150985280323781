import React, { useState} from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { getAllowedRoutes, isLoggedIn } from '../utils';
import { PrivateRoutesConfig } from '../config';
import { TopNav } from '../components/common';
import MapAllowedRoutes from '../routes/MapAllowedRoutes';
import Header from "../partials/Header";

function PrivateRoutes() {
	const [sidebarOpen, setSidebarOpen] = useState(false);

	/**
	 * El useRouteMatch intenta hacer coincidir la URL actual de la misma 
	 * manera que el <Route> lo haria. Es sobre todo útil para obtener acceso a
	 * los datos de coincidencia sin tener que representar un <Route>.
	 */
	const match = useRouteMatch('/app');
	let allowedRoutes = [];

    /**
	 * Validar si usuario esta logueado y obteber tidas las rutas de acuerdo al tipo cargado en el local store 
	 * en caso de que no regresar ala ruta principal publica
	 */
	if (isLoggedIn()) allowedRoutes = getAllowedRoutes(PrivateRoutesConfig);
	else return <Redirect to="/login" />;
	return (	
		<div className="flex h-screen overflow-hidden relative">	
			<TopNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} routes={allowedRoutes}  prefix={match.path}  />
		  {/* Content area */}
				<Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />				
			<div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden pt-16">
				{/*  Site header */}					
				<MapAllowedRoutes routes={allowedRoutes} basePath="/app" isAddNotFound />

			</div>
		  </div>
	)
}

export default PrivateRoutes;
