/** @format */

import { Component } from "react"
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom"
import AlumnosService from "services/AlumnosService"
import DocentesService from "services/DocentesService"
import AdministrativoService from "services/AdministrativoService"
import IndicadoresService from "services/IndicadoresService";
import GeneralService from "services/GeneralService";
import luz_verde from "images/luzverde.png"
import luz_roja from "images/luzroja.png"
import luzama from "images/luzama.png"

class IndicadoresPrincipal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			usuarios: [],
			rol: "",
			totales: [
				{ totalAlumno: '' }
			],
			totalAlumnos: "",
			totalDocentes: "",
			total_Administrativos: "",
			semaforos:[],
			semaforo_verde:[],
			semaforo_amarillo:[],
			semaforo_rojo:[],
			estadisticas:[],
			monto_amarillo:"",
			total_amarillo:"",
			monto_rojo:"",
			total_rojo:"",
			total_verde:"",
			monto_verde:"",
			periodo:""
		}

		this.goBack = this.goBack.bind(this);
	}

	goBack() {
        this.props.history.goBack();
    }

	componentDidMount() {
		
		IndicadoresService.TotalSemaforoRojo().then(res => {
			console.log("Datas",res.data);
			this.setState({semaforo_amarillo:res.data.AMARILLO,semaforo_rojo: res.data.ROJO,
				semaforo_verde: res.data.VERDE, periodo: res.data.PERIODO.mes,
				estadisticas: res.data.efi})
			
			
		}
		)
	}

	render() {

		return (
			<>
				<div className='bg-primary_2 py-10'>
					<div className="flex justify-end mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Indicadores</a>
									</div>
								</li>
								
							</ol>
						</nav>
					</div>
					<h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest'>
						Indicadores instituto mes {this.state.periodo}
					</h1>
					
						
			
					<div class=' px-2 md:px-10 pt-4 md:pt-3  overflow-y-auto'>
						<div class='grid grid-cols-3 gap-10'>
							{
								(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'|| sessionStorage.getItem("roles")==='["ADMINISTRATIVO"]'||
								sessionStorage.getItem("roles")==='["ADMINISTRADOR"]'||sessionStorage.getItem("roles")==='["CONTROL ESCOLAR"]')&&
								<div className=' shadow-2xl rounded-md bg-secondary_2 relative py-6'>
									<div className="absolute w-full top-3 h-10 flex justify-end items-center">
										<div className="w-5 h-5 bg-yellow rounded-full mr-7 border"></div>
										</div>
										<div class='flex justify-center'>
										<img
										src={luzama}
										class='w-10 h-10 sm:w-16 sm:h-16'
										alt=''></img>
										</div>
										<div class='flex justify-center py-2'>
											<h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
											Total Alumnos:{this.state.semaforo_amarillo.total}
											</h1>
										
										</div>
										<div class='flex justify-center'>
											<h4 class='text-1xl sm:text-4xl text-secondary font-black counter'>
											Monto Estimado: {GeneralService.currencyFormater(this.state.semaforo_amarillo.monto)}
											</h4>
										</div>
										<div class='flex justify-center'>
											<h4 class='text-1xl sm:text-4xl text-secondary font-black counter'>
											Descuentos: {GeneralService.currencyFormater(this.state.semaforo_amarillo.descuentos)}
											</h4>
										</div>
										<div class='flex justify-center'>
											<h4 class='text-1xl sm:text-4xl text-secondary font-black counter'>
											Total: {GeneralService.currencyFormater(this.state.semaforo_amarillo.total_mes)}
											</h4>
										</div>
									{/* <div class='flex justify-center'>
									<h1 class='text-activo text-xl sm:text-xl'>ACTIVOS</h1>
									</div> */}
									<div class='flex justify-center pt-3'>
										<NavLink
											to='/app/IndicadorDetalle'
											className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
											<p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
											Detalles
											</p>
										</NavLink>
									</div>
								</div>
								
							}
						{
							(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'|| sessionStorage.getItem("roles")==='["ADMINISTRATIVO"]'||
							sessionStorage.getItem("roles")==='["ADMINISTRADOR"]'||sessionStorage.getItem("roles")==='["CONTROL ESCOLAR"]'||
							sessionStorage.getItem("roles")==='["DIRECTOR"]')&&
							<div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
								<div className="absolute w-full top-3 h-10 flex justify-end items-center">
									<div className="w-5 h-5 bg-red rounded-full mr-7 border"></div>
								</div>
								<div class='flex justify-center'>
									<img
										src={luz_roja}
										class='w-8 h-8 lg:w-16 lg:h-16'
										alt=''></img>
								</div>
								<div class='flex justify-center py-2'>
											<h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
											Total Alumnos:{this.state.semaforo_rojo.total}
											</h1>
										
										</div>
										<div class='flex justify-center'>
											<h4 class='text-1xl sm:text-4xl text-secondary font-black counter'>
											Estimado: {GeneralService.currencyFormater(this.state.semaforo_rojo.monto)}
											</h4>
										</div>
										<div class='flex justify-center'>
											<h4 class='text-1xl sm:text-4xl text-secondary font-black counter'>
											Descuentos: {GeneralService.currencyFormater(this.state.semaforo_rojo.descuentos)}
											</h4>
										</div>
										<div class='flex justify-center'>
											<h4 class='text-1xl sm:text-4xl text-secondary font-black counter'>
											Total: {GeneralService.currencyFormater(this.state.semaforo_rojo.total_mes)}
											</h4>
										</div>
								<div class='flex justify-center'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow tracking-widest text-white'>ROJO</h1>
									<br />
								</div>
								{/* <div class='flex justify-center'>
									<h1 class='text-green-500 text-xl sm:text-xl'>ACTIVOS</h1>
								</div> */}
								<div className=' flex justify-center  pt-3'>
									<NavLink
										to='/app/IndicadorDetalle'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
											Detalles
										</p>
									</NavLink>
								</div>
					</div>
						}	
					

							
								{
									(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'|| sessionStorage.getItem("roles")==='["ADMINISTRATIVO"]'||
									sessionStorage.getItem("roles")==='["ADMINISTRADOR"]'||sessionStorage.getItem("roles")==='["CONTROL ESCOLAR"]'||
									sessionStorage.getItem("roles")==='["DIRECTOR"]')&&
									<div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
								<div className="absolute w-full top-3 h-10 flex justify-end items-center">
									<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
								</div>
								<div class='flex justify-center'>
									<img
										src={luz_verde}
										class='w-8 h-8 lg:w-16 lg:h-16'
										alt=''></img>
								</div>
								<div class='flex justify-center py-2'>
											<h1 class='text-1xl sm:text-4xl text-secondary font-black counter'>
											Total Alumnos:{this.state.semaforo_verde.total}
											</h1>
										
										</div>
										<div class='flex justify-center'>
											<h4 class='text-1xl sm:text-4xl text-secondary font-black counter'>
											Estimado: {GeneralService.currencyFormater(this.state.semaforo_verde.monto)}
											</h4>
										</div>
										<div class='flex justify-center'>
											<h4 class='text-1xl sm:text-4xl text-secondary font-black counter'>
											Descuento: {GeneralService.currencyFormater(this.state.semaforo_verde.descuentos)}
											</h4>
										</div>
										<div class='flex justify-center'>
											<h4 class='text-1xl sm:text-4xl text-secondary font-black counter'>
											Total: {GeneralService.currencyFormater(this.state.semaforo_verde.total_mes)}
											</h4>
										</div>
								<div class='flex justify-center'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow text-white tracking-widest'>
										VERDE
									</h1>
								</div>
								{/* <div class='flex justify-center'>
									<h1 class='text-green-500 text-xl sm:text-xl'>ACTIVOS</h1>
								</div> */}
								
								<div className='flex justify-center pt-3'>
									<NavLink
										to='/app/IndicadorDetalle'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center'>
											Detalles
										</p>
									</NavLink>
								</div>
							</div>
							}
							
							
								
							
								
							

						</div>
					</div>
				</div>

			</>
		)
	}
}

export default withRouter(IndicadoresPrincipal);