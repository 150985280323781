import { Component } from "react";
import TemasService from "services/TemasService";
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert2'
import open from "../images/open.png"
import { withRouter } from "react-router-dom";
import { APIURL } from "services/Host";


class ListaTemasAlumnos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id_asignatura: sessionStorage.getItem("id_asignatura"),
            nombre_asignatura: sessionStorage.getItem("nombre_asignatura"),
            id_tema: '',
            nombre_tema: '',
            descripcion: '',
            temas: [],
            modaldetalle: false,
            modalactualizar: false
        }
    }

    componentDidMount() {
        TemasService.TemasActivosbyAsignatura(this.state.id_asignatura).then((res) => {
            if (res.data.length > 0) {
                this.setState({ temas: res.data, temasTemp: res.data });
            }
            else {
                swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No existen temas ',
                });
                this.setState({ temas: [], temasTemp: [] });
            }
        })
    }

    goBackAsignaturas = () => {
        this.props.history.push("/app/AcademicoAlumnosAsignaturas");
    }

    irSubtemas = async (id_tema, nombre_tema) => {
        sessionStorage.setItem("id_tema", id_tema)
        sessionStorage.setItem("nombre_tema", nombre_tema)
        this.props.history.push("/app/ListaSubtemasAlumnos")
    }

    render() {
        return (
            <div className='bg-primary_2 py-10'>
                <div className="flex justify-start mx-10 mt-4">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1">
                            <li class="flex justify-end">
                                <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Home
                                </a>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBackAsignaturas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Asignaturas</a>
                                </div>
                            </li>
                            <li aria-current="page">
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Lista de temas</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="relative text-center">
                    <h5 className="text-2xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest">
                        {this.state.nombre_asignatura}
                    </h5>
                </div>
                <div class=' px-2 md:px-10 pt-4 md:pt-3  overflow-y-auto'>
                    <div class='grid grid-cols-3 gap-10'>
                        {
                            this.state.temas.map(
                                (tema, index) =>
                                    <div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
                                        {tema.Extension != "" ?
                                            <div class='flex justify-center'>
                                                <img
                                                    src={APIURL + '/tema/get_imagen/' + tema.ID_Tema}
                                                    class='rounded-full w-10 h-10 sm:w-16 sm:h-16'
                                                    alt=''></img>

                                            </div>
                                            :
                                            <div class='flex w-10 h-10 sm:w-16 sm:h-16'>
                                            </div>
                                        }
                                        <div class='flex justify-center py-2'>
                                            <h1 class='text-xl text-secondary font-black counter'>
                                                {tema.Nombre_Tema}
                                            </h1>
                                        </div>
                                        <div class='flex justify-center'>
                                            <h1 class='text-white text-xl font-barlow tracking-widest'>{tema.Descripcion}</h1>
                                        </div>
                                        <div class='flex justify-center pt-3'>
                                            <div onClick={() => this.irSubtemas(tema.ID_Tema, tema.Nombre_Tema)} className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
                                                <p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
                                                    Lista de subtemas
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ListaTemasAlumnos);