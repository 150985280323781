import { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { withRouter } from "react-router";
import ProspectosService from "services/ProspectosService";
import { Progress } from 'antd';
import swal from 'sweetalert2';
import 'antd/dist/antd.css';

class ProspectosInscritos extends Component {

	constructor(props) {
		super(props);
		this.state = {
			prospectos: [],
			show: [],
			dirnom: false,
			activ: "1",
			vacio: false,
			ocultar: "",
			modaldetalles: false,
			Nombre: "",
			Apellidop: "",
			Apellidom: "",
			Curp: "",
			Rfc: "",
			Nacionalidad: "",
			Sexo: "",
			Telefono: "",
			Fecha_Nacimiento: "",
			Correo: "",
			Anio_Egreso: "",
			Procedencia: "",
			Procedencia_Tipo: "",
			Promedio: "",
			EstadoN: "",
			EstadoM: "",
			EstadoL: "",
			Correo: "",
			Telefono: "",
			Telefono2: "",
			Opcion_E: "",
		}

		this.inputChange = this.inputChange.bind(this);
		this.carga = this.carga.bind(this);
		this.goBack = this.goBack.bind(this);
		this.ModalDetalles = this.ModalDetalles.bind(this);
		this.ModalDetallesCerrar = this.ModalDetallesCerrar.bind(this);
		this.filter_busqueda=this.filter_busqueda.bind(this);

	}

	goBack() {
		this.props.history.goBack();
	}

	inputChange = (event) => {
		let ob = {};
		ob[event.target.name] = event.target.value;
		this.setState(ob, function () {
			switch (event.target.name) {
				case "busqueda":
					if (event.target.value !== "") {
						this.filterType("text");
					} else {
						this.filterType();
					}
					break;

				case "dirnom":
					this.filterType("nom");
					break;
				default:
			}
		});
	};

	filter_busqueda=(event)=>{
		var textNombre = event.target.value   
        const data = this.state.prospectos;
        const newData = data.filter(function(item){
        const itemData = item.Nombre.toUpperCase()
        const textData = textNombre.toUpperCase()
        return itemData.indexOf(textData) > -1
        })
        if(textNombre === ""){
			this.componentDidMount();
        } else {
          this.setState({
            prospectos: newData,
            textNombre: textNombre,
          })
        }
	}

	carga(usuario_id) {
		ProspectosService.Prospectos_inscritos(usuario_id).then((response) => {
			if (response.data.length == 0) {
				this.setState({ vacio: true });
			}
			this.setState(
				{ prospectos: response.data, show: response.data }
			);

		});
	}

	componentDidMount() {
		let usuario_id = sessionStorage.getItem('id');
		this.carga(usuario_id);
	}


	renderAlert() {
		return (
			<span class="bg-blue-600 text-gray-100 text-sm font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-blue-200 dark:text-blue-800">
				<svg className="w-3.5 h-3.5" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
					<path fill="var(--ci-primary-color, currentColor)" d="M474.444,19.857a20.336,20.336,0,0,0-21.592-2.781L33.737,213.8v38.066l176.037,70.414L322.69,496h38.074l120.3-455.4A20.342,20.342,0,0,0,474.444,19.857ZM337.257,459.693,240.2,310.37,389.553,146.788l-23.631-21.576L215.4,290.069,70.257,232.012,443.7,56.72Z" class="ci-primary" />
				</svg>
				enviado
			</span>

		);
	}

	renderAlert2() {
		return (
			<span class="bg-blue-600 text-gray-100 text-sm font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-blue-200 dark:text-blue-800">
				<svg className="w-3.5 h-3.5" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
					<path fill="var(--ci-primary-color, currentColor)" d="M474.444,19.857a20.336,20.336,0,0,0-21.592-2.781L33.737,213.8v38.066l176.037,70.414L322.69,496h38.074l120.3-455.4A20.342,20.342,0,0,0,474.444,19.857ZM337.257,459.693,240.2,310.37,389.553,146.788l-23.631-21.576L215.4,290.069,70.257,232.012,443.7,56.72Z" class="ci-primary" />
				</svg>enviado
			</span>

		);
	}

	renderAlert3() {
		return (
			<span class="bg-green-600 text-gray-100 text-sm font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-blue-200 dark:text-blue-800">
				<svg class="h-3.5 w-3.5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
				</svg>
				en revisión
			</span>

		);
	}

	renderAlert4() {
		return (
			<span class="bg-green-800 text-gray-100 text-sm font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-blue-200 dark:text-blue-800">
				<svg class="w-3.5 h-3.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
				inscrito
			</span>
		);
	}

	//Función para filtrar según el tipo de usuario seleccionado
	filterType(op) {
		let order;
		switch (op) {

			case "text":
				order = this.state.prospectos.filter(
					itm => {
						let nom = itm.Nombre.trim().toLowerCase();
						return nom.includes(this.state.busqueda.trim().toLowerCase());
					}
				);
				break;

			case "nom":
				let arr = this.state.show;
				if (this.state.dirnom) {
					arr.sort((a, b) => {
						if (a.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") < b.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) { return -1; }
						if (a.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) { return 1; }
						return 0;
					});
				} else {
					arr.sort((a, b) => {
						if (b.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") < a.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) { return -1; }
						if (b.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.Nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) { return 1; }
						return 0;
					});
				}
				order = arr;
				break;

			default:
				order = this.state.prospectos;
				break;
		}

		this.setState({ show: order });

	}
	CalucularMes(mes) {
		switch (mes) {
			case 1:
				{
					mes = "enero";
					break;
				}
			case 2:
				{
					mes = "febrero";
					break;
				}
			case 3:
				{
					mes = "marzo";
					break;
				}
			case 4:
				{
					mes = "abril";
					break;
				}
			case 5:
				{
					mes = "mayo";
					break;
				}
			case 6:
				{
					mes = "junio";
					break;
				}
			case 7:
				{
					mes = "julio";
					break;
				}
			case 8:
				{
					mes = "agosto";
					break;
				}
			case 9:
				{
					mes = "septiembre";
					break;
				}
			case 10:
				{
					mes = "octubre";
					break;
				}
			case 11:
				{
					mes = "noviembre";
					break;
				}
			case 12:
				{
					mes = "diciembre";
					break;
				}
			default:
				{
					mes = "Error";
					break;
				}
		}
		return mes;
	}

	ModalDetalles(ID_Prospecto) {
		let prospecto;
		this.setState({ modaldetalles: !this.state.modaldetalles });
		ProspectosService.detalles_prospecto(ID_Prospecto).then((res) => {
			prospecto = res.data[0];

			this.setState({
				Nombre: prospecto.Nombre,
				Apellidop: prospecto.Apellidop,
				Apellidom: prospecto.Apellidom,
				Curp: prospecto.Curp,
				Rfc: prospecto.Rfc,
				Sexo: prospecto.Sexo,
				Fecha_Nacimiento: prospecto.Fecha_Nacimiento,
				Nacionalidad: prospecto.Nacionalidad,
				EstadoN: prospecto.EstadoN,
				EstadoM: prospecto.EstadoM,
				EstadoL: prospecto.EstadoL,
				Correo: prospecto.Correo,
				Telefono: prospecto.Telefono,
				Procedencia: prospecto.Procedencia,
				Procedencia_Tipo: prospecto.Procedencia_Tipo,
				Promedio: prospecto.Promedio,
				Anio_Egreso: prospecto.Anio_Egreso,
				Opcion_E: prospecto.Opcion_E
			});


		});
	}
	ModalDetallesCerrar() {
		this.setState({ modaldetalles: !this.state.modaldetalles })
	}


	render() {
		return (
			<>
				<div className="flex justify-end mx-10 mt-4">

					<nav class="flex" aria-label="Breadcrumb">
						<ol class="inline-flex items-center space-x-1">
							<li class="flex justify-end">
								<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
									<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
									Home
								</a>
							</li>

							<li aria-current="page">
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Inscripciones</span>
								</div>
							</li>
						</ol>
					</nav>
				</div>

				<div className="container mx-auto h-16 text-center uppercase tracking-widest">
					<div className=" text-center">
						<h1 className="text-2xl md:text-3xl text-white font-bold mb-1 text-center">
							Inscripciones
						</h1>
					</div>
				</div>

				<div className="mx-10 flex-row-reverse sm:flex items-center lg:flex grid grid-cols-1  justify-between">
					<div className="cont">
						<label>Buscar <i class="fa fa-search" aria-hidden="true"></i></label>
						<input onChange={(text) => this.filter_busqueda(text)} type="search" name="caja_busqueda" id="caja_busqueda" placeholder="Ingrese la busqueda" title="Presione Esc para supender la búsqueda" />
					</div>
				</div>

				<div className="mx-4 my-4 overflow-x-auto">
					<table className="w-full whitespace-nowrap my_table">
						<thead className="h-10">
							<tr>
								<th className="border border-slate-300">
									<div class="flex items-center justify-center">
										Nombre
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className={`icon cursor-pointer icon-tabler icon-tabler-chevron-down text-gray-800"}`}
											width={20}
											height={20}
											viewBox="0 0 24 24"
											strokeWidth="1.8"
											stroke="currentColor"
											fill="none"
											strokeLinecap="round"
											strokeLinejoin="round"
											onClick={() => {
												this.inputChange(
													{ target: { name: "dirnom", value: !this.state.dirnom } });
											}
											}
										>
											<path stroke="none" d="M0 0h24v24H0z" />
											{this.state.dirnom ?
												<polyline points="6 15 12 8 18 15" />
												: <polyline points="6 9 12 15 18 9" />}

										</svg>
									</div>
								</th>
								<th className="border border-slate-300">Télefono</th>
								<th className="border border-slate-300">Correo</th>
								<th className="border border-slate-300">Fecha de inscripción</th>
								<th className="border border-slate-300">Carrera</th>
								<th className="border border-slate-300">Proceso</th>
								<th className="border border-slate-300">Acciones</th>
							</tr>
						</thead>
						<tbody className="w-full">
							{
								this.state.prospectos.map(
									prospectos =>
									<tr className="h-20 text-sm leading-none text-gray-800 group border border-slate-300">

											<td className="pl-2 text-left">
												<p className="font-medium">{prospectos.Nombre + " "}{prospectos.Apellidop + " "}{prospectos.Apellidom}</p>
											</td>

											<td className="pl-2 text-left ">
												<p className="font-medium">{prospectos.Telefono}</p>
											</td>

											<td className="pl-2 text-left ">
												<p className="font-medium">{prospectos.Correo}</p>
											</td>


											<td className="pl-4 cursor-pointer">
												<p className="font-medium">{!prospectos.Fecha_Inscripcion ?
													<p className="font-medium">
														<span class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">pendiente</span></p>
													: <p className="font-medium">{(prospectos.Fecha_Inscripcion.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(prospectos.Fecha_Inscripcion.split("-")[1])) + "-" + prospectos.Fecha_Inscripcion.split("-")[0]}</p>
												}</p>
											</td>
											<td className="pl-2 text-left ">
												<p className="font-medium">{prospectos.Carrera}</p>
											</td>

											<td className="pl-2 text-center">
												<p className="font-medium">{prospectos.Status == 2 ? (
													this.renderAlert2()
												)
													:
													prospectos.Status == 3 ?
														this.renderAlert3()
														:
														prospectos.Status == 4 ?
															this.renderAlert4()
															:
															<p className="font-medium">{this.renderAlert()}</p>

												}</p>
											</td>

											<td class="pl-2 text-left">

												<div class="flex justify-center items-center">
													<div className="flex items-center">
														<div class="mr-2 transform hover:text-purple-500 hover:scale-110">
															<button hidden={this.state.ocultar} onClick={() => this.ModalDetalles(prospectos.ID_Prospecto)} title="Detalles alumno">
																<svg class="h-7 w-7 text-green-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
															</button>
														</div>

													</div>
												</div>


											</td>



										</tr>
								)
							}


						</tbody>
					</table>
					{this.state.vacio ? (
						<>

							<div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-4 grid justify-items-center rounded" role="alert">
								<strong class="font-bold">Aún no hay inscritos!</strong>

							</div>

						</>
					) : null}

					{this.state.modaldetalles ? (
						<>
							<div div class="overflow-y-auto h-32">
								<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
									<div className='relative w-auto my-6 mx-auto max-w-3xl'>
										{/*content*/}
										<div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2'>
											{/*header*/}
											<div className=' rounded-t-3xl'>
												<p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Información del prospecto</p>
											</div>
											{/*body*/}
											<div className='relative flex-auto'>
												<div class='grid grid-cols-1 gap-5 px-6 py-5 '>
													<div className="shadow-sm2 px-5 py-2 rounded-3xl">
														<p class='mb-5 font-bold text-primary uppercase text-center text-lg'>
															Datos generales
														</p>
														<div class='grid grid-cols-2 gap-4'>
															<div>
																<p class='mb-2 font-bold text-primary '>
																	Nombre del usuario:{" "}
																</p>
																<p class='mb-2 font-semibold text-primary'>
																	{this.state.Nombre +
																		" " +
																		this.state.Apellidop +
																		" " +
																		this.state.Apellidom}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>Curp:</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Curp}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary '>Rfc:</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Rfc}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>Sexo:</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Sexo}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Fecha de nacimiento:
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Fecha_Nacimiento}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Nacionalidad:
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Nacionalidad}
																</p>
															</div>
														</div>
													</div>
													<div className="shadow-sm2 px-5 py-2 rounded-3xl">
														<p class='mb-5 font-bold text-primary uppercase text-center text-lg'>
															Lugar de nacimiento
														</p>

														<div class='grid grid-cols-3 gap-4'>
															<div>
																<p class='mb-2 font-bold text-primary '>
																	Estado de nacimiento{" "}
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.EstadoN}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Municipio de nacimiento{" "}
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.EstadoM}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Localidad de nacimiento{" "}
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.EstadoL}
																</p>
															</div>
														</div>
													</div>
													<div className="shadow-sm2 px-5 py-2 rounded-3xl">
														<p class='mb-5 font-bold text-primary uppercase text-center text-lg'>
															Datos de contacto
														</p>

														<div class='grid grid-cols-3 gap-4'>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Correo electrónico
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Correo}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Número de teléfono 1
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Telefono}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Número de teléfono 2
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Telefono2}
																</p>
															</div>
														</div>
													</div>
													<div className="shadow-sm2 px-5 py-2 rounded-3xl">
														<p class='mb-5 font-bold text-primary uppercase text-center text-lg'>
															Datos escolares
														</p>
														<div class='grid grid-cols-3 gap-4'>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Escuela de procedencia
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Procedencia}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary  '>
																	Sistema escolar
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Procedencia_Tipo}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary '>
																	Promedio
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Promedio}
																</p>
															</div>

														</div>
														<div class='grid grid-cols-2 gap-4'>
															<div>
																<p class='mb-2 font-bold text-primary'>
																	Año egreso
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Anio_Egreso}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-primary '>
																	Opción de estudio
																</p>
																<p class='mb-2 font-semibold text-primary '>
																	{this.state.Opcion_E}
																</p>
															</div>

														</div>
													</div>
												</div>
											</div>
											{/*footer*/}
											<div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
												<button
													className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
													type='button'
													onClick={() => this.ModalDetallesCerrar(false)}>
													Cerrar
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
							</div>
						</>
					) : null}
				</div>

			</>
		);
	}

}

export default withRouter(ProspectosInscritos);