import React, { Component } from "react"

import image from "../images/logotipo_color.svg"
import logo_white from '../images/logotipo_white.svg'
import portada from '../images/slider-back.webp'
import login from '../images/login_alumno.png'
import ReactPlayer from 'react-player/lazy'
import axios from "axios"
import { APIURL } from "../services/Host"
import { NavLink } from "react-router-dom"



var CryptoJS = require("crypto-js");
const APIURLo = APIURL + "/login"


//const cookies = new Cookies();
export default class Login extends Component {
	constructor(props) {
		super(props)

		/**
		 * vinculacion del controlador a este componente
		 */
		this.changeUsuarioNameHandler = this.changeUsuarioNameHandler.bind(this)
		this.changeContrasenaHandler = this.changeContrasenaHandler.bind(this)
	}

	state = {
		//carga de propiedades con informacion
		//id: this.props.match.params.id,
		username: "",
		password: "",
		error: false,
		errorMsg: "",
	}

	saveUser = async e => {
		e.preventDefault()
		let usuario = {
			username: this.state.username,
			password: this.state.password,
		}
		await axios.post(APIURLo, usuario).then(response => {
			var count = Object.keys(response.data).length
			if (count > 0) {
				let respuesta = response.data
				let usuario  = CryptoJS.AES.encrypt(JSON.stringify(respuesta.username), 'my-secret-key@123').toString();
				let nombre =  CryptoJS.AES.encrypt(JSON.stringify(respuesta.nombre), 'my-secret-key@123').toString();
				let appPaterno =  CryptoJS.AES.encrypt(JSON.stringify(respuesta.app_paterno), 'my-secret-key@123').toString();
				let appMaterno =  CryptoJS.AES.encrypt(JSON.stringify(respuesta.app_materno), 'my-secret-key@123').toString();
				let fotoImg =  CryptoJS.AES.encrypt(respuesta.fotoImg, 'my-secret-key@123').toString();
				
				sessionStorage.setItem("roles","[" + JSON.stringify(respuesta.descripcion) + "]")
				sessionStorage.setItem("token", usuario);
				sessionStorage.setItem("id", respuesta.usuario_id);
				sessionStorage.setItem("nombre", nombre);
				sessionStorage.setItem("materno",appMaterno);
				sessionStorage.setItem("paterno",appPaterno);
				sessionStorage.setItem("fotoImg",fotoImg);
				window.location.reload();
			} else {
				this.setState({
					error: true,
					errorMsg: "Compruebe que el usuario y contraseña sean correctos",
				})
			}
			return response.data
		})
	}

	//* Escuchador de eventos de cambio de los label*//
	changeUsuarioNameHandler = event => {
		this.setState({ username: event.target.value })
	}

	changeContrasenaHandler = event => {
		this.setState({ password: event.target.value })
	}

	render() {
		return (
			<div className='h-screen w-screen bg-transparent xl:flex-col lg:flex-col lg:justify columns-1 xl:columns-2'>
				<div className="xl:w-100 xl:h-40
								lg:w-100 lg:h-20    
								sm:center-items sm:flex-wrap  sm:w-100 sm:10 sm:w-100 sm:h-10
								justify-center center-items w-100 h-20">
				
				</div>
				<div className="lg:w-full lg:h-1/2  lg:flex sm:center-items 
							    xl:flex 
								sm:flex-wrap sm:h-full sm:px-1
								justify-center center-items "> {/*div central*/}
						<div className="w-full bg-transparent py-5 px-5
									lg:w-1/2 lg:h-full  lg:pb-10 lg:px-10
									xl:w-1/2 xl:h-full  xl:px-3
									md:px-20
									sm:px-5 sm:w-full sm:h-1/2">
								<ReactPlayer className='react-player'  url='https://plataforma.instituto-euroamericano.edu.mx:9443/videos/Video_Universidad_Euro.mp4' 
											playing='true'
                                            controls='true'
                                            volume='1'  
                                            loop='false' 
                                            width='100%' 
                                            height='100%'
                                            config={{ 
                                                file: { 
                                                  attributes: {
                                                        controlsList: 'nodownload'
                                                    } 
                                              } 
                                            }}/>
						</div>
						<div className="w-1/2  
									lg:w-1/2 lg:h-1/2 lg:px-10
									xl:px-20  xl:w-1/2 xl:h-full  xl:py-10
									md:px-20  md:h-1/2
									sm:px-30 sm:h-1/2 sm:pb-10
									w-full h-1/2 font-rachel px-5 pt-5">
				
								<div className=' bg-secondary_2 white rounded-lg pt-10 
												 lg:pt-5 px-1 justify-center items-center '>
										<div className="w-full h-full bg-secondary_2 xl:bg-white rounded px-5"></div>
										<div className=" relative">
							
											<div className='text-primary'>
											<div className="text-white text-4xl text-center font-rachel-bold tracking-widest">Bienvenido al Aula virtual</div>
											<div className="text-white text-2xl text-center  font-rachel tracking-widest">de la Universidad EuroAmericano</div>
												<form>
													<label className='text-white font-barlow tracking-widest text-xl'>Usuario:</label>
													<input
														name='username'
														type='text'
														value={this.state.nombre}
														onChange={this.changeUsuarioNameHandler}
														placeholder='Correo'
														className='w-full p-2 mt-3 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 font-barlow tracking-widest font-medium'
													/>
													<label className="text-white mb-2 font-barlow tracking-widest text-xl">Contraseña:</label>
												<div class='flex flex-wrap items-stretch w-full mt-3 relative'>
													<input
														name='password'
														id='password'
														type='password'
														value={this.state.nombre}
														onChange={this.changeContrasenaHandler}
														class='flex-shrink flex-grow leading-normal w-px flex-1 h-10 border-grey-light rounded-md rounded-r-none px-3 font-barlow tracking-widest font-medium'
														placeholder='Contraseña'
													/>
													<div class='flex'>
														<span
																	class='flex items-center leading-normal bg-color1 border-1 rounded-l-none border border-l-0 px-3 whitespace-no-wrap text-grey-dark w-12 h-10 hover:translate-y-2 justify-center text-xl rounded-lg text-white transition duration-300 ease-out hover:bg-primary'
																	onMouseDown={() => {
																		document.getElementById("password").type = "text"
																	}}
																	onMouseUp={() => {
																		document.getElementById("password").type = "password"
																	}}
																	onTouchStart={() => {
																		document.getElementById("password").type = "text"
																	}}
																	onTouchEnd={() => {
																		document.getElementById("password").type = "password"
																	}}>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		class='h-6 w-6 cursor-pointer'
																		fill='none'
																		viewBox='0 0 24 24'
																		stroke='currentColor'>
																		<path
																			stroke-linecap='round'
																			stroke-linejoin='round'
																			stroke-width='2'
																			d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
																		/>
																		<path
																			stroke-linecap='round'
																			stroke-linejoin='round'
																			stroke-width='2'
																			d='M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z'
																		/>
																	</svg>
																</span>
													</div>
												</div>
									<div class='flex items-center justify-end flex-wrap items-stretch w-full mt-3 relative text-white'>
										<NavLink to={{ pathname: '/RecuperarCuenta', }} rel="noopener noreferrer"
											className=''>
											Recuperar cuenta
										</NavLink>
									</div>

									<div className='flex items-center pt-10 justify-center'>
										<button
											className='bg-primary py-2 px-5 text-md text-white rounded focus:outline-none focus:border-black transition duration-300 ease-in-out transform hover:translate-y-2 hover:bg-primary font-barlow tracking-widest uppercase font-bold text-base'
											value='Login'
											onClick={this.saveUser}>
											Ingresar
										</button>
									</div>
								</form>
								
								<div className='flex items-center mt-3 justify-center'>
									{this.state.error === true && (
										<div className='flex bg-transparent-200 p-4'>
											<div className='flex justify-between w-full'>
												<div className='text-red-600'>
													<p className='mb-2 font-bold text-center'>
														Error al intentar iniciar sesión
													</p>
													<p className='text-xs text-center'>
														{this.state.errorMsg}
													</p>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					</div>
				</div>
				<div className="xl:py-10 xl:w-full xl:h-1/4
								 lg:py-10 lg:w-full lg:h-1/4
								 md:py-10 md:w-full md:h-1/4
								 sm:py-10 sm:w-full sm:h-1/4
								w-full h-1/4 py-10">

								<div className="text-white text-1xl text-center font-rachel-bold tracking-widest">El Aula Virtual es una plataforma para estudiantes
									de la Universidad EuroAmericano en Línea
								</div>
								<div className="text-white text-1xl text-center  font-rachel tracking-widest">Si aún no eres alumno, puedes conocer las carreras en Línea
									en nuestro sitio web.
								</div>
								<div className="text-white text-1xl text-bold text-center  font-rachel tracking-widest">
									Términos y Condiciones
								</div>
				</div>			

			</div>

		)
	}
}
