import { Component } from "react";
import AsignaturasService from "services/AsignaturasService";
import AreaAsignaturaService from "services/AreaAsignaturaService";
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert2'
import open from "../images/open.png"
import { withRouter } from "react-router-dom";


class ListaAsignaturasCarga extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id_especialidad: sessionStorage.getItem("id_especialidad"),
            nombre_especialidad: sessionStorage.getItem("nombre_especialidad"),
            id_plantel: sessionStorage.getItem("plantel_id"),
            nombre_plantel: sessionStorage.getItem("nombre_plantel"),
            id_checkbox: this.props.newParams ? this.props.newParams.id_checkbox : "",
            id_checkboxTemp: '',
            clave_asignatura: '',
            creditos_asignatura: '',
            especialidad_id: '',
            seriacion: '',
            area_id: '',
            horas_academico: '',
            horas_ind: '',
            id_asignatura: '',
            nombre_area: '',
            nombre: '',
            costo: '',
            intro: '',
            imagen: '',
            descripcion: '',

            Especialidades: [],
            Asignaturas: [],
            AsignaturasTemp: [],
            Areas: [],

            modaldetalle: false,
            modalactualizar: false,

            txtCalificacionActual: 'hidden',
            txtCalificacionTodos: '',
        }
        this.ListaAsignaturas = this.ListaAsignaturas.bind(this);
        this.change_Especialidad = this.change_Especialidad.bind(this);
        this.state.ModalDetalles = this.ModalDetalles.bind(this);
        this.ModalActualizar = this.ModalActualizar.bind(this);
        this.CheckedTodos = this.CheckedTodos.bind(this);
        this.CheckedActivos = this.CheckedActivos.bind(this);
        this.MostrarAsignaturasActivos = this.MostrarAsignaturasActivos.bind(this);

        this.filter_nombreAsignatura = this.filter_nombreAsignatura.bind(this);
    }

    change_Especialidad = (event) => {
        this.ListaAsignaturas();
    }

    componentDidMount() {
        console.log("nombre del plantel " + this.state.nombre_plantel)
        if (this.state.id_checkbox === '') {
            if (this.state.nombre_plantel === "UNIVERSIDAD") {
                this.ListaAsignaturas();
                console.log("Dentro del if en lista asignaturas" + this.nombré_plantel);
            }
            else {
                //this.ListaAsignaturasByPlantel();
                this.ListaAsignaturas();
            }

            document.getElementById('CheckTodos').checked = true;
            document.getElementById('CheckActivar').checked = false;
            this.setState({ txtCalificacionActual: 'hidden' });
        }
        else {
            this.MostrarAsignaturasActivos();
        }
    }

    AsignaturaById(id_asignatura) {
        let asig;
        AsignaturasService.AsignaturaById(id_asignatura).then((res) => {
            //console.log("datos asignatura ",res.data);
            asig = res.data;
            //console.log("asig ",asig);

            this.setState({
                area_id: asig.area_id,
                clave_asignatura: asig.clave_asignatura,
                creditos_asignatura: asig.creditos_asignatura,
                especialidad_id: asig.especialidad_id,
                horas_academico: asig.horas_academico,
                horas_ind: asig.horas_ind,
                seriacion: asig.seriacion,
                id_asignatura: asig.id_asignatura,
                nombre_asignatura: asig.nombre_asignatura,
                nombre_area: asig.nombre_area,
                costo: asig.costo,
                intro: asig.intro,
                imagen: asig.imagen,
                descripcion: asig.descripcion,
            })
        })
    }
    /**
     * 0 = Mostrar todas las asignaturas
     * 1 = Mostrar las asignaturas activas
     */

    ListaAsignaturasByPlantel = async () => {
        AsignaturasService.AsignaturabyPlantel(this.state.id_plantel).then((response) => {
            console.log("response plantel ", response.data)
            if (response.data.length > 0) {
                this.setState({ Asignaturas: response.data, AsignaturasTemp: response.data });
            }
            else {
                swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No existen asignaturas ',
                });
                this.setState({ Asignaturas: [], AsignaturasTemp: [] });
            }
        })
    }

    async ListaAsignaturas() {
        AsignaturasService.AsignaturabyEspecialidad(this.state.id_especialidad).then((res) => {
            //console.log("total "+res.data.length);
            if (res.data.length > 0) {
                this.setState({ Asignaturas: res.data, AsignaturasTemp: res.data });
            }
            else {
                swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No existen asignaturas ',
                });
                this.setState({ Asignaturas: [], AsignaturasTemp: [] });
            }
        })
    }

    ModalDetalles(id_asignatura) {
        this.AsignaturaById(id_asignatura);
        this.setState({ modaldetalle: !this.state.modaldetalle });
    }

    ModalActualizar(id_asignatura) {
        AreaAsignaturaService.ListaAreas().then((res) => {
            this.setState({ Areas: res.data })
            //console.log("areas ",this.state.Areas);
        })
        this.AsignaturaById(id_asignatura);
        this.setState({ modalactualizar: !this.state.modalactualizar });
    }

    ActualizarDatos = (event) => {
        event.preventDefault();
        let id_area = document.getElementById("id_area").value;
        let asignatura = {
            id_asignatura: this.state.id_asignatura,
            clave_asignatura: this.state.clave_asignatura,
            creditos_asignatura: this.state.creditos_asignatura,
            especialidad_id: this.state.id_especialidad,
            horas_academico: this.state.horas_academico,
            horas_ind: this.state.horas_ind,
            nombre_asignatura: this.state.nombre_asignatura,
            seriacion: this.state.seriacion,
            area_id: id_area,
            costo: this.state.costo,
            intro: this.state.intro,
            imagen: this.state.imagen,
            descripcion: this.state.descripcion,
        };

        AsignaturasService.AsignaturaUpdate(asignatura).then((res) => {
            // console.log("se ha agregado correctamente "+res.data);
            if (res.data === 1) {
                this.setState({ modalactualizar: !this.state.modalactualizar });
                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Actualizado',
                });
                this.componentDidMount();
            }
            else {
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al actualizar los datos',
                });
            }
        })
    }

    CheckedTodos() {
        if (document.getElementById("CheckTodos").checked === true) {
            //console.log("entra if CheckTodos");
            this.setState({ txtCalificacionActual: 'hidden', txtCalificacionTodos: '', id_checkbox: '' });
            this.componentDidMount();
        }
        else if (document.getElementById("CheckTodos").checked === false) {
            //console.log("entra en false CheckTodos");
            document.getElementById('CheckTodos').checked = true;
        }
    }

    CheckedActivos() {
        if (document.getElementById("CheckActivar").checked === true) {
            //console.log("entra if CheckActivar");
            this.setState({ txtCalificacionActual: '', txtCalificacionTodos: 'hidden' });
            this.MostrarAsignaturasActivos();
        }
        else if (document.getElementById("CheckActivar").checked === false) {
            //console.log("entra else if CheckActivar");
            document.getElementById('CheckActivar').checked = true;
        }
    }

    MostrarAsignaturasActivos() {
        document.getElementById('CheckActivar').checked = true;
        document.getElementById('CheckTodos').checked = false;
        AsignaturasService.AsignaturaActivos(this.state.id_especialidad).then((res) => {
            if (res.data == "") {
                this.setState({ Asignaturas: [] });
                swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    text: 'En la especialidad no hay asignaturas activas',
                });
            }
            else {
                this.setState({ Asignaturas: res.data, AsignaturasTemp: res.data, txtCalificacionActual: '', txtCalificacionTodos: 'hidden' });

            }

        })
    }

    filter_nombreAsignatura = (event) => {
        var textAsignatura = event.target.value
        const data = this.state.Asignaturas;
        const newData = data.filter(function (item) {
            const itemData = item.nombre_asignatura.toUpperCase()
            const textData = textAsignatura.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        if (textAsignatura === "") {
            if (document.getElementById("CheckActivar").checked === true) {
                this.MostrarAsignaturasActivos();
            } else {
                this.setState({ Asignaturas: this.state.AsignaturasTemp })
            }

        } else {
            this.setState({
                Asignaturas: newData,
                textAsignatura: textAsignatura,
            })
        }
    }

    goBack = () => {
        this.props.history.push("/app/ListaEspecialidadesCarga");
    }

    irTemas = async (id_asignatura, nombre_asignatura) => {
        sessionStorage.setItem("id_asignatura", id_asignatura)
        sessionStorage.setItem("nombre_especialidad", nombre_asignatura)
        this.props.history.push("/app/ListaTemas")
    }

    actualizarDatos = (id_especialidad) => {
        this.setState({ modaldetalle: !this.state.modaldetalle });
        this.ModalActualizar(id_especialidad);
    }


    render() {
        return (
            <div className="bg-primary_2 h-auto pb-20">
                <div className="flex justify-start mx-10 mt-4">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1">
                            <li class="flex justify-end">
                                <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Home
                                </a>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de especialidades y licenciaturas</a>
                                </div>
                            </li>
                            <li aria-current="page">
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Lista de asignaturas</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="mr-10  sm:flex lg:flex grid grid-cols-1 justify-between ">
                    <div className="flex justify-start mx-20">
                        <div class="xl:w-96 mt-8">

                        </div>
                    </div>
                </div>
                <div className="relative text-center">
                    <h5 className="text-2xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest">
                        {this.state.nombre_especialidad}
                    </h5>
                </div>
                <div className="mx-8 my-8 mr-8 overflow-x-auto ">
                    <div className=" px-2 md:px-10 md:pt-7 pb-5 overflow-y-auto">
                        <table className="bg-color4 w-full border-collapse border border-slate-400 table-auto my_table" id="Tabla_alumnos">
                            <thead className="h-10">
                                <tr>
                                    <th className="font-semibold text-center text-lg ">Clave de asignatura<br />
                                    </th>
                                    <th className="font-semibold text-center text-lg"><br />Asignatura
                                        <div class="flex flex-items-center ml-10">
                                            <label class="flex items-center">
                                                <input onClick={this.CheckedTodos} type="checkbox" id="CheckTodos" name="grupo" class="form-checkbox" />
                                                <span class="ml-2 text-black text-sm ">Todos</span>
                                            </label>
                                            <label class="ml-10 flex items-center">
                                                <input onClick={this.CheckedActivos} type="checkbox" id="CheckActivar" name="grupo" class="form-checkbox" />
                                                <span class="ml-2 text-black text-sm">Activos</span>
                                            </label>
                                        </div>
                                    </th>
                                    <th hidden={this.state.txtCalificacionActual} className="font-semibold text-center text-lg ">Docente<br />
                                    </th>

                                    <th className="font-semibold text-center text-lg">Área<br />
                                    </th>

                                    <th className="font-semibold text-center text-lg">Temas</th>

                                </tr>
                            </thead>
                            {
                                this.state.Asignaturas.map(
                                    (asignatura, index) =>
                                        <tbody key={index} >
                                            <tr>
                                                <td >
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{asignatura.Clave_asignatura}</h1>
                                                </td>
                                                <td >
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{asignatura.nombre_asignatura}</h1>
                                                </td>
                                                <td hidden={this.state.txtCalificacionActual} >
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{asignatura.nombre + " " + asignatura.app_paterno + " " + asignatura.app_materno}</h1>
                                                </td>

                                                <td >
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{asignatura.nombre_area}</h1>
                                                </td>

                                                <td >
                                                    <button onClick={() => this.irTemas(asignatura.id_asignatura, asignatura.nombre_asignatura)}>
                                                        <svg class="h-8 w-8 text-green-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />  <line x1="10" y1="14" x2="20" y2="4" />  <polyline points="15 4 20 4 20 9" /></svg>
                                                    </button>
                                                </td>

                                            </tr>
                                        </tbody>
                                )
                            }
                        </table>
                    </div>
                </div>

            </div>
        )
    }
}
export default withRouter(ListaAsignaturasCarga);