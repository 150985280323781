import { Component } from "react";
import { withRouter } from "react-router";
import swal from 'sweetalert2';
import DocumentosService from "services/DocumentosService";
import { NavLink } from 'react-router-dom';


class RevisionDocumentos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id_prospecto: this.props.newParams ? this.props.newParams.id_prospecto : "",
            nombrecompleto: this.props.newParams ? this.props.newParams.nombre : "",
            tipo_doc: "",
            ID_Documento: "",
            Descripcion: "",
            view: false,
            view_pdf: false,
            documentos: [],
            base64: "",
            Checked: 2,
            validado: 0
        }

        this.previewFile = this.previewFile.bind(this);
        this.CerrarModal = this.CerrarModal.bind(this);
        this.CerrarModalPdf = this.CerrarModalPdf.bind(this);
        this.ModalView = this.ModalView.bind(this);
        this.base64toBlob = this.base64toBlob.bind(this);
        this.goBack = this.goBack.bind(this);
        this.CheckedTodos = this.CheckedTodos.bind(this);
        this.aceptar_validacion = this.aceptar_validacion.bind(this);




    }

    goBack() {
        this.props.history.goBack();
    }


    componentDidMount() {
        let id_prospecto = this.state.id_prospecto;
        DocumentosService.listaDocs(id_prospecto).then((response) => {

            this.setState(
                { documentos: response.data }
            );
            this.setState(
                { validado: this.state.documentos[0].User_Alta }
            );
        });
    }

    CerrarModal() {
        this.setState({
            view: !this.state.view
        });
    }

    CerrarModalPdf() {
        this.setState({
            view_pdf: !this.state.view_pdf
        });
    }



    ModalView(Prospecto_ID, Descripcion, ID_Documento) {

        this.setState({ view_pdf: !this.state.view_pdf });
        this.setState({
            tipo_doc: Descripcion
        });
        //console.log(Prospecto_ID, Descripcion);

        DocumentosService.getAdjunt({
            id_prospecto: Prospecto_ID,
            descripcion: Descripcion, id_documento: ID_Documento
        })
            .then((res) => {
                this.state.base64 = res.data;
                var base64Pdf = this.state.base64;
                var blob = this.base64toBlob(base64Pdf);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
                } else {
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl);
                }
                //console.log("adjunto: ", res.data);
            })


    }


    base64toBlob(base64Data) {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: "application/pdf" });
    }



    previewFile(e) {
        e.preventDefault();


        var file = document.getElementById('archivo').files[0];

        var reader = new FileReader();

        var id_pro = this.state.ID_Documento;
        var nombre = this.state.nombrecompleto;
        var documento = this.state.tipo_doc;
        const $this = this;
        reader.onload = function (e) {

            const parts = file.name.split('.')
            const extension = parts[parts.length - 1]
            if (extension === 'pdf' || extension === 'PDF') {
                //console.log("es pdf /////////////");

                var base64PDF = e.target.result;
                base64PDF = base64PDF.split("base64,")[1];

                let doc = {
                    id_documento: id_pro,
                    nombre: nombre,
                    pdf: base64PDF,
                    npdf: documento,
                    id_prospecto: $this.state.id_prospecto,
                }

               // console.log(doc);


                DocumentosService.addDocumento(doc).then(
                    (response) => {
                        //console.log(" se agrego el pdf " + JSON.stringify(response), $this);
                        DocumentosService.listaDocs($this.state.id_prospecto).then((response) => {
                            //document.getElementById('archivo').value = "";
                            $this.setState(
                                { documentos: response.data }
                            );
                            $this.setState({ view: false });
                        });

                    }
                );


            }
            else {
                swal.fire({
                    text: "Tipo de Archivo no permitido",
                    icon: "error",
                });
            }
        }
        if (file) {
           // console.log("file");
            reader.readAsDataURL(file);
        } else {
            //console.log("vacio");

        }

    }



    CalucularMes(mes) {
        switch (mes) {
            case 1:
                {
                    mes = "enero";
                    break;
                }
            case 2:
                {
                    mes = "febrero";
                    break;
                }
            case 3:
                {
                    mes = "marzo";
                    break;
                }
            case 4:
                {
                    mes = "abril";
                    break;
                }
            case 5:
                {
                    mes = "mayo";
                    break;
                }
            case 6:
                {
                    mes = "junio";
                    break;
                }
            case 7:
                {
                    mes = "julio";
                    break;
                }
            case 8:
                {
                    mes = "agosto";
                    break;
                }
            case 9:
                {
                    mes = "septiembre";
                    break;
                }
            case 10:
                {
                    mes = "octubre";
                    break;
                }
            case 11:
                {
                    mes = "noviembre";
                    break;
                }
            case 12:
                {
                    mes = "diciembre";
                    break;
                }
            default:
                {
                    mes = "Error";
                    break;
                }
        }
        return mes;
    }

    CheckedTodos=(event)=>{
        let valor = event.target.value;
        var aux = valor;
        let TotalSuma = (parseInt(aux) + parseInt(valor));
        this.setState(
            { Checked: TotalSuma }
        );
        console.log(TotalSuma,aux,this.state.Checked);
    }

    aceptar_validacion() {

        if (this.state.CheckedTodos == 12) {
            swal.fire({
                icon: 'error',
                title: 'error',
                text: 'Por favor valide todos los documentos',
            })

        } else {

            DocumentosService.aceptar_validacion(this.state.id_prospecto).then((res) => {
                //console.log(res.data);
                if (res.data) {

                    swal.fire({
                        icon: 'success',
                        title: 'éxito',
                        text: 'La documentación fué validad con éxito',
                    });
                    this.goBack();
                }
                else {
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Ocurrio un error',
                    });
                }
            });

        }

    }




    render() {
        return (
            <>
                <div>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">prospectos</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">documentos</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>


                    <div className="container mx-auto h-16 text-center uppercase mb-5 mt-5">
                        <div className=" text-center">
                            <h1 className="text-2xl md:text-3xl text-white font-bold mb-1 text-center">
                                Documentación del prospecto {this.state.nombrecompleto}
                            </h1>
                        </div>
                    </div>


                    <div className="mx-10 my-4 overflow-x-auto">
                        <table className="w-full whitespace-nowrap my_table">
                            <thead className="h-10">
                                <tr>
                                    <th className="border border-slate-300">Documento</th>
                                    <th className="border border-slate-300">Estado</th>
                                    <th className="border border-slate-300">Fecha registro</th>
                                    <th className="border border-slate-300">Acciones</th>

                                </tr>
                            </thead>
                            <tbody>

                                {this.state.documentos.map(
                                    (docs, index) =>

                                    <tr className="h-20 text-sm leading-none text-gray-800 border border-slate-300">

                                        <td className="pl-2 text-left">
                                            <p className="font-medium">{docs.Descripcion}</p>
                                        </td>

                                        <td className="pl-2 text-left">
                                            {docs.User_Alta == 0 ? (
                                                <p className="font-medium">{!docs.Url_doc ?
                                                    <p className="font-medium"><span class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">NA</span></p> : <p className="font-medium"><span class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">CARGADO</span></p>}</p>

                                            )

                                                :

                                                <p className="font-medium">{!docs.Url_doc ?
                                                    <p className="font-medium"><span class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">NA</span></p> : <p className="font-medium"><span class="bg-green-600 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900"><i class="fas fa-check-double"></i> VALIDADO</span></p>}</p>


                                            }

                                        </td>

                                        <td className="pl-2 text-left">
                                            <p className="font-medium">{(docs.Fecha.split("-")[2]).split(" ")[0] + "-" + this.CalucularMes(parseInt(docs.Fecha.split("-")[1])) + "-" + docs.Fecha.split("-")[0]}</p>
                                        </td>

                                        <td className="pl-2 text-left">

                                            <button title="detalles" onClick={() => this.ModalView(docs.Prospecto_ID, docs.Descripcion, docs.ID_Documento)}>
                                                <svg class="h-8 w-8 text-green-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
                                            </button>
                                            {docs.User_Alta == 0 ? (
                                                <div>
                                                    <label>
                                                        <input onClick={this.CheckedTodos} type="checkbox" id="CheckTodos" name="grupo" value={index+1} />
                                                        <span className="ml-2">validar</span>
                                                    </label>
                                                </div>
                                            )

                                                :

                                                <p className="font-medium"></p>

                                            }

                                        </td>





                                    </tr>



                                )
                                }


                            </tbody>

                        </table>

                        {this.state.validado == 0 ? (
                            <div className="flex justify-center mt-8">
                                <button onClick={this.aceptar_validacion} class="bg-green-800 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                    <i class="fas fa-check-double"></i>  aceptar
                                </button>
                            </div>
                        )
                            :

                            <p className="font-medium"></p>
                        }


                    </div>
                </div>


                {this.state.view_pdf ? (
                    <>
                        <div className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" >
                            <form onSubmit={this.previewFile}>
                                <div className="relative">
                                    {/*content*/}
                                    <div className=" modal-dialog border-0 rounded-lg  relative flex flex-col bg-white outline-none focus:outline-none">
                                        {/*header*/}
                                        <div className="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
                                            <p class="text-blue-900 text-3xl ">{this.state.tipo_doc}</p>
                                        </div>
                                        {/*body*/}


                                        {/*footer*/}
                                        <div className="flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b">

                                            <button
                                                className="text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="submit"
                                                onClick={() => this.CerrarModalPdf(false)}
                                            >
                                                Cerrar
                                            </button>


                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </>
        );
    }

}

export default withRouter(RevisionDocumentos);