import { Component } from "react";
import AsignaturasService from "services/AsignaturasService";
import AreaAsignaturaService from "services/AreaAsignaturaService";
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert2'
import open from "../images/open.png"
import { withRouter } from "react-router-dom";


 class ListaAsignaturas extends Component{
    constructor(props){
        super(props)
        this.state = {
            id_especialidad:sessionStorage.getItem("id_e"),
            nombre_especialidad:sessionStorage.getItem("n_e"),
            id_plantel:sessionStorage.getItem("plantel_id"),
            nombre_plantel: sessionStorage.getItem("nombre_plantel"),
            id_checkbox:this.props.newParams? this.props.newParams.id_checkbox:"",
            id_checkboxTemp:'',
            clave_asignatura:'',
            creditos_asignatura:'',
            especialidad_id:'',
            seriacion:'',
            area_id:'',
            horas_academico:'',
            horas_ind:'',
            id_asignatura:'',
            nombre_area:'',
            nombre:'',
            costo:'',
            intro:'',
            imagen:'',
            descripcion:'',

            Especialidades:[],
            Asignaturas:[],
            AsignaturasTemp:[],
            Areas:[],

            modaldetalle:false,
            modalactualizar:false,

            txtCalificacionActual:'hidden',
            txtCalificacionTodos:'',
        }
       this.ListaAsignaturas=this.ListaAsignaturas.bind(this);
       this.change_Especialidad=this.change_Especialidad.bind(this);
       this.state.ModalDetalles=this.ModalDetalles.bind(this);
       this.ModalActualizar=this.ModalActualizar.bind(this);
       this.CheckedTodos=this.CheckedTodos.bind(this);
       this.CheckedActivos=this.CheckedActivos.bind(this);
       this.MostrarAsignaturasActivos=this.MostrarAsignaturasActivos.bind(this);

       this.filter_nombreAsignatura=this.filter_nombreAsignatura.bind(this);
    }

    change_Especialidad=(event)=>{
        this.ListaAsignaturas();
    }

    componentDidMount(){
        console.log("nombre del plantel "+this.state.nombre_plantel)
        if(this.state.id_checkbox===''){
            if(this.state.nombre_plantel === "UNIVERSIDAD"){
                this.ListaAsignaturas();
                console.log("Dentro del if en lista asignaturas"+ this.nombré_plantel);
            }
            else{
                //this.ListaAsignaturasByPlantel();
                this.ListaAsignaturas();
            }

            document.getElementById('CheckTodos').checked=true;
            document.getElementById('CheckActivar').checked=false;
            this.setState({txtCalificacionActual:'hidden'});
        }
        else{
            this.MostrarAsignaturasActivos();
        }
    }

    AsignaturaById(id_asignatura){
        let asig;
        AsignaturasService.AsignaturaById(id_asignatura).then((res)=>{
            //console.log("datos asignatura ",res.data);
            asig=res.data;
            //console.log("asig ",asig);
            
            this.setState({
                area_id:asig.area_id,
                clave_asignatura:asig.clave_asignatura,
                creditos_asignatura:asig.creditos_asignatura,
                especialidad_id:asig.especialidad_id,
                horas_academico:asig.horas_academico,
                horas_ind:asig.horas_ind,
                seriacion:asig.seriacion,
                id_asignatura:asig.id_asignatura,
                nombre_asignatura:asig.nombre_asignatura,
                nombre_area:asig.nombre_area,
                costo:asig.costo,
                intro:asig.intro,
                imagen:asig.imagen,
                descripcion:asig.descripcion,
                })
            })
    }
    /**
     * 0 = Mostrar todas las asignaturas
     * 1 = Mostrar las asignaturas activas
     */

    ListaAsignaturasByPlantel = async() =>{
        AsignaturasService.AsignaturabyPlantel(this.state.id_plantel).then((response)=>{
            console.log("response plantel ",response.data)
            if(response.data.length>0){
                this.setState({Asignaturas:response.data, AsignaturasTemp:response.data});
            }
            else{
            swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'No existen asignaturas ',
            });
            this.setState({Asignaturas:[], AsignaturasTemp:[]});
            }
        })
    }

    async ListaAsignaturas(){
        AsignaturasService.AsignaturabyEspecialidad(this.state.id_especialidad).then((res)=>{
            //console.log("total "+res.data.length);
           if(res.data.length>0){
            this.setState({Asignaturas:res.data, AsignaturasTemp:res.data});
           }
           else{
            swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'No existen asignaturas ',
            });
            this.setState({Asignaturas:[], AsignaturasTemp:[]});
           }
        })
    }

    ModalDetalles(id_asignatura){
        this.AsignaturaById(id_asignatura);
        this.setState({modaldetalle:!this.state.modaldetalle});   
    }

    ModalActualizar(id_asignatura){
        AreaAsignaturaService.ListaAreas().then((res)=>{
            this.setState({Areas:res.data})
            //console.log("areas ",this.state.Areas);
        }) 
        this.AsignaturaById(id_asignatura);
        this.setState({modalactualizar:!this.state.modalactualizar});
    }

    ActualizarDatos=(event)=>{
        event.preventDefault();
        let id_area=document.getElementById("id_area").value;
        let asignatura={
            id_asignatura:this.state.id_asignatura,
            clave_asignatura:this.state.clave_asignatura,
            creditos_asignatura:this.state.creditos_asignatura,
            especialidad_id:this.state.id_especialidad,
            horas_academico: this.state.horas_academico,
            horas_ind:this.state.horas_ind,
            nombre_asignatura:this.state.nombre_asignatura,
            seriacion:this.state.seriacion,
            area_id:id_area,
            costo:this.state.costo,
            intro:this.state.intro,
            imagen:this.state.imagen,
            descripcion:this.state.descripcion,
            };

        AsignaturasService.AsignaturaUpdate(asignatura).then((res)=>{
           // console.log("se ha agregado correctamente "+res.data);
            if(res.data===1){
                this.setState({modalactualizar:!this.state.modalactualizar});
                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Actualizado',
                 });
                this.componentDidMount();
            }
            else{
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al actualizar los datos',
                 });
            }
        })
    }

	CheckedTodos(){
            if(document.getElementById("CheckTodos").checked===true){
                //console.log("entra if CheckTodos");
                this.setState({txtCalificacionActual:'hidden', txtCalificacionTodos:'', id_checkbox:''});
                this.componentDidMount();
            }
            else if(document.getElementById("CheckTodos").checked===false){
                //console.log("entra en false CheckTodos");
                document.getElementById('CheckTodos').checked=true;
            }
	}

    CheckedActivos(){
        if(document.getElementById("CheckActivar").checked===true){
            //console.log("entra if CheckActivar");
            this.setState({txtCalificacionActual:'', txtCalificacionTodos:'hidden'});
            this.MostrarAsignaturasActivos();
        }
        else if(document.getElementById("CheckActivar").checked===false){
            //console.log("entra else if CheckActivar");
            document.getElementById('CheckActivar').checked=true;
        }
	}

    MostrarAsignaturasActivos(){
        document.getElementById('CheckActivar').checked=true;
        document.getElementById('CheckTodos').checked=false;
        AsignaturasService.AsignaturaActivos(this.state.id_especialidad).then((res)=>{
            if(res.data==""){
                this.setState({Asignaturas:[]});
                swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    text: 'En la especialidad no hay asignaturas activas',
                 });
            }
            else{
                 this.setState({Asignaturas:res.data, AsignaturasTemp:res.data, txtCalificacionActual:'', txtCalificacionTodos:'hidden'});

            }
   
        })
    }

    filter_nombreAsignatura=(event)=>{
		var textAsignatura = event.target.value   
        const data = this.state.Asignaturas;
        const newData = data.filter(function(item){
        const itemData = item.nombre_asignatura.toUpperCase()
        const textData = textAsignatura.toUpperCase()
        return itemData.indexOf(textData) > -1
        })
        if(textAsignatura === ""){
            if(document.getElementById("CheckActivar").checked===true){
                this.MostrarAsignaturasActivos();
            }else{
                this.setState({Asignaturas:this.state.AsignaturasTemp})
            }

        } else {
          this.setState({
            Asignaturas: newData,
            textAsignatura: textAsignatura,
          })
        }
	}

    goBack=()=> {
        this.props.history.push("/app/Listaplanteles");
    }

    actualizarDatos=(id_especialidad)=>{
		this.setState({modaldetalle:!this.state.modaldetalle});
        this.ModalActualizar(id_especialidad);
	}


    render(){
        return(
            <div className="bg-primary_2 h-auto pb-20">
                <div className="w-full  p-4 sm:p-6 overflow-hidden h-20 z-10">
					<div className="relative text-center">
						<h1 className="text-2xl uppercase md:text-3xl text-white font-bold mb-1 text-center font-barlow tracking-widest">
							Lista de asignaturas
						</h1>
					</div>
				</div>

                <div className="flex justify-start mx-10 mt-4">
					<nav class="flex" aria-label="Breadcrumb">
						<ol class="inline-flex items-center space-x-1">
							<li class="flex justify-end">
								<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
									<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
									Home
								</a>
							</li>
							<li>
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Planteles</a>
								</div>
							</li>
							<li aria-current="page">
								<div class="flex items-center">
									<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
									<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Lista de asignaturas</span>
								</div>
							</li>
						</ol>
					</nav>
				</div>
                <div className="mr-10  sm:flex lg:flex grid grid-cols-1 justify-between ">
                    <div className="flex justify-start mx-20">
                        <div class="xl:w-96 mt-8">
                            <div class="input-group relative flex flex-wrap items-stretch w-full mb-4 ">
                                <input type="search"
                                    class="form-control relative flex-auto px-3 py-1.5 text-base font-normal text-white bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none"
                                    placeholder="buscar"
                                    aria-label="Search"
                                    id="txtbuscar"
                                    name="txtbuscar"
                                    onKeyPress={this.busqueda}
                                    onChange={(text) => this.filter_nombreAsignatura(text)} 
                                    aria-describedby="button-addon2" />

                                <button
                                    onClick={this.busqueda}
                                ><svg class="h-6 w-6 text-white ml-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 flex flex-items-reverse px-10 py-1 ">
                       
                        <NavLink class="flex items-reverse" to={{
                            pathname: '/app/NuevaAsignatura',
                            state: { 
                            id_especialidad:this.state.id_especialidad,
                            nombre_especialidad:this.state.nombre_especialidad,
                            }
                                }}> 
                                <div className="inline-flex sm:ml-3 sm:mt-0 px-6 py-1 bg-color1 hover:bg-color1 focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110"> 
                                
                                <p className="text-sm font-medium leading-none text-white mt-1 mr-2 ml-2"><i class="fas fa-plus-circle"></i> Nueva asignatura</p>									
                                </div>
                        </NavLink> 
                    </div>
                </div>                        

                    <div className="mx-8 my-8 mr-8 overflow-x-auto ">
                        <div className=" px-2 md:px-10 md:pt-7 pb-5 overflow-y-auto">
                            <table className="bg-color4 w-full border-collapse border border-slate-400 table-auto my_table" id="Tabla_alumnos">
                                <thead className="h-10">
                                    <tr>
                                        <th className="font-semibold text-center text-lg ">Clave de asignatura<br/>
                                        </th>
                                        <th className="font-semibold text-center text-lg"><br/>Asignatura
                                            <div class="flex flex-items-center ml-10">
                                                <label class="flex items-center">
                                                    <input onClick={this.CheckedTodos} type="checkbox" id="CheckTodos" name="grupo" class="form-checkbox"/>
                                                    <span class="ml-2 text-black text-sm ">Todos</span>
                                                </label>
                                                <label  class="ml-10 flex items-center">
                                                    <input onClick={this.CheckedActivos}  type="checkbox" id="CheckActivar" name="grupo" class="form-checkbox"/>
                                                    <span class="ml-2 text-black text-sm">Activos</span>
                                                </label>
                                            </div>
                                        </th>
                                        <th hidden={this.state.txtCalificacionActual} className="font-semibold text-center text-lg ">Docente<br/>
                                        </th>
        
                                        <th className="font-semibold text-center text-lg">Área<br/>
                                        </th>

                                        <th className="font-semibold text-center text-lg">Mostrar</th>

                                        <th hidden={this.state.txtCalificacionActual} className="font-semibold text-center text-lg ">Calificaciones<br/>
                                        </th>
                                    </tr>
                                </thead>
                                    {
                                        this.state.Asignaturas.map(
                                            (asignatura, index)=>
                                            <tbody  key={index} >
                                                <tr>
                                                    <td >
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{asignatura.Clave_asignatura}</h1>
                                                    </td>
                                                    <td > 
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{asignatura.nombre_asignatura}</h1>
                                                    </td>
                                                    <td hidden={this.state.txtCalificacionActual} >
                                                        <h1 className="font-semibold text-primary text-left pl-5 text-sm">{asignatura.nombre+" "+asignatura.app_paterno+" "+asignatura.app_materno}</h1>
                                                    </td>
                                                                
                                                    <td >
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{asignatura.nombre_area}</h1>
                                                    </td>
                                                
                                                    <td>
                                                        <div class="flex justify-center items-center">
                                                            <div class="mt-2 ml-3 transform hover:text-purple-500 hover:scale-110">
                                                                <button hidden={this.state.ocultar}  onClick={()=>this.ModalDetalles(asignatura.id_asignatura)}  title="Detalles asignatura">
                                                                    <svg class="h-7 w-7 text-alternative1" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
                                                                </button>
                                                            </div>

                                                            <div class="mt-2 ml-3 transform hover:text-purple-500 hover:scale-110">
                                                                <button hidden={this.state.ocultar} onClick={()=>this.ModalActualizar(asignatura.id_asignatura)}  title="Actualizar asignatura">
                                                                    <svg class="h-6 w-6 text-alternative2" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td hidden={this.state.txtCalificacionActual} >
                                                        <NavLink class="flex justify-center items-center" to={{
                                                            pathname: '/app/CalificacionesActuales',
                                                            state: { 
                                                            id_especialidad:this.state.id_especialidad,
                                                            nombre_especialidad:this.state.nombre_especialidad,
                                                            id_asignatura:asignatura.id_asignatura,
                                                            nombre_asignatura:asignatura.nombre_asignatura,
                                                            docente:asignatura.nombre+" "+asignatura.app_paterno+" "+asignatura.app_materno,
                                                            id_checkbox:1,                                           
                                                            }
                                                                }}> 

                                                        <button>
                                                            <svg class="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />  <line x1="10" y1="14" x2="20" y2="4" />  <polyline points="15 4 20 4 20 9" /></svg>													
                                                        </button>
                                                        </NavLink>   
                                                    </td>

                                                </tr>
                                            </tbody>
                                        )
                                    }
                            </table>
                        </div>
                    </div>

                    {this.state.modaldetalle ?(
                        <>
                            <div div class="overflow-y-auto h-32">
                                <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                    <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                        {/*content*/}
                                        <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                            {/*header*/}
                                            <div className='rounded-t-3xl'>
                                                <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Detalles</p>
                                            </div>
                                            {/*body*/}

                                            <div className="flex justify-end mx-10 ">
                                                <nav class="flex" aria-label="Breadcrumb">
                                                    <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                                    <button onClick={()=>this.actualizarDatos(this.state.id_asignatura)} class="bg-secondary hover:bg-primary text-white font-bold py-1 px-4 rounded-full">Editar asignatura</button>
                                                    
                                                    </ol>
                                                </nav>
                                            </div>

                                            <div className='relative flex-auto'>
                                                <div class='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                                        <div className="shadow-sm2 px-5 py-2 rounded-3xl">
                                                            <div>
                                                                <p class='mb-2 font-bold text-primary  '>
                                                                    Nombre de la asignatura:
                                                                </p>
                                                                <p class='mb-2 font-semibold text-secondary '>
                                                                {this.state.nombre_asignatura}
                                                                </p>
                                                            </div>
                                                               

                                                            <div class='grid grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 md:grid-cols-1 sm:grid-cols-1'>
                                                                <div>
                                                                    <p class='mb-2 font-bold text-primary '>
                                                                        Clave</p>
                                                                    <p class='mb-2 font-semibold text-secondary'>
                                                                        {this.state.clave_asignatura}</p>
                                                                </div>
                                                                <div>
                                                                    <p class='mb-2 font-bold text-primary  '>
                                                                        Créditos</p>
                                                                    <p class='mb-2 font-semibold text-secondary'>
                                                                        {this.state.creditos_asignatura}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p class='mb-2 font-bold text-primary '>
                                                                        Horas académico
                                                                    </p>
                                                                    <p class='mb-2 font-semibold text-secondary '>
                                                                    {this.state.horas_academico}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p class='mb-2 font-bold text-primary '>
                                                                        Horas independiente</p>
                                                                    <p class='mb-2 font-semibold text-secondary '>
                                                                        {this.state.horas_ind}</p>
                                                                </div>

                                                            </div><br/>
                                                        </div><br/>

                                                        {
                                                            this.state.nombre_plantel === "UNIVERSIDAD"  || this.state.nombre_plantel === "DIPLOMADOS"  ? 
                                                            <div className="shadow-sm2 px-5 py-2 rounded-3xl">
                                                                <div class='grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-1 sm:grid-cols-1'>
                                                                    <div>
                                                                        <p class='mb-2 font-bold text-primary  '>
                                                                            Area
                                                                        </p>
                                                                        <p class='mb-2 font-semibold text-secondary '>
                                                                        {this.state.nombre_area}
                                                                        </p>
                                                                    </div>
                                                            
                                                                    <div>
                                                                        <p class='mb-2 font-bold text-primary '>
                                                                           Seriación</p>
                                                                        <p class='mb-2 font-semibold text-secondary'>
                                                                            {this.state.seriacion}</p>
                                                                    </div>

                                                                </div>
                                                                
                                                                <div class='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-1 sm:grid-cols-1'>
                                                                    <div>
                                                                        <p class='mb-2 font-bold text-primary  '>
                                                                        Link del intro
                                                                        </p>
                                                                        <p class='mb-2 font-semibold text-secondary '>
                                                                        {this.state.intro}
                                                                        </p>
                                                                    </div>
                                                            
                                                                    <div>
                                                                        <p class='mb-2 font-bold text-primary '>
                                                                            Link de imagen</p>
                                                                        <p class='mb-2 font-semibold text-secondary'>
                                                                            {this.state.imagen}</p>
                                                                    </div>

                                                                    <div>
                                                                        <p class='mb-2 font-bold text-primary '>
                                                                            Costo</p>
                                                                        <p class='mb-2 font-semibold text-secondary'>
                                                                            {"$ "+this.state.costo}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            
                                                                <div>
                                                                    <p class='mb-2 font-bold text-primary  '>
                                                                        Descripción </p>
                                                                    <p class='mb-2 font-semibold text-secondary'>
                                                                        {this.state.descripcion}
                                                                    </p>
                                                                </div>
                                                                  
                                                            </div> :" "
                                                        }
                                                   
                                                </div>
                                            </div>
                                            {/*footer*/}
                                            <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                                <button
                                                    className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                    type='button'
                                                    onClick={() => this.setState({modaldetalle:false})}>
                                                    Cerrar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={() => this.setState({modaldetalle:false})} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
                                </div>
                              
                                </div>
                        </>
                    ):null}

                    {this.state.modalactualizar ?(
                        <>
                           	<form onSubmit={this.ActualizarDatos}>
                                <div div class="overflow-y-auto h-32">
                                    <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                        <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                            {/*content*/}
                                            <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                                {/*header*/}
                                                <div className='rounded-t-3xl'>
                                                    <h3 className='text-primary text-3xl text-center my-5 font-bold uppercase'>
                                                        Actualizar Datos
                                                    </h3>
                                                </div>
                                                {/*body*/}

                                                <div className='relative p-6 flex-auto'>
                                                        <div class='grid grid-cols-1 gap-5 px-6 py-5 '>
                                                            {
                                                                this.state.nombre_plantel === "UNIVERSIDAD" ? 

                                                                <div className='grid grid-cols-1 md:grid-cols-1 gap-1 md:gap-1 mt-1'>
                                                                    <div>
                                                                        <label className="mb-2 font-bold text-primary  ">
                                                                            Especialidad
                                                                        </label>
                                                                        <p class='mb-2 font-semibold text-primary  '>
                                                                        {this.state.nombre_especialidad}
                                                                        </p>
                                                                    </div>
                                                                </div> : " "
                                                            }

                                                                <div className='grid grid-cols-1 md:grid-cols-1 gap-1 md:gap-1 mt-1'>
                                                                    <div >
                                                                        <label className='mb-2 font-bold text-primary '>
                                                                            Nombre de la asignatura
                                                                            <span className='text-red-500 text-xl'>*</span><br />                                                                        </label><br/>
                                                                        <input
                                                                            className='mt-1 w-full uppercase rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                            type='text'
                                                                            name='nombre'
                                                                            value={this.state.nombre_asignatura}
                                                                            onChange={event => this.setState({nombre_asignatura:event.target.value})}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='grid grid-cols-4 md:grid-cols-4 gap-5 md:gap-8 mt-5 '>
                                                                    <div >
                                                                        <label className='mb-2 font-bold text-primary '>
                                                                            Clave
                                                                            <span className='text-red-500 text-xl'>*</span>
                                                                        </label>
                                                                        <input
                                                                            className='uppercase mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                            type='text'
                                                                            name='app_Paterno'
                                                                            value={this.state.clave_asignatura}
                                                                            onChange={event=>this.setState({clave_asignatura:event.target.value})}
                                                                            required
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <label className='mb-2 font-bold text-primary '>
                                                                            Créditos
                                                                            <span className='text-red-500 text-xl'>*</span>
                                                                        </label>
                                                                        <input
                                                                            className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                            type='text'
                                                                            name='creditos'
                                                                            value={this.state.creditos_asignatura}
                                                                            onChange={event=>this.setState({creditos_asignatura:event.target.value})}
                                                                            required
                                                                        />
                                                                    </div>

                                                                    <div >
                                                                        <label className='mb-2 font-bold text-primary '>
                                                                            Horas académico
                                                                            <span className='text-red-500 text-xl'>*</span>
                                                                        </label>
                                                                        <input
                                                                            className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                            type='text'
                                                                            name='horas_academico'
                                                                            value={this.state.horas_academico}
                                                                            onChange={event=>this.setState({horas_academico:event.target.value})}
                                                                            required
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <label className='mb-2 font-bold text-primary '>
                                                                            Horas independientes
                                                                            <span className='text-red-500 text-xl'>*</span>
                                                                        </label>
                                                                        <input
                                                                            className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                            type='text'
                                                                            name='creditos'
                                                                            value={this.state.horas_ind}
                                                                            onChange={event=>this.setState({horas_ind:event.target.value})}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                           
                                                        </div>
                                                        {
                                                            this.state.nombre_plantel === "UNIVERSIDAD"  || this.state.nombre_plantel === "DIPLOMADOS"  ? 
                                                            <div className="shadow-sm2 px-5 py-2 rounded-3xl">
                                                                <div class='grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-1 sm:grid-cols-1'>
                                                                    <div>
                                                                        <label className='mb-2 font-bold text-primary '>
                                                                            Seriación
                                                                            <span className='text-red-500 text-xl'></span><br />
                                                                        </label>
                                                                        <input
                                                                            className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                            type='text'
                                                                            name='seriacion'
                                                                            value={this.state.seriacion}
                                                                            onChange={event => this.setState({seriacion:event.target.value})}
                                                                        
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <label className="mb-2 font-bold text-primary ">
                                                                            Seleccionar área
                                                                            <span className='text-red-500 text-xl'>*</span><br />
                                                                        </label>

                                                                        <select class="flex items-end py-2 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                                                                            name="id_area"
                                                                            id="id_area" 
                                                                            value={this.state.area_id}
                                                                            onChange={event=>this.setState({area_id:event.target.value})}
                                                                            required>
                                                                            {
                                                                                this.state.Areas.map(
                                                                                    area=>
                                                                                    <option value={area.id_area}>{area.nombre_area}</option>
                                                                                )
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-1 sm:grid-cols-1'>
                                                                    <div>
                                                                        <label className="mb-2 font-bold text-primary ">
                                                                           Link del intro
                                                                            <span className='text-red-500 text-xl'>*</span><br />
                                                                        </label>
                                                                        <input
                                                                            className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                            type='text'
                                                                            name='intro'
                                                                            value={this.state.intro}
                                                                            onChange={event => this.setState({intro:event.target.value})}
                                                                        
                                                                        />
                                                                    </div>
                                                            
                                                                    <div>
                                                                        <label className="mb-2 font-bold text-primary ">
                                                                           Link de imagen
                                                                            <span className='text-red-500 text-xl'>*</span><br />
                                                                        </label>
                                                                        <input
                                                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                        type='text'
                                                                        name='imagen'
                                                                        value={this.state.imagen}
                                                                        onChange={event => this.setState({imagen:event.target.value})}
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <label className="mb-2 font-bold text-primary ">
                                                                           Costo
                                                                            <span className='text-red-500 text-xl'>*</span><br />
                                                                        </label>
                                                                        <input
                                                                        className='mt-1 w-4/4 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                        type='number'
                                                                        name='costo'
                                                                        value={this.state.costo}
                                                                        onChange={event => this.setState({costo:event.target.value})}/>
                                                                    </div>
                                                                </div>
                                                            
                                                                <div>
                                                                    <label className="mb-2 font-bold text-primary ">
                                                                    Descripción
                                                                    <span className='text-red-500 text-xl'>*</span><br />
                                                                    </label>
                                                                    <input
                                                                        className=' w-4/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                                        type='text'
                                                                        name='descripcion'
                                                                        value={this.state.descripcion}
                                                                        onChange={event => this.setState({descripcion:event.target.value})}/>
                                                                </div>
                                                                  
                                                            </div> :" "
                                                        }
                                                   
                                                </div>
                                                {/*footer*/}
                                                <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
                                                    <button
                                                        className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                        type='button'
                                                        onClick={() => this.setState({modalactualizar:false})}>
                                                        Cerrar
                                                    </button>
                                                    <button
                                                        className='bg-emerald-500 text-black active:bg-gray-600 font-bold uppercase text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                        type='submit'>
                                                        Actualizar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => this.setState({modalactualizar:false})} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
                                    </div>
                                </div>
						</form>
                        </>
                    ):null}


            </div>
        )
    }
}
export default withRouter(ListaAsignaturas);