import React, { useEffect } from "react";
import { NotificationsNone, Language, Settings } from "@material-ui/icons";
//import { isLoggedIn } from '../../utils';
import { isLoggedIn } from "../utils";
import { Link, useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";

import user from '../images/user.png'
import menu_points from '../images/menu_points.svg'

import { useState } from 'react';
import imgNoHayFoto from '../images/user.png';
var CryptoJS = require("crypto-js");

function Header({ sidebarOpen, setSidebarOpen }) {

	console.log("MENU MOBILE")

	let history = useHistory();
	var ocultar = "hidden";
	const [Ocultar, setOcultar] = useState("hidden");
	var us  = sessionStorage.getItem("token");
	var des_nombre = JSON.parse((CryptoJS.AES.decrypt(sessionStorage.getItem("nombre"), 'my-secret-key@123')).toString(CryptoJS.enc.Utf8));
	var des_paterno = JSON.parse((CryptoJS.AES.decrypt(sessionStorage.getItem("paterno"), 'my-secret-key@123')).toString(CryptoJS.enc.Utf8));
	var des_materno = JSON.parse((CryptoJS.AES.decrypt(sessionStorage.getItem("materno"), 'my-secret-key@123')).toString(CryptoJS.enc.Utf8));
	var fotoImg = (CryptoJS.AES.decrypt(sessionStorage.getItem("fotoImg"), 'my-secret-key@123')).toString(CryptoJS.enc.Utf8);
	var fotoImg = (fotoImg!="no"?fotoImg:imgNoHayFoto);

	function handleLogout() {
		sessionStorage.removeItem("roles");
		sessionStorage.removeItem("token");
		sessionStorage.clear();
		window.location.href = '/';
	}

	/*
	function handleButtonClick (){
		if(Ocultar=="hidden"){
			setOcultar("");
		} else{
			setOcultar("hidden");
		}
	};
	*/

	return (
		<header className="top-0 bg-secondary_2 z-50 absolute w-full h-16">
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="flex items-center justify-between h-16 -mb-px">
					{/* Header: Left side */}
					<div className="flex">
						{/* Hamburger button */}
						<button
							className="text-white lg:hidden"
							aria-controls="sidebar"
							aria-expanded={sidebarOpen}
							onClick={() => setSidebarOpen(!sidebarOpen)}
						>
							<span className="sr-only">Open sidebar</span>
							<svg
								className="w-6 h-6 fill-current"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect x="4" y="5" width="16" height="2" />
								<rect x="4" y="11" width="16" height="2" />
								<rect x="4" y="17" width="16" height="2" />
							</svg>
						</button>
					</div>

					{/* Header: Right side */}
					<div className="flex items-center">

						<div className="flex items-center mr-2 text-sm">
							<div className="w-10 h-10 mr-3 mt-3">
								<img id="fotoUsuarioId" className="rounded-full" src={fotoImg} />
							</div>

							<div className="container relative inline-block text-left " >
								<button type="button" class="button text-white font-barlow tracking-wider transition duration-300 ease-in-out transform hover:translate-y-px flex" onClick={()=>setOcultar(!Ocultar)} >
									{des_nombre+" "+des_paterno+" "+des_materno+" "} 
										<img src={menu_points} className="w-5 ml-2" />
								</button>
									<div hidden={Ocultar} class="absolute right-0 w-48 py-2 mt-2 bg-gray-100 rounded-md shadow-xl" >
										<li
											class="block px-4 py-2 text-sm text-gray-300 text-gray-700 hover:bg-gray-400 hover:text-white"
										>
											<Link to="/app/PerfilUsuario" className=" inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start" > 
												<svg class="h-6 w-6 text-black"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
												</svg>
												<p className="mt-2 text-sm font-medium leading-none text-black">Perfil</p>  
											</Link>
										</li>
										<li
										class="block px-4 py-2 text-sm text-gray-300 text-gray-700 hover:bg-gray-400 hover:text-white"
										>
										{isLoggedIn() && (
											<Link onClick={handleLogout} className=" inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start">
											<svg class="h-6 w-6 text-black"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />  <path d="M7 12h14l-3 -3m0 6l3 -3" /></svg>
											<p className="mt-2 text-sm font-medium leading-none text-black">Cerrar sesión</p>
											</Link>
										)}
											
										</li>
									
									</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
export default Header;