import { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import VideosService from "services/VideosService";
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert2/dist/sweetalert2.js'


class EditarVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id_asignatura: sessionStorage.getItem("id_asignatura"),
            nombre_asignatura: sessionStorage.getItem("nombre_asignatura"),
            id_tema: sessionStorage.getItem("id_tema"),
            nombre_tema: sessionStorage.getItem("nombre_tema"),
            id_subtema: sessionStorage.getItem("id_subtema"),
            nombre_subtema: sessionStorage.getItem("nombre_subtema"),
            id_video: sessionStorage.getItem("id_video"),
            nombre_video: sessionStorage.getItem("nombre_video")

        }

        this.RegistrarVideo = this.RegistrarVideo.bind(this);
    }

    componentDidMount() {
    }

    RegistrarVideo = (event) => {
        event.preventDefault();
        var video = new FormData();

        video.append('idSubtema', this.state.id_subtema);
        video.append('nombreVideo', this.state.nombre_video);
        video.append('idVideo', this.state.id_video);
        if (document.getElementById('imagen').files[0] !== undefined) {
            video.append('imagen', document.getElementById('imagen').files[0]);
        }

        VideosService.EditarVideo(video).then((res) => {
            if (res.data === 1) {
                this.props.history.push({
                    pathname: '/app/ListaVideos'
                });

            }
            else {
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Ocurrio un error intentelo de nuevo',
                });
            }

        });
    }

    goBack = () => {
        this.props.history.push("/app/ListaEspecialidadesCarga");
    }

    goBackAsignaturas = () => {
        this.props.history.push("/app/ListaAsignaturasCarga");
    }

    goBackTemas = () => {
        this.props.history.push("/app/ListaTemas");
    }

    goBackSubtemas = () => {
        this.props.history.push("/app/ListaSubtemas");
    }

    goBackVideos = () => {
        this.props.history.push("/app/ListaVideos");
    }

    render() {
        return (
            <>
                <div className="bg-primary_2 h-auto">
                    <div className="flex justify-start mx-10 mt-4">
                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1">
                                <li class="flex justify-end">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de especialidades y licenciaturas</a>
                                    </div>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBackAsignaturas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de asignaturas</a>
                                    </div>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBackTemas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de temas</a>
                                    </div>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBackSubtemas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de subtemas</a>
                                    </div>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBackVideos} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de videos</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Editar video</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <form className="bg-primary_2 h-auto pb-20" onSubmit={this.RegistrarVideo}>
                    <div className="grid justify-items-center  mx-8 my-8 mr-8 overflow-x-auto">
                        <div className="ml-10 w-3/4 bg-color4  shadow-sm2 px-5 py-2 rounded-3xl ">
                            <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                <div className="relative text-center">
                                    <h1 className="text-2xl text-color1 font-bold mb-1 text-center">
                                        Editar video
                                    </h1>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                <div>
                                    <label className=' md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Nombre
                                        <span className='text-red-500 text-xl'>*</span><br />
                                    </label>
                                    <input
                                        className=' w-5/6 uppercase rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                        type='text'
                                        name='nombre'
                                        value={this.state.nombre_video}
                                        onChange={event => this.setState({ nombre_video: event.target.value })}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                    Imagen
                                </label>

                                <input
                                    className=' w-4/4 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                    type="file"
                                    name="imagen"
                                    id="imagen"
                                    accept="image/*"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5">
                        <div>
                            <button
                                type="submit"
                                className="focus:outline-none focus:ring-2 w-auto bg-primary hover:bg-primary rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" >
                                Guardar
                            </button>
                        </div>
                        <div>

                            <NavLink class="flex justify-left items-center " to={{
                                pathname: '/app/ListaVideos'
                            }} rel="noopener noreferrer" >
                                <div className="bg-red-700 text-white inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-4 py-3 bg-secondary hover:bg-secondary focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
                                    <p className="text-sm font-medium leading-none ">
                                        <a ><p className="font-medium text-white">Cancelar</p></a></p>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}
export default withRouter(EditarVideo);