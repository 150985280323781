import { Component } from "react"
import { NavLink } from 'react-router-dom';
import PeriodosService from "services/PeriodosService";
import swal from 'sweetalert2/dist/sweetalert2.js'
import carga_academica from "../images/carga_academica.png"
import open from "../images/open.png"
import warning from "../images/warning.png"

export default class ListaPeriodos extends Component{
	constructor(props){
		super(props);
		this.state={
			periodos:[],
			tipo_periodos:[],
			tipo_evaluacion:[],
		
			status:'',
			periodo_seleccionado:'',

			fecha_fin:'',
			fecha_inicio:'',
			id_tipo_evaluacion:'',
			nombre:'',
			periodo_id:'',
			modalUpdate:'',

			txtSeleccionado:'',
			txtCargaAcademico:'hidden',

		}
		this.change_periodoSeleccionado=this.change_periodoSeleccionado.bind(this);
		this.MostrarDatos=this.MostrarDatos.bind(this);
		this.changeStatus=this.changeStatus.bind(this);
		this.estado=this.estado.bind(this);
		this.CerrarPeriodo=this.CerrarPeriodo.bind(this);
		this.ValidarFecha=this.ValidarFecha.bind(this);
		this.CambiarFormato=this.CambiarFormato.bind(this);
	//	this.EditarPeriodo=this.EditarPeriodo.bind(this);
	}

	change_periodoSeleccionado=(event)=>{
		this.setState({periodo_seleccionado:event.target.value});
		this.MostrarDatos();
	}

	changeStatus=(event)=>{
		this.setState({status:event.target.value});
	}

	componentDidMount(){
		PeriodosService.Tipo_periodos().then((res)=>{
			this.setState({tipo_periodos:res.data});
			this.MostrarDatos();
		});
	}

	MostrarDatos(){
		let id_tipo_periodo=document.getElementById("id_tipo_periodo").value;
		console.log("periodo seleccionado "+id_tipo_periodo);
		this.setState({periodo_seleccionado:id_tipo_periodo});
		var countPeriodos = Object.keys(this.state.tipo_periodos).length;
		if(countPeriodos>0){
			PeriodosService.PeriodoActual(id_tipo_periodo).then((res)=>{
				var count = Object.keys(res.data.periodo).length;
			
				if(count>0){
					this.setState({periodos:res.data.periodo,txtCargaAcademico:''});
				}
				console.log("periodos  ",this.state.periodos);
				//var count = Object.keys(this.state.periodos).length;
			})
		}
		else{
			this.setState({periodos:[]});
			swal.fire({
				imageUrl:"https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
				imageHeight:'150',
				text: 'No hay ningún periodo activo',
				confirmButtonColor: '#18253f',
			});
		}
	}

	estado(fecha_in, fecha_fi){
		
        /**
         * Recorrer el arreglo de periodos para obtener el rango de fechas que esta activo para agregar las calificaciones 
         */
		let fecha_i=new Date(fecha_in);
		let	fecha_f=new Date(fecha_fi);
		var today = new Date();
		var hoy=this.ValidarFecha(today.getFullYear(),(today.getMonth() + 1),today.getDate());
		var fecha_inicio=this.ValidarFecha(fecha_i.getFullYear() , (fecha_i.getMonth() + 1), (fecha_i.getDate()+1));
		var fecha_fin=this.ValidarFecha(fecha_f.getFullYear(), (fecha_f.getMonth() + 1), (fecha_f.getDate()+1));
   
        /**
         * Comparar si la fecha actual esta en el rango de fecha del parcial registrado en la bd
         */
        if(hoy>=fecha_inicio && hoy<=fecha_fin){
			return "Activo"
        }
        else{
			return "Inactivo"
		}
	}

	ValidarFecha(anio, mes, dia){

        var date;
        if(mes>10 && dia>10){
            date = anio + '-' + (mes) + '-' + dia; 
        }
        else if(mes<10 && dia<10){
            date = anio + '-0' + (mes) + '-0' + dia; 
        }
        else if(mes>10 && dia<10){
            date = anio + '-' + (mes) + '-0' + dia; 
        }
        else if(mes<10 && dia>10){
            date = anio + '-0' + (mes) + '-' + dia;   
        }
        return date;
    }

	CambiarFormato(fecha){
		var f =new Date(fecha);
		var anio=f.getFullYear();
		var mes=f.getMonth() + 1;
		var dia=f.getDate()+1;

        var date;

		console.log("fecha entrante "+dia+" - "+mes+" - "+anio)
        if(mes>=10 && dia>=10){
            date = dia + '-' + (mes) + '-' + anio; 
        }
        else if(mes<10 && dia<10){
            date =  '0' + dia + '-0' + (mes) + '-' + anio; 
        }
        else if(mes>=10 && dia<10){
            date = '0' +dia + '-' + (mes) + '-' + anio; 
        }
        else if(mes<10 && dia>=10){
            date = dia + '-0' + (mes) + '-' + anio;   
        }
        return date;
    }

	/*EditarPeriodo(id_tipo_evaluacion){
		PeriodosService.EditarFechaParcial(id_tipo_evaluacion).then((res)=>{
			console.log("devuelve ", res.data);
			let parcial=res.data;
			this.setState({
				fecha_fin:parcial.fecha_fin,
				fecha_inicio:parcial.fecha_inicio,
				id_tipo_evaluacion:parcial.id_tipo_evaluacion,
				nombre:parcial.nombre,
				periodo_id:parcial.periodo_id,
				status:parcial.status,
				modalUpdate:true,
				tipo_evaluacion:res.data, 
				modalUpdate:true});
		})
	}*/
/*
	<div>
	<button onClick={()=>this.EditarPeriodo(parcial.id_tipo_evaluacion)} class="mt-5 bg-color1 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded">
		{"Editar "+parcial.nombre}
	</button> 
</div><br/>
*/
	ActualizarDatos=(event)=>{
		event.preventDefault();
		PeriodosService.UpdateFecha(this.state.id_tipo_evaluacion, this.state.fecha_inicio, this.state.fecha_fin).then((res)=>{
			console.log("se actualizo la fecha ");
			if(res.data===1){
				swal.fire({
					icon: 'success',
					title: 'Success',
					text: 'Dato actualizado correctamente',
				});
				this.setState({modalUpdate:!this.state.modalUpdate});
				this.componentDidMount();
			}
			else{
				swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Error al actualizar los datos',
				});
		
			}
		})
	}

	CerrarPeriodo(){
		swal.fire({
            icon:'warning',
			title: 'Warning',
            text:'Estas seguro de finalizar el periodo?',
			showCancelButton: true,
			confirmButtonText: 'Finalizar',
			denyButtonText: `Cancelar`,
            confirmButtonColor: '#18253f',
            cancelButtonColor: '#9b8818',
		  }).then((result) => {
			if (result.isConfirmed) {
			  let id_t_periodo=document.getElementById("id_tipo_periodo").value;
				PeriodosService.CerrarPeriodo(id_t_periodo).then((res)=>{
					if(res.data===1){
						this.componentDidMount();
					}
					else{
						swal.fire({
							imageUrl:"https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
							imageHeight:'150',
							title: 'Error',
							text: 'Error al finalizar el período',
						});
					}
						
				});
			} 
		  })
	}


    render(){
        return(
			<div className="bg-transparent text-white h-auto pb-20">
					
					<div className='relative bg-primary_2 p-4 sm:p-6 rounded-sm overflow-hidden mb-3'>
						<div className='relative text-center'>
							<h1 className='text-2xl md:text-3xl text-white  font-bold mb-1 text-center'>
							Periodo actual
							</h1>
						</div>
					</div>

					<div className="flex justify-start mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1">
								<li class="flex justify-end">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Periodo Actual</span>
									</div>
								</li>
							</ol>
						</nav>
					</div>

					

					<div className="grid grid-cols-1 md:grid-cols-1 gap-5 md:gap-8  justify-items-center">
				
						<label className="text-lg text-gray-500 text-light font-semibold">
							Escoger tipo de periodo<br/>
							</label>
							<select class="py-2 px-3 text-black rounded-lg border-2 border-color1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
							name="id_tipo_periodo"  
							id="id_tipo_periodo" 
							onChange={this.change_periodoSeleccionado}
							required>
								{
								this.state.tipo_periodos.map(
									tip_periodos=>
										<option value={tip_periodos.tipo_periodo_id}>{tip_periodos.Tipo_Periodo}</option>
								)
							}
						</select>
					</div> <br/>

							{
								this.state.periodos.map(
									periodo=>
									<div>												
										<div className="grid grid-cols-3 md:grid-cols-3 sm:grid-cols-3 justify-items-center">                           
											<div>
												<label className=" text-light font-semibold text-lg">
												Fecha inicio del periodo 
												</label> 
												<p class="mb-2 font-semibold text-secondary text-lg">{periodo.Fecha_Inicio}</p>
											</div>
											
											<div>
												<label className="text-lg text-light font-semibold text-lg">
													Fecha fin del periodo 
													</label>
													<p class="mb-2 font-semibold text-secondary text-lg">{periodo.Fecha_Fin}</p>
													
											</div>
										
											<div align="center" className="text-red-500 font-bold"> 
												<button onClick={this.CerrarPeriodo} class=" bg-color1 inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start py-2 px-4  bg-white hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
													<p className="mt-2 text-sm font-medium leading-none text-white">Finalizar periodo</p> 
												</button> 
												<NavLink to="/app/AgregarPeriodo" className="bg-color1 inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-white hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110"> 
													<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
													<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"/></svg>
														<p className="mt-2 text-sm font-medium leading-none text-white">Nuevo periodo</p>  
												</NavLink>  
											</div>
										</div><br/>

										<hr/><hr/><br/>

										<div className="grid grid-cols-1 md:grid-cols-1 gap-1 md:gap-1 ">
												{
													periodo.parciales.map(
													parcial=>
													<div className=" flex justify-center "  >
														<div className=" rounded-2xl shadow-sm2 grid grid-cols-5 h-auto py-3 bg-secondary_2 mt-2 text-white ">
															<div>
																<p class="text-center font-semibold pl-5 text-sm ">{parcial.nombre}</p>
															</div>
															<div>
																<p class=" text-center pl-5 text-sm ">{this.CambiarFormato(parcial.fecha_inicio)}</p>
															</div>
														
															<div >
																<p class=" text-center pl-5 text-sm ">{this.CambiarFormato(parcial.fecha_fin)}</p>
															</div>
															<div className="col-span-2 flex justify-center items-center rounded-full"  >
																<div className={'' + this.estado(parcial.fecha_inicio, parcial.fecha_fin ) === 'Activo' ? 'bg-activo rounded-full px-3 py-1' :this.estado(parcial.fecha_inicio, parcial.fecha_fin ) === 'Inactivo' ? 'bg-inactivo rounded-full px-3 py-1' : 'bg-activo rounded-full px-3 py-1'}>
																	<h1 className='text-white text-center uppercase tracking-wide'>{this.estado(parcial.fecha_inicio, parcial.fecha_fin )}</h1>
																</div>
															</div>
														</div>
													</div>
													
												)
												}
											
										</div>
								</div>								
								)
							}

				

				{this.state.modalUpdate ? (
					<>
						<form onSubmit={this.ActualizarDatos}>
							<div div class="overflow-y-auto h-32">
								<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__zoomIn'>
									<div className='relative w-auto my-6 mx-auto max-w-3xl'>
										{/*content*/}
										<div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
											{/*header*/}
											<div className='flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t'>
												<p class='text-blue-900 text-3xl '>Editar fecha</p>
											</div>
											{/*body*/}
							
												<div className="md:px-20 md:py-3 lg:px-30 lg:py-10 2xl:px-60">
													<div className="grid grid-cols-2 md:grid-cols-2 sm:grid-cols-2  justify-items-center">    
														<div >
															<label className="md:text-sm text-xs text-gray-500 text-light font-semibold">
																{"Fecha inicio del "+this.state.nombre} <span className="text-red-500 text-xl"></span><br/>
															</label>
															<input
																className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
																type="date"
																name="fecha_inicio_parcial"
																value={this.state.fecha_inicio}
																onChange={(event=>this.setState({fecha_inicio:event.target.value}))}
																required
															/>
														</div>
														
														<div >
															<label className="ml-10 md:text-sm text-xs text-gray-500 text-light font-semibold">
															{"Fecha fin del "+this.state.nombre} <span className="text-red-500 text-xl"></span><br/>
															</label>
															<input
																className="ml-10 mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
																type="date"
																name="fecha_fin_parcial"
																value={this.state.fecha_fin}
																onChange={(event=>this.setState({fecha_fin:event.target.value}))}
																required
															/>
														</div>
													</div>
												</div>
											{/*footer*/}
											<div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
												<button
													className="text-red-500 background-transparent font-bold  px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
													type="button"
													onClick={()=>this.setState({modalUpdate:false})}
												>
													Cerrar
												</button>
												<button
													className="bg-emerald-500 text-black active:bg-gray-600 font-bold  text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
													type='submit' >
													Cambiar
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
							</div>
						</form>
				
					</>
				) : null}
			</div>
			
        )
    }
}