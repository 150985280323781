import { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import GestorPagoService from "services/GestorPagoService";
import swal from 'sweetalert2';
import AguilaCaida from "../images/aguila_caida.svg"
import maternal from "../images/maternal.png"
import preescolar from "../images/preescolar.png"
import primaria from "../images/primaria.png"
import secundaria from "../images/secundaria.png"
import bachillerato from "../images/bachillerato.png"
import universidad from "../images/universidad.png"
import diplomado from "../images/diplomado.png"


 class PlantelesAsignaturas extends Component{
    constructor(props){
        super(props)
        this.state = {
            planteles:[]
        }
    }

    componentDidMount(){
        sessionStorage.setItem("plantel_id", 0)
        sessionStorage.setItem("nombre_plantel", "")
        
        GestorPagoService.ListarPlanteles().then((res) => {
            if (res.data == 0) {
                swal.fire({
                    imageUrl: AguilaCaida,
                    title: 'Error',
                    text: 'No hay planteles',
                });
                this.goBack();
            }
            else {
                console.log("planteles ", res.data)
                this.setState({ planteles: res.data });
            
            }
        })
    }

    MostrarAsignaturas = async(id_plantel, nombre_plantel)=>{
        console.log("clic "+nombre_plantel)
        //window.$plantel_id=id_plantel
        //window.$nombre_plantel=nombre_plantel
        sessionStorage.setItem("plantel_id", id_plantel)
        sessionStorage.setItem("nombre_plantel", nombre_plantel)

        if(nombre_plantel === "UNIVERSIDAD"){
            this.props.history.push("/app/ListaEspecialidades")
        }
        else{
            this.props.history.push("/app/ListaAsignaturas")
        }
    }

    render(){
        return(
         <>
            <div>
                <div className='relative p-4 sm:p-6 rounded-sm overflow-hidden mb-4'>
                    <div className='relative text-center'>
                        <h1 className='text-2xl md:text-3xl text-white font-bold mb-1 text-center'>
                           SELECCIONE EL PLANTEL
                        </h1>
                    </div>
                </div>
            </div>

            <div className="flex justify-start mx-10 mt-4">
                <nav class="flex" aria-label="Breadcrumb">
                    <ol class="inline-flex items-center space-x-1 md:space-x-3">
                        <li class="inline-flex items-center">
                            <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                Home
                            </a>
                        </li>

                        <li aria-current="page">
                            <div class="flex items-center">
                                <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">gestión pagos</span>
                            </div>
                        </li>
                    </ol>
                </nav>
            </div>

                <div class=' px-2 md:px-10 pt-4 md:pt-3  overflow-y-auto'>
                    <div class='grid grid-cols-3 gap-5'>
                        
                        {
                            this.state.planteles.map(
                                plantel =>
                                    <div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
                                    
                                        <div class='flex justify-center'>
                                            {
                                                plantel.Nombre === "MATERNAL" ?  <img
                                                src={maternal}
                                                class='w-8 h-8 lg:w-16 lg:h-16'
                                                alt=''></img>  :
                                                plantel.Nombre === "PREESCOLAR" ? <img
                                                src={preescolar}
                                                class='w-8 h-8 lg:w-16 lg:h-16'
                                                alt=''></img> :
                                                plantel.Nombre === "PRIMARIA" ? <img
                                                src={primaria}
                                                class='w-8 h-8 lg:w-16 lg:h-16'
                                                alt=''></img> :
                                                plantel.Nombre === "SECUNDARIA" ? <img
                                                src={secundaria}
                                                class='w-8 h-8 lg:w-16 lg:h-16'
                                                alt=''></img> :
                                                plantel.Nombre === "BACHILLERATO" ? <img
                                                src={bachillerato}
                                                class='w-8 h-8 lg:w-16 lg:h-16'
                                                alt=''></img> :
                                                plantel.Nombre === "UNIVERSIDAD" ? <img
                                                src={universidad}
                                                class='w-8 h-8 lg:w-16 lg:h-16'
                                                alt=''></img> :
                                                plantel.Nombre === "DIPLOMADOS" ? <img
                                                src={diplomado}
                                                class='w-8 h-8 lg:w-16 lg:h-16'
                                                alt=''></img> : " "
                                            }
                                           
                                        </div>                                         
                                    
                                        <div className=' flex justify-center  pt-3'>
                                        <button
                                            className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '
                                            onClick={()=>this.MostrarAsignaturas(plantel.ID_Plantel, plantel.Nombre)}>
                                                    <h1 class='text-gray-500 text-xl sm:text-xl font-barlow tracking-widest text-white'>{plantel.Nombre} </h1>
                                        </button>
                                        </div>
                                    </div>
                            )
                        }
                    </div>
                </div>

         </>
      
      
        )
    }
}
export default withRouter(PlantelesAsignaturas)