import axios from "axios"

import { APIURL } from "./Host"

class AgenteVentaService {
	insertNuevoAgente(agente) {
		//console.log("usuario a agregar ", agente)
		return axios.post(APIURL + "/agente/insert", agente)
	}

	Total_AgenteVentas() {
		return axios.get(APIURL + "/total_agenteventas")
	}

	ListaAgentesV(){
		return axios.get(APIURL+"/lista_agente_ventas");
	}
	ListaAgentesCeeas(){
		return axios.get(APIURL+"/lista_agente_ventas_ceeas");
	}
	AgenteById(id_agente){
		return axios.get(APIURL+"/lista_by_agente_ventas/"+id_agente);
	}
	updateAgenteVentas(id_agente, agente) {
		//console.log("datos a actualizar ", id_agente)
		return axios.put(APIURL + "/agente_ventas_update/" + id_agente, agente)
	}
	ListaPuestos(){
        return axios.get(APIURL+'/puestos_agente_venta');
    }

	BusquedaAgente(txtbuscar){
		return axios.get(APIURL + "/busqueda_agente_ventas/"+txtbuscar);
	}
	
}
export default new AgenteVentaService()
