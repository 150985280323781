import axios from "axios"

import { APIURL } from "./Host"

class Puestos_AdmonService {
	listaPuestos(rol) {
		//console.log(rol)
		return axios.get(APIURL + `/puesto-admon/lista?rol=${rol}`)
	}
}

export default new Puestos_AdmonService()
