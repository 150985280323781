import axios from 'axios'; 

import {APIURL} from "./Host";

class CalificacionesService{
    /*ListaCalificaciones(id_especialidad, id_asignatura){
        return axios.get(APIURL+"/calificaciones");
    }*/
    ListaCalificaciones(id_asignatura,id_periodo){
        return axios.get(APIURL+"/calificaciones/"+id_asignatura+"/"+id_periodo);
    }

    CalificacionesByDocente(id_docente){
        return axios.get(APIURL+"/calificaciones_docente/"+id_docente);
    }
    CalificacionesActuales(id_asignatura){
        return axios.get(APIURL+"/calificaciones_actual/"+id_asignatura);
    }
}
export default new CalificacionesService();






















