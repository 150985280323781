import axios from "axios"
import { APIURL } from "./Host"

class UsuariosService {
	listaUsuario() {
		return axios.get(APIURL + "/usuarios")
	}

	registrarUsuario(usuario) {
		return axios.post(APIURL + "/usuarios", usuario)
	}

	registrarNuevoUsuario(usuario) {
		console.log("imprime los datos del usuario ",usuario)
		return axios.post(APIURL + "/usuario/insertar", usuario)
	}

	listarRoles() {
		return axios.get(APIURL + `/roles`)
	}

	RecuperarFoto(id_usuario) {
		return axios.get(APIURL + "/foto/" + id_usuario)
	}

	UpdateStatus(id_usuario, status) {
		return axios.put(APIURL + "/update_status/" + id_usuario + "/" + status)
	}

	PerfilUsuario(id_usuario) {
		return axios.get(APIURL + "/perfil_usuario/" + id_usuario)
	}

	CambiarFotoPerfil(base64){
        return axios.put(APIURL+"/editar_foto_perfil",base64);
    }

	EliminarFotoPerfil(id){
		return axios.get(APIURL+"/eliminar_foto_perfil/"+id);
	}

	VerificarCorreoRegistrado(correo) {
		return axios.get(APIURL + "/verificar_correo_registrado/"+correo);
	}

	VerificarCorreoRegistradoAct(id, correo) {
		return axios.get(APIURL + "/verificar_correo_registrado_act/"+id+"/"+correo);
	}

	ListaGradoAcademicoDocente(){
		return axios.get(APIURL + "/lista_grado_academico_docente");
	}

	ListaPerfilProfesionalDocente(){
		return axios.get(APIURL + "/perfil_profesional_docente");
	}

	ListaCuatrimestreAlumno(){
		return axios.get(APIURL + "/cuatrimestre_alumno");
	}

	ListaSistemaEstudiosProspecto(){
		return axios.get(APIURL + "/sistema_estudios_prospectos");
	}

	ListaStatusUsuario(){
		return axios.get(APIURL + "/status_usuario_admin");
	}

	ObtenerDatosUsuarioPerfil(id){
		return axios.get(APIURL + "/obtener_datos_usuario_perfil/" + id)
	}

	UpdateDatosUsuarioAdmin(usuario){
		return axios.put(APIURL + "/update_datos_usuario_admin", usuario)
	}

	RecuperarCuentaUsuario(correo){
		return axios.get(APIURL + "/recuperar_cuenta_usuario/" + correo)
	}

	listaPlanteles() {
		return axios.get(APIURL + `/lista_planteles`)
	}
	listaPlantelesIEA() {
		console.log("lamando planteles IEA")
		return axios.post(APIURL + `/lista_plantelesIEA`)
	}
	obtenerCorreoUsuarioSession(id){
		return axios.get(APIURL + "/obtener_correo_usuario_session/" + id)
	}

	agregarCorreoElectronicoUsuario(datos) {
		return axios.post(APIURL + "/agregar_correo_electronico_usuario", datos)
	}



}
export default new UsuariosService()