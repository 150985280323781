import { Component } from "react";
import { withRouter } from "react-router";
import TutorService from "services/TutorService";
import { Link } from "react-router-dom";
import swal from 'sweetalert2';


class PagoActualTutor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nombrecompleto: "",
            tutorados: [],
            periodos: [],
            id_alumnouser: "",
            id_periodo: "",
            pagos: [],
            vacio: true,
        }
        this.goBack = this.goBack.bind(this);
    }


    goBack() { this.props.history.goBack(); }

    componentDidMount() {
        let usuario_id = sessionStorage.getItem('id');
        let status_pago = 0;
        TutorService.MisTutorados(status_pago,usuario_id).then((res) => {
            console.log("MISPAGOSPERIODOACTUAL", res.data);
            if (res.data == 0) {
                this.setState({ vacio: true });
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No tiene pagos pendientes',
                });
                this.goBack();
            } else {
                this.setState({ vacio: false });
                this.setState({ tutorados: res.data });
            }

        })
    }


    CambiarFormato = (fecha)=>{
		var f =new Date(fecha);
		var anio=f.getFullYear();
		var mes=f.getMonth() + 1;
		var dia=f.getDate();

        var date;
        if(mes>=10 && dia>=10){
            date = dia + '-' + (mes) + '-' + anio;  
        }
        else if(mes<10 && dia<10){
            date =  '0' + dia  + '-0' + (mes) + '-' +  anio ; 
        }
        else if(mes>=10 && dia<10){
            date = '0' +dia  + '-' + (mes) + '-' + anio ; 
        }
        else if(mes<10 && dia>=10){
            date = dia + '-0' + (mes) + '-' + anio;    
        }
        return date;
    }

    PagoActual = async(id_alu_user, id_periodo)=>{
        sessionStorage.setItem("id_a_u",id_alu_user )
        sessionStorage.setItem("id_p",id_periodo )
        this.props.history.push("/app/PagoActual")
    }


    render() {
        return (
            <>
                <div>

                    <div className="flex justify-end mx-10 mt-4">

                        <nav class="flex" aria-label="Breadcrumb">
                            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                <li class="inline-flex items-center">
                                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-200" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a onClick={this.goBack} class="ml-1 text-sm font-medium text-white hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">pagos</a>
                                    </div>
                                </li>
                                <li aria-current="page">
                                    <div class="flex items-center">
                                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">actual</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="relative p-4 sm:p-6 rounded-sm overflow-hidden mb-2">
                        <div className="relative text-center">
                            <h1 className="text-2xl uppercase md:text-3xl text-white font-bold mb-1 text-center">
                                mensualidades
                            </h1>
                        </div>
                    </div>



                    <div className="mx-10 my-4 overflow-x-auto flex">

                        <table className="w-full whitespace-nowrap my_table">
                            <thead className="h-10">
                                <tr>
                                    <th className="border border-slate-300">Nombre</th>

                                    <th className="border border-slate-300">Matricula</th>
                                    <th className="border border-slate-300">Nivel</th>
                                    <th className="border border-slate-300">Periodo</th>
                                    <th className="border border-slate-300">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.tutorados.map(
                                        (alumno, index) =>
                                            <tr className="h-20 text-sm leading-none text-gray-800 group border border-slate-300">

                                                <td className="pl-2 text-left ">
                                                    <p className="font-medium ">{alumno.Nombre + " " + alumno.App + " " + alumno.Apm}</p>
                                                </td>
                                                <td className="pl-2 text-left ">
                                                    <p className="font-medium ">{alumno.matricula}</p>
                                                </td>
                                                <td className="pl-2 text-left ">
                                                    <p className="font-medium ">{alumno.Plantel}</p>
                                                </td>
                                                  <td className="pl-2 text-left">
                                                    <p className="font-medium">{this.CambiarFormato(alumno.fecha_inicio) +"  /  "+this.CambiarFormato( alumno.fecha_fin)}</p>
                                                </td>

                                                <td className="pl-2 text-left">
                                                    <div className="grid grid-cols-1 content-center">
                                                        <button className="miboton" onClick={()=>this.PagoActual(alumno.id_alumno_usuario, alumno.id_periodo)}>
                                                        <p className="text-sm font-medium leading-none"><i class="fas fa-calendar-alt"></i>  Ver mensualidades</p>
                                                    </button>
                                                    </div>
                                                </td>
                                            </tr>
                                    )

                                }


                            </tbody>
                            {this.state.vacio ? (
                                <>
                                    <tr>
                                        <th scope="col" colspan="6" class="px-6 py-3 text-center">
                                            <div class="flex p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                                                <svg class="inline flex-shrink-0 mr-3 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                                                <div>
                                                    Sin tutorados.
                                                </div>
                                            </div>
                                        </th>
                                    </tr>

                                </>
                            ) : null}

                        </table>



                    </div>

                </div>


            </>
        );
    }

}

export default withRouter(PagoActualTutor);