import axios from 'axios'; 
import {APIURL} from "./Host";

class GradoAcademicoService{

    ListaGradoAcademico(){
        return axios.get(APIURL+"/grados_academicos");
    }
    
}

export default new GradoAcademicoService();