import axios from "axios"

import { APIURL } from "./Host"

class TutorService {
    MisTutorados(status_pago,usuario_id) {
		return axios.get(APIURL + "/Mis_Tutorados/" + status_pago + "/" + usuario_id)
	}

    MisTutoradosDetalle(id_tutorado) {
		return axios.get(APIURL + "/Mis_Tutorados_Deatlles/" + id_tutorado)
	}

	crear_pago(datas) {
		return axios.post(APIURL + "/crear_pago_tutor", datas)
	}
	
	DetallesPagoTutor(id_tutorado){
		return axios.get(APIURL+"/Detalles_PagoTutor/"+id_tutorado)
	}

}  
  
export default new TutorService()
