import { Component } from "react"
import { NavLink } from 'react-router-dom';
import AgenteVentaService from "services/AgenteVentaService";
import DocentesService from "services/DocentesService";
import UsuariosService from "services/UsuariosService";
import CatalogoDireccionService from "services/CatalogoDireccionService";
import swal from 'sweetalert2/dist/sweetalert2.js';
import { withRouter } from "react-router-dom";
import close from '../images/cancel.svg'

class ListaAgenteVentas extends Component{
        constructor(props){
            super(props);
            this.state={
                agenteVentas:[],
                listaStatus:[],

                id_usuario:'',
                nombre:'',
                app_paterno:'',
                app_materno:'',
                curp:'',
                rfc:'',
                fecha_nacimiento:'',
                nac_estado:'',
                nac_municipio:'',
                nac_localidad:'',
                nombre_localidad:'',
                nombre_municipio:'',
                nombre_estado:'',
                sexo:'',
                nacionalidad:'',
                correo_electronico:'',
                telefono1:'',
                telefono2:'',
                status_id:'',
                status:'',
                id_agente:'',
                clave_agente:'',
                pusto_agente:'',
                modaldetalle:false,
				modalActualizar:false,
                porcentaje_comision:0,

				Estados: [],
				Municipios: [],
				Localidades: [],
            }
            this.ModalDetalles=this.ModalDetalles.bind(this);
			this.ModalActualizar=this.ModalActualizar.bind(this);
			this.AgenteById=this.AgenteById.bind(this);
			this.ActualizarDatos=this.ActualizarDatos.bind(this);

			this.ListaEstado=this.ListaEstado.bind(this);
			this.ListaMunicipios=this.ListaMunicipios.bind(this);
			this.ListaLocalidades=this.ListaLocalidades.bind(this);

			this.change_estado=this.change_estado.bind(this);
			this.change_municipio=this.change_municipio.bind(this);
			this.change_localidad=this.change_localidad.bind(this);
			this.changeStatus=this.changeStatus.bind(this);
            this.change_texto_buscar=this.change_texto_buscar.bind(this);
        }


		change_estado=(event)=>{ 
			this.setState({nac_estado:event.target.value});
			this.ListaMunicipios(this.state.nac_estado);
		}
	
		change_municipio=(event)=>{ 
			this.setState({nac_municipio:event.target.value});
			this.ListaLocalidades(this.state.nac_estado,this.state.nac_municipio);
		}
	
		change_localidad=(event)=>{this.setState({nac_localidad:event.target.value});}
	
		changeStatus=(id_usuario, status)=>{
			swal.fire({
				imageUrl:"https://multimedia-uea-metaverso.netlify.app/assets/uea19.6bc028c7.webp",
			    imageHeight:'150',
				text:'Estas seguro de cambiar el status del agente de ventas ?',
				showCancelButton: true,
				confirmButtonText: 'Cambiar',
				denyButtonText: `Cancelar`,
				confirmButtonColor: '#18253f',
				cancelButtonColor: '#9b8818',
			  }).then((result) => {
				if (result.isConfirmed) {
					swal.fire('Cambiado', '', 'success')
					let EstadoCambiado = document.getElementById(status).value;
					console.log("status seleccionado "+EstadoCambiado);
					UsuariosService.UpdateStatus(id_usuario,EstadoCambiado ).then((res) =>{
						this.componentDidMount();
					})
				} 
			})
		}

        change_texto_buscar=(event)=>{
            var text=event.target.value;
            if(text==""){
                this.componentDidMount();
            }
            this.setState({txtBuscar:event.target.value})
        }


		ListaEstado(){
			CatalogoDireccionService.getEstados().then(res => {
				this.setState({ Estados: res.data })
			})
		}
		
		ListaMunicipios(id){
			CatalogoDireccionService.getMunicipios(id).then(res => {
				this.setState({Municipios: res.data })
			})
		}
	
		ListaLocalidades(id_estado, id_municipio){
			CatalogoDireccionService.getLocalidades(id_estado, id_municipio).then((res) => {
				this.setState({ Localidades: res.data })
			})
		}

        componentDidMount(){
            AgenteVentaService.ListaAgentesV().then((res)=>{
                //console.log("lsita de agente de ventas ", res.data);
                if(res.data.length>0){
                    this.setState({agenteVentas:res.data});
                    DocentesService.ListaStatus().then((res)=>{
                        this.setState({listaStatus:res.data});
                    })
                }
                else{
                    swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'No hay registros de agente de ventas',
                    });
                }
            })
        }

		ModalActualizar(id_agenteVenta){
			this.ListaEstado();
			this.AgenteById(id_agenteVenta);
			
			this.setState({modalActualizar:!this.state.modalActualizar});
		}

        ModalDetalles(id_agenteVenta){
         this.AgenteById(id_agenteVenta);
		 this.setState({modaldetalle:!this.state.modaldetalle});
        }

		AgenteById(id_agenteVenta){
			AgenteVentaService.AgenteById(id_agenteVenta).then((res)=>{
                //console.log("agente ventas ", res.data);
                let agente=res.data;
                this.setState({
                    id_usuario:agente.id_usuario,
                    nombre:agente.nombre,
                    app_paterno:agente.app_paterno,
                    app_materno:agente.app_materno,
                    curp:agente.curp,
                    rfc:agente.rfc,
                    fecha_nacimiento:agente.fecha_nacimiento,
                    nac_estado:agente.nac_estado,
                    nac_municipio:agente.nac_municipio,
                    nac_localidad:agente.nac_localidad,
                    nombre_localidad:agente.nombre_localidad,
                    nombre_municipio:agente.nombre_municipio,
                    nombre_estado:agente.nombre_estado,
                    sexo:agente.sexo,
                    nacionalidad:agente.nacionalidad,
                    correo_electronico:agente.correo_electronico,
                    telefono1:agente.telefono1,
                    telefono2:agente.telefono2,
                    status_id:agente.status_id,
                    status:agente.status,
                    id_agente:agente.id_agente,
                    clave_agente:agente.clave_agente,
                    puesto_agente:agente.puesto_agente,
                    porcentaje_comision:agente.porcentaje_comision,
                })
				this.ListaMunicipios(this.state.nac_estado);
				this.ListaLocalidades(this.state.nac_estado, this.state.nac_municipio);
            })
		}

		ActualizarDatos =(event)=>{
			event.preventDefault();
			let ActualizarDatos={
				nombre:this.state.nombre,
				app_paterno:this.state.app_paterno,
				app_materno: this.state.app_materno,
				curp:this.state.curp,
				rfc:this.state.rfc,
				fecha_nacimiento:this.state.fecha_nacimiento,
				nac_estado:this.state.nac_estado,
				nac_municipio:this.state.nac_municipio,
				nac_localidad:this.state.nac_localidad,
				sexo:this.state.sexo,
				nacionalidad:this.state.nacionalidad,
				correo_electronico:this.state.correo_electronico,
				telefono1:this.state.telefono1,
				telefono2:this.state.telefono2,
				clave_agente:this.state.clave_agente,
				puesto_agente:this.state.puesto_agente,
                porcentaje_comision:this.state.porcentaje_comision,
			};
			console.log("datos a actualizar ", ActualizarDatos );
			AgenteVentaService.updateAgenteVentas(this.state.id_agente, ActualizarDatos).then((res)=>{
					if(res.data===1){
						swal.fire({
							icon: 'success',
							title: 'Success',
							text: 'Datos actualizados correctamente',
						});
						this.setState({modalActualizar:!this.state.modalActualizar});
						this.componentDidMount();
					}
					else{
						swal.fire({
							icon: 'error',
							title: 'Error',
							text: 'Error al actualizar los datos',
						});
					}
			});
		}


        goBack=()=> {
            this.props.history.push("/app/ListaUsuarios");
        }
	

        actualizarDatos=(id_agente)=>{
            this.setState({modaldetalle:!this.state.modaldetalle});
            this.ModalActualizar(id_agente);
        }

        busqueda=()=>{
            var txt=document.getElementById("txtbuscar").value;
            if(txt!=""){
                if(txt=='activo'){
                    txt='A';
                }
                else if(txt=='inactivo'){
                    txt='I';
                }
                AgenteVentaService.BusquedaAgente(txt).then((res)=>{
                    this.setState({agenteVentas:res.data});
                })
            }
    
        }

        cambiarStatus(txt){
            if(txt==='A'){
                return "ACTIVO"
            }
            else  if(txt==='I'){
                return "INACTIVO"
            }
        }

        render(){
            return(
                <>
                  <div className="bg-primary_2 text-white h-auto pb-20">

                    <div className='relative bg-primary_2 p-4 sm:p-6 rounded-sm overflow-hidden mb-8'>
						<div className='relative text-center'>
							<h1 className='text-2xl md:text-3xl text-white  font-bold mb-1 text-center'>
                            Gestión de agente de ventas
							</h1>
						</div>
					</div>
                 
						<div className="col-span-5 lg:col-span-6 w-full flex items-center">
                            <nav class="flex relative" aria-label="Breadcrumb">
                                <ol class="flex items-center pl-10">
                                    <li class="inline-flex items-center">
                                        <a href="/" class="inline-flex items-center text-sm dark:text-white group">
                                            <svg class="mr-2 w-4 h-4 text-white group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                            <label className="font-barlow text-white tracking-widest group-hover:text-white">Home</label>
                                        </a>
                                    </li>
                                    <li>
                                        <div class="flex items-center text-white ">
                                            <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                            <a onClick={this.goBack} class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Usuarios</a>
                                        </div>
                                    </li>
                                    <li aria-current="page">
                                        <div class="flex items-center">
                                            <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                            <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Gestión de agente de ventas</span>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
						</div>

                        <div className="col-span-2">
                            <div className="flex justify-start ">
                                <div class="xl:w-96 mt-8">
                                    <div class="ml-10 input-group relative flex flex-wrap items-stretch w-full mb-4 ">
                                    <input type="search" 
                                        class="uppercase form-control relative flex-auto px-3 py-1.5 text-base font-normal text-black bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none" 
                                        placeholder="Search" 
                                        aria-label="Search" 
                                        id="txtbuscar"
                                        name="txtbuscar"
                                        onKeyPress={this.busqueda}
                                        onChange={this.change_texto_buscar}
                                        aria-describedby="button-addon2"/>

                                        <button 
                                        onClick={this.busqueda}
                                        ><svg class="h-6 w-6 text-gray-500 ml-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-row-reverse mr-10'>
                                <NavLink to={{
                                    pathname: '/app/FormUsuario',
                                    state: { idTipoUsuarioSelect: 6 }
                                    }} rel="noopener noreferrer"
                                    className='inline-flex items-center justify-center h-10 px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 mt-2 ml-4'>
                                    <p className='text-sm font-medium leading-none text-white'>
                                        <i class='fas fa-plus-circle'></i> Agregar agente
                                    </p>
                                </NavLink>
                            </div>
                        </div>

                    <div className="px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto" >
                        <div className="w-full " id="Tabla_alumnos" >
                            <div>
                                <div className="w-full text-sm grid grid-cols-11 text-gray-800 font-semibold">
                                    <div className="font-semibold text-center pl-1 col-span-3 px-6 py-3">
                                        <label className="text-lg">Nombre Completo</label><br/>
                                    </div>
                                    <div className="font-semibold text-center pl-1 col-span-2 px-6 py-3">
                                        <label className="text-lg">Clave_Agente</label><br/>
                                    </div>
                                    <div className="font-semibold text-center pl-1 col-span-2 px-6 py-3">
                                        <label className="text-lg">Puesto</label><br/>
                                    </div>
                                    <div className="font-semibold text-center pl-1 col-span-2 px-6 py-3">
                                        <label className="text-lg">Status</label><br/>
                                    </div>

                                    <div className="font-semibold text-center pl-1 col-span-2 px-6 py-3">
                                        <label className="text-lg">Mostrar</label>
                                    </div>
                                </div>
                            </div>
                            <div>
							{
								this.state.agenteVentas.map(
									agenteVenta =>
                                    <div className="w-full flex justify-center items-center">
                                        <div className="mt-5 rounded-2xl w-10/12 md:w-full lg:grid grid-cols-11 h-auto px-5 py-5 bg-secondary_2 relative">
                                            <div className="col-span-3 flex justify-start items-center">
												<h1 className="text-white text-left pl-5 text-lg">{agenteVenta.nombre + " "}{agenteVenta.app_paterno + " "}{agenteVenta.app_materno}</h1>
											</div>

											<div className="col-span-2 flex justify-start items-center">
												<h1 className="text-white text-left pl-5 text-lg">{agenteVenta.clave_agente}</h1>
											</div>

											<div className="col-span-2 flex justify-start items-center">
												<h1 className="text-white text-left pl-5 text-lg">{agenteVenta.puesto_agente}</h1>
											</div>

                                            <div className="col-span-2 flex justify-center items-center rounded-full" >
												<div className={'' + agenteVenta.status === 'A' ? 'bg-activo rounded-full px-3 py-1' : agenteVenta.status === 'I' ? 'bg-inactivo rounded-full px-3 py-1' : 'bg-activo rounded-full px-3 py-1'}>
													<h1 className='text-white text-center uppercase tracking-wide'>{this.cambiarStatus(agenteVenta.status)}</h1>
												</div>

                                                <div className="col-span-2 flex justify-center items-center gap-2" hidden={this.state.ocultar}>
                                                    <select class="flex items-end py-1 px-2 rounded-lg border-2 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary text-secondary focus:border-transparent cursor-pointer transition duration-300 ease-in-out shadow-inner2"
                                                        name={"status" + agenteVenta.id_usuario}
                                                        id={"status" +  agenteVenta.id_usuario}
                                                        onChange={()=>this.changeStatus(agenteVenta.id_usuario,"status"+ agenteVenta.id_usuario)}
                                                        required>
                                                        {this.state.listaStatus.map(
                                                            status => (
                                                            <option value={status.id_status}> {status.status} </option>
                                                        ))}
                                                    </select><br/>											
                                                </div>
											</div>

											
											<div className="flex justify-center items-center col-span-2">
												<div class="flex justify-center items-center">
													<div class="mt-2 ml-6 transform hover:text-purple-500 hover:scale-110">
														<button onClick={() => this.ModalDetalles(agenteVenta.id_agente)} title="Detalles agente de ventas">
															<svg class="h-7 w-7 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
														</button>
													</div>

													<div class="mt-2 ml-2 transform hover:text-purple-500 hover:scale-110">
														<button  onClick={() => this.ModalActualizar(agenteVenta.id_agente,)} title="Detalles agente de ventas">
															<svg class="h-6 w-6 text-white" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
														</button>
													</div>
												</div>
											</div>
                                        </div>  
									</div>
                                    )
                                }
                            </div>

                        </div>
                    </div>

                    {this.state.modaldetalle ? (
					<>
					<div div class="overflow-y-auto h-32">
							<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
								<div className='relative w-auto my-6 mx-auto max-w-3xl'>
									{/*content*/}
									<div className='rounded-3xl bg-primary_2 relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
										{/*header*/}
										<div className=' rounded-t-3xl'>
                                            <div className='flex items-center justify-end p-1 absolute -top-3 -right-3'>
												<button
													className='text-black-500 background-transparent font-bold uppercase px-3 py-3 rounded-full text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 bg-red-500'
													type='button'
													onClick={() => this.setState({modaldetalle:false})}>
													<img src={close} className="w-6" />
												</button>
											</div>
											<p class='text-white text-3xl text-center my-5 font-bold uppercase font-barlow tracking-widest'>Detalles</p>
										</div>
										{/*body*/}

                                        <div className="flex justify-end mx-10 ">
											<nav class="flex" aria-label="Breadcrumb">
												<ol class="inline-flex items-center space-x-1 md:space-x-3">
												<button onClick={()=>this.actualizarDatos(this.state.id_agente)} class="bg-secondary hover:bg-primary text-white font-bold py-1 px-4 rounded-full">Editar agente de venta</button>
												
												</ol>
											</nav>
										</div>

										<div className='relative flex-auto'>
											<div class='grid grid-cols-1 gap-5 px-6 py-5 '>
												<div className="shadow-sm2 px-5 py-2 rounded-3xl bg-secondary_2">
													<p class='mb-5 font-bold text-white uppercase text-center text-lg font-barlow tracking-widest'>
														Datos generales
													</p>
													<div class='grid grid-cols-2 gap-4'>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>
																Nombre del usuario:{" "}
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow'>
																{this.state.nombre +
																	" " +
																	this.state.app_paterno +
																	" " +
																	this.state.app_materno}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>Curp:</p>
															<p class='mb-2 font-semibold text-secondary font-barlow '>
																{this.state.curp}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>Rfc:</p>
															<p class='mb-2 font-semibold text-secondary font-barlow '>
																{this.state.rfc}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>Sexo:</p>
															<p class='mb-2 font-semibold text-secondary font-barlow '>
																{this.state.sexo}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>
																Fecha de nacimiento:
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow '>
																{this.state.fecha_nacimiento}
															</p>
														</div>
													</div>
												</div>
												<div className="shadow-sm2 px-5 py-2 rounded-3xl bg-secondary_2">
													<p class='mb-2 font-bold text-gray-300 '>
														Lugar de nacimiento:
													</p>
													<br />
													<div class='grid grid-cols-3 gap-4'>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>
																Estado de nacimiento{" "}
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow '>
																{this.state.nombre_estado}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>
																Municipio de nacimiento{" "}
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow '>
																{this.state.nombre_municipio}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>
																Localidad de nacimiento{" "}
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow '>
																{this.state.nombre_localidad}
															</p>
														</div>
													</div>
												</div>
												<div className="shadow-sm2 px-5 py-2 rounded-3xl bg-secondary_2">
													<p class='mb-2 font-bold text-gray-300 '>Contactos:</p>
													<br />

													<div class='grid grid-cols-3 gap-4'>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>
																Correo electrónico
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow '>
																{this.state.correo_electronico}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>
																Número de teléfono 1
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow '>
																{this.state.telefono1}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>
																Número de teléfono 2
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow '>
																{this.state.telefono2}
															</p>
														</div>
													</div>
												</div>
												<div className="shadow-sm2 px-5 py-2 rounded-3xl bg-secondary_2">
													<br />
													<div class='grid grid-cols-3 gap-4'>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>
																Clave agente
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow '>
																{this.state.clave_agente}
															</p>
														</div>
														<div>
															<p class='mb-2 font-bold text-gray-300 font-barlow '>
																Puesto agente
															</p>
															<p class='mb-2 font-semibold text-secondary font-barlow '>
																{this.state.puesto_agente}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>					
									</div>
								</div>
                                <div onClick={() => this.setState({modaldetalle:false})} className='opacity-25 fixed inset-0 bg-black cursor-pointer'></div>
							</div>
						
						</div>
					</>
				) : null}


				{this.state.modalActualizar ?(
                        <>
                           	<form onSubmit={this.ActualizarDatos}>
                               <div div class="overflow-y-auto h-32">
                                    <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                        <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                            {/*content*/}
                                            <div className='rounded-3xl bg-primary_2 relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                                {/*header*/}
                                                <div className=' rounded-t-3xl'>
                                                    <p class='text-white text-3xl text-center my-5 font-bold uppercase tracking-widest'>Actualizar datos</p>
                                                </div>
                                                {/*body*/}

                                                <div className='relative flex-auto'>
                                                    <div class='grid grid-cols-1 gap-5 px-6 py-5 '>
                                                            <div className="grid grid-cols-3 gap-5 mt-5 bg-secondary_2 px-5 py-5 rounded-3xl">
                                                                <div >
                                                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                        Nombre(s)
                                                                        <span className='text-red-500 text-xl'>*</span>
                                                                    </label>
                                                                    <input
                                                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
                                                                        type='text'
                                                                        name='nombre'
                                                                        value={this.state.nombre}
                                                                        onChange={event => this.setState({nombre:event.target.value})}
                                                                        required
                                                                    />
                                                                </div>

                                                                <div >
                                                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                        Apellido paterno
                                                                        <span className='text-red-500 text-xl'></span>
                                                                    </label>
                                                                    <input
                                                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
                                                                        type='text'
                                                                        name='app_Paterno'
                                                                        value={this.state.app_paterno}
                                                                        onChange={event=>this.setState({app_paterno:event.target.value})}
                                                                    
                                                                    />
                                                                </div>

                                                                <div >
                                                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                        Apellido Materno
                                                                        <span className='text-red-500 text-xl'></span>
                                                                    </label>
                                                                    <input
                                                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
                                                                        type='text'
                                                                        name='app_Materno'
                                                                        maxLength='45'
                                                                        value={this.state.app_materno}
                                                                        onChange={event=>this.setState({app_materno:event.target.value})}
                                                                    
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                        Fecha de nacimiento
                                                                        <span className='text-red-500 text-xl'></span>
                                                                    </label>
                                                                    <input
                                                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2'
                                                                        type='date'
                                                                        name='fechaNacimiento'
                                                                        value={this.state.fecha_nacimiento}
                                                                        onChange={event=>this.setState({fecha_nacimiento:event.target.value})}
                                                                    
                                                                    />
                                                                </div>

                                                                <div>
                                                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                        Curp <span className='text-red-500 text-xl'></span>
                                                                    </label>
                                                                    <input
                                                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 '
                                                                        type='text'
                                                                        name='curp'
                                                                        maxLength='18'
                                                                        value={this.state.curp}
                                                                        onChange={event=>this.setState({curp:event.target.value})}
                                                                    />
                                                                </div>

                                                                <div>
                                                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                        Rfc <span className='text-red-500 text-xl'></span><br/>
                                                                    </label>
                                                                    <input
                                                                        className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 '
                                                                        type='text'
                                                                        name='rfc'
                                                                        maxLength='18'
                                                                        value={this.state.rfc}
                                                                        onChange={event=>this.setState({rfc:event.target.value})}
                                                                    />
                                                                </div>
                                                            </div>

                                                        <div className="grid grid-cols-3 gap-5 mt-5 bg-secondary_2 px-5 py-5 rounded-3xl">
                                                            <div >
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Nacionalidad
                                                                    <span className='text-red-500 text-xl'></span><br/>
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 '
                                                                    type='text'
                                                                    name='nacionalidad'
                                                                    value={this.state.nacionalidad}
                                                                    onChange={event=>({nacionalidad:event.target.value})}
                                                                />
                                                            </div>

                                                            <div >
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Sexo <span className='text-red-500 text-xl'></span><br/>
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 '
                                                                    type='text'
                                                                    name='sexo'
                                                                    value={this.state.sexo}
                                                                    onChange={event=>this.setState({sexo:event.target.value})}
                                                                />
                                                            </div>

                                                            <div>
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Estado de nacimiento
                                                                    <span className='text-red-500 text-xl'></span><br/>
                                                                </label>
                                                                <select
                                                                    class='py-2 w-full text-primary_2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent'
                                                                    value={this.state.nac_estado}
                                                                    onChange={this.change_estado}
                                                                    >
                                                                    {this.state.Estados.map(estado => (
                                                                        <option value={estado.id_Estado}>{estado.entidad_Federativa}</option>
                                                                    ))}
                                                                </select><br/>
                                                            </div>

                                                            <div>
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Municipio de nacimiento
                                                                    <span className='text-red-500 text-xl'></span><br/>
                                                                </label>
                                                                <select
                                                                    class='py-2 w-full text-primary_2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent'
                                                                    value={this.state.nac_municipio}
                                                                    onChange={this.change_municipio}
                                                                    >
                                                                    {this.state.Municipios.map(Municipio => (
                                                                        <option value={Municipio.c_mnpio}> {Municipio.nombre_Municipio} </option>
                                                                    ))}
                                                                </select><br/>
                                                            </div>

                                                            <div>
                                                                 <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Localidad de nacimiento
                                                                    <span className='text-red-500 text-xl'></span><br/>
                                                                </label>
                                                                <select
                                                                    class='py-2 w-full text-primary_2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent'
                                                                    name='id_variedad'
                                                                    id='id_variedad'
                                                                    value={this.state.nac_localidad}
                                                                    onChange={this.change_localidad}
                                                                    >
                                                                    {this.state.Localidades.map(Localidad => (
                                                                        <option value={Localidad.id_Localidad}>
                                                                            {Localidad.nombre}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div >
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Correo electrónico
                                                                    <span className='text-red-500 text-xl'></span><br/>
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 '
                                                                    type='text'
                                                                    name='correo'
                                                                    value={this.state.correo_electronico}
                                                                    onChange={event=>this.setState({correo_electronico:event.target.value})}
                                                                /><br/>
                                                            </div>

                                                            <div>
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Número telefónico 1
                                                                    <span className='text-red-500 text-xl'></span><br/>
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 '
                                                                    type='text'
                                                                    name='telefono1'
                                                                    value={this.state.telefono1}
                                                                    onChange={event=>this.setState({telefono1:event.target.value})}
                                                                /><br/>
                                                            </div>

                                                            <div>
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Número telefónico 2
                                                                    <span className='text-red-500 text-xl'></span><br/>
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 '
                                                                    type='text'
                                                                    name='telefono2'
                                                                    value={this.state.telefono2}
                                                                    onChange={event=>this.setState({telefono2:event.target.value})}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="grid grid-cols-3 gap-5 mt-5 bg-secondary_2 px-5 py-5 rounded-3xl">
                                                            <div>
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                Clave <span className='text-red-500 text-xl'></span>
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 '
                                                                    type='text'
                                                                    value={this.state.clave_agente}
                                                                    onChange={event=>this.setState({clave_agente:event.target.value})}
                                                                />
                                                            </div>
                                                            <div>
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Puesto<span className='text-red-500 text-xl'></span>
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 '
                                                                    type='text'
                                                                    value={this.state.puesto_agente}
                                                                    onChange={event=>this.setState({puesto_agente:event.target.value})}
                                                                />
                                                            </div>
                                                            <div>
                                                                <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                                    Porcentaje comisión<span className='text-red-500 text-xl'></span>
                                                                </label>
                                                                <input
                                                                    className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 '
                                                                    type='text'
                                                                    value={this.state.porcentaje_comision}
                                                                    onChange={event=>this.setState({porcentaje_comision:event.target.value})}
                                                                />
                                                            </div>
                                                        </div>
                                                   </div>
                                                </div>
                                                {/*footer*/}
                                                <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
                                                    <button
                                                        className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                        type='button'
                                                        onClick={() => this.setState({modalActualizar:false})}>
                                                        Cerrar
                                                    </button>
                                                    <button
														className='bg-emerald-500 text-white font-bold uppercase text-sm px-3 py-2  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 bg-normal'
														type='submit'>
														Actualizar
													</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div  onClick={() => this.setState({modalActualizar:false})} className='opacity-25 fixed inset-0 bg-black cursor-pointer'></div>

                                    </div>
                                </div>
						</form>
                        </>
                    ):null}
                  </div>
                </>
            )}
}

export default withRouter(ListaAgenteVentas);