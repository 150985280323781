import { Component } from "react";
import EspecialidadService from "services/EspecialidadService";
import AsignaturasService from "services/AsignaturasService";
import DocentesService from "services/DocentesService";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { NavLink } from 'react-router-dom';
import PeriodosService from "services/PeriodosService";
import AreaAsignaturaService from "services/AreaAsignaturaService";

export default class AsignacionMateriasDocente extends Component{
    constructor(props){
        super(props);
        this.state={
            id_plantel:sessionStorage.getItem("plantel_id"),
            nombre_plantel:sessionStorage.getItem("nombre_plantel"),
            id_tipo_periodo:"",
            id_periodo:"",
            nivel:"",
            tipo_periodo:"",
           
            especialidades:[],
            asignaturas:[],
            asignaturasTemp:[],
            asignaturasAsignadas:[],
            docentes:[],
            asig:[],
            areas:[],
            id_esp:'',
            id_docente:'',
            id_area:'',

            error:false,
            success:false,
            mensaje:'',

            nombreEspecialidad:'',
            nombreDocente:'',
            taller:false
        }
        this.change_especialidad=this.change_especialidad.bind(this);
        this.change_docente=this.change_docente.bind(this);
        this.AgregarAsignatura=this.AgregarAsignatura.bind(this);
        this.delete=this.delete.bind(this);
        this.Guardar=this.Guardar.bind(this);
        this.filtrarbynombre=this.filtrarbynombre.bind(this);
    }

    change_especialidad=(event)=>{
        var val = document.getElementById("id_especialidad");
        var NombreEspecialidad = val.options[val.selectedIndex].text;
        this.setState({success:false,nombreEspecialidad: NombreEspecialidad}); 
        console.log("eantra en cambiar especialidad "+this.state.id_esp)
		this.Asignaturas();
	}


    change_area = (event) => {
        let i = document.getElementById("id_area").value
        this.setState({id_area:i})
        this.AsignaturasByTallerArea()
    }

    change_docente=(event)=>{
        this.setState({id_docente:event.target.value});
        var valdoc = document.getElementById("id_docente");
        var NombreDocente = valdoc.options[valdoc.selectedIndex].text;
        this.setState({nombreDocente: NombreDocente});
    }

    componentDidMount(){
        console.log("nombre_plantel ==== >"+this.state.nombre_plantel+"id_plantel ==== >"+this.state.id_plantel+ "tipo plantel -.id ==== >"+this.state.id_tipo_periodo )
        if(this.state.nombre_plantel === "DIPLOMADOS"){
            this.setState({taller:true})
            AreaAsignaturaService.ListaAreas().then((response)=>{
                console.log("response de areeas ==== ",response.data)
                this.setState({areas:response.data})
                this.RecuperarDocentes()
            })   
        }
        else{
            PeriodosService.PeridoActivoByPlantel(this.state.id_plantel).then((response)=>{
                console.log("PeriodoActivoByPLantel ",response.data )
                if(response.data.Status=="error"){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'No esta activo el periodo que corresponde ',
                     });
                }
                else{
                    this.setState({
                        id_periodo:response.data.id_periodo,
                        nombre_plantel:response.data.nombre_plantel,
                        tipo_periodo:response.data.tipo_periodo,
                        nivel: response.data.nivel,
                        id_tipo_periodo: response.data.id_tipo_periodo
                    })
                    if(response.data.nombre_plantel === "UNIVERSIDAD"){
                        this.RecuperarEspecialidad()  
                    }
                    else{
                        this.RecuperarDocentes()
                        this.Asignaturas()
                    }
                }
            })
        }
     
       // this.VerificarPeriodoActivo()
    }

     /*VerificarPeriodoActivo = async() =>{
        PeriodosService.VerificarPeriodoById(this.state.periodo).then((response)=>{
            if(this.state.nombrePeriodo==="TALLER" || response.data.id_periodo!=="NoHayDatos"){
                this.RecuperarEspecialidad()  
            }
            else if(response.data.id_periodo==="NoHayDatos"){
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No hay periodo activo ',
                 });
            }
        })
    }*/

    RecuperarEspecialidad = async() =>{
        EspecialidadService.CarrerasByTPeriodo(this.state.id_tipo_periodo).then((res) => {
			this.setState({especialidades:res.data});
            console.log("especialidades ", res.data)
            var val_id = document.getElementById("id_especialidad").value;
            var val = document.getElementById("id_especialidad");
            var NombreEspecialidad = val.options[val.selectedIndex].text;
            this.setState({nombreEspecialidad: NombreEspecialidad, id_esp:val_id}); 
            this.Asignaturas()
            this.RecuperarDocentes()
      
        }); 
    }

    RecuperarDocentes = async()=>{
        
        DocentesService.DocenteActivos().then((res)=>{
            if(res.data.length>0){ 
                this.setState({docentes:res.data});
                console.log("docentes" , this.state.docentes)
            }
            else{
                this.setState({docentes:[]});
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No hay docentes registrados',
                  })
            }
            
        });
    }

    AsignaturasByTallerArea = async () =>{
       let i_a = document.getElementById("id_area").value
        AsignaturasService.AsignaturaTallerByArea(i_a).then((response)=>{
            this.setState({asignaturas:response.data})
        })
    }

    async Asignaturas (){
        let val_id=""
        if(this.state.nombre_plantel==="UNIVERSIDAD"){
            val_id = document.getElementById("id_especialidad").value;
        }
        else{
            val_id=this.state.id_plantel
        }
        AsignaturasService.AsignaturaNoAsignadas(val_id, this.state.nombre_plantel).then((res)=>{
            if(res.data.length>0){
                this.setState({asignaturas:res.data});
             }
             else{
                this.setState({asignaturas:[]});
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No hay materias registradas en la especialidad',
                 });
             }
        });
	}



    AgregarAsignatura(id_asignatura,Clave_asignatura,Creditos_Asignatura,nombre_asignatura){
        console.log("entra en agregar asignatura "+id_asignatura+" "+Clave_asignatura+" "+Creditos_Asignatura+" "+nombre_asignatura)
        let id_esp = ""
        let id_doce=document.getElementById("id_docente").value;
        if(this.state.nombre_plantel=== "UNIVERSIDAD"){
            id_esp=document.getElementById("id_especialidad").value;
            var val = document.getElementById("id_especialidad");
            var NombreEspecialidad = val.options[val.selectedIndex].text;

            id_doce=document.getElementById("id_docente").value;
            var valdoc = document.getElementById("id_docente");
            var NombreDocente = valdoc.options[valdoc.selectedIndex].text;
            this.setState({nombreDocente: NombreDocente,  nombreEspecialidad:NombreEspecialidad});
        }
        else{
            var valdoc = document.getElementById("id_docente");
            var NombreDocente = valdoc.options[valdoc.selectedIndex].text;
            this.setState({nombreDocente: NombreDocente});
        }
       
        this.state.asig.push({"id_especialidad":id_esp, "id_docente":id_doce,"id_asignatura":id_asignatura,"Clave_asignatura":Clave_asignatura,"Creditos_Asignatura":Creditos_Asignatura,"nombre_asignatura":nombre_asignatura});
        const listaentrante = this.state.asignaturas.filter(item => item.id_asignatura !== id_asignatura);
        this.setState({asignaturasAsignadas:this.state.asig, asignaturas:listaentrante});
    }

    delete (id_asignatura,Clave_asignatura,Creditos_Asignatura,nombre_asignatura) {
        const filtredData = this.state.asignaturasAsignadas.filter(item => item.id_asignatura !== id_asignatura);
        let asignaturasTemp = this.state.asignaturas;
        asignaturasTemp.push({"id_asignatura":id_asignatura,"Clave_asignatura":Clave_asignatura,"Creditos_Asignatura":Creditos_Asignatura,"nombre_asignatura":nombre_asignatura});
        this.setState({ asignaturasAsignadas: filtredData, asig:filtredData, asignaturas:asignaturasTemp });
    };

    Guardar(){
        DocentesService.AsignaturasDocente(this.state.asignaturasAsignadas).then((res)=>{
            if(res.data===1){
                    this.setState({asignaturasAsignadas:[], asig:[] });
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Se agregó correctamente el registro',
                      })
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No está seleccionado ninguna asignatura',
                  })
            }
        });
    }

    filtrarbynombre=(event)=>{
        var textNombre = event.target.value;
        const data = this.state.asignaturas;
        const newData = data.filter(function(item){
        const itemData = item.nombre_asignatura.toUpperCase()
        const textData = textNombre.toUpperCase()
        return itemData.indexOf(textData) > -1
        })
       
        if(textNombre === ""){
			this.Asignaturas();
        } else {
          this.setState({
            asignaturas: newData,
            textNombre: textNombre,
          })
        }
    }

    render(){
        return(
            <div className="bg-primary_2 text-white h-auto pb-20">
                  <div className='relative bg-primary_2 p-4 sm:p-6 rounded-sm overflow-hidden mb-8'>
						<div className='relative text-center'>
							<h1 className='text-2xl md:text-3xl text-white  font-bold mb-1 text-center'>
                            Asignación de materias a los docentes 
							</h1>
						</div>
					</div>

                    <div className=" w-full h-24 z-10 bg-primary_2 grid grid-cols-1 ">
                        <div className="col-span- w-full flex items-center">
                            <nav class="flex relative" aria-label="Breadcrumb">
                                <ol class="flex items-center pl-10">
                                    <li class="inline-flex items-center">
                                        <a href="/" class="inline-flex items-center text-sm dark:text-white group">
                                            <svg class="mr-2 w-4 h-4 text-white group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                            <a className="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Home</a >
                                        </a>
                                    </li>
                                    <li>
                                        <div class="flex items-center">
                                            <button >
                                            <NavLink to={{
                                                pathname: '/app/CargaAcademicoByPeriodos',}} 
                                                className="inline-flex sm:ml-3 mt-6 sm:mt-0 items-start justify-start px-1 py-1 bg-blue hover:bg-red focus:outline-none rounded transition transform duration-500 "> 
                                               <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                               <a  class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Nivel académico</a>
                                            </NavLink> 
                                            </button>
                                         </div>
                                    </li>
                                    <li>
                                        <div class="flex items-center">
                                            <svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                            <a class="ml-1 text-sm font-bold md:ml-2 dark:text-gray-400 font-barlow tracking-widest text-white">Asignación de asignaturas</a>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                
                    
                    <div className='grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 ml-10'>
                        {this.state.nivel ==="SUPERIOR" ? <div> 

                                                        <label className="flex item-start md:text-sm text-xs text-white font-semibold ">
                                                            Seleccionar especialidad
                                                        </label>

                                                        <select class="flex  text-black  items-end py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                                            name="id_especialidad"  
                                                            id="id_especialidad" 
                                                            onChange={this.change_especialidad}
                                                            required>                 
                                                            {
                                                                this.state.especialidades.map(
                                                                    (especialidades, index)=>
                                                                    
                                                                    <option key={index} value={especialidades.id_especialidad}>{especialidades.nombre_especialidad}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div> : " "
                        }
                        {
                            this.state.nivel==="" ? <div> 
                                <label className="flex item-start md:text-sm text-xs text-white font-semibold ">
                                    Seleccionar Area
                                </label>

                                <select class="flex  text-black  items-end py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                    name="id_area"  
                                    id="id_area" 
                                    onChange={this.change_area}
                                    required>                 
                                    {
                                        this.state.areas.map(
                                            (area, index)=>
                                            
                                            <option key={index} value={area.id_area}>{area.nombre_area}</option>
                                        )
                                    }
                                </select>

                            </div> : <div>
                                            <label className="flex item-start md:text-sm text-xs text-white font-semibold ">
                                               Grado académico
                                            </label>
                                            <p className="font-medium text-secondary text-2xl ">{this.state.nombre_plantel}</p>          
                                    </div>
                        }

                       <div>
                        <label className="flex item-start md:text-sm text-xs text-white font-semibold ">
                                Seleccionar Docente
                            </label>

                            <select class="flex items-end py-1 px-2 text-black rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent" 
                                name="id_docente"  
                                id="id_docente" 
                                onChange={this.change_docente}
                                required>
                           
                                {
                                    this.state.docentes.map(
                                        (docente, index)=>
                                        <option key={index} value={docente.id_docente}>{docente.nombre+" "+docente.app_paterno+" "+ docente.app_materno}</option>
                                    )
                                }
                            </select>
                       </div>
                    </div><br/><br/>


                <div className="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
                    <div>
                        <p className="font-semibold text-left pl-4 text-center text-lg">Lista de asignaturas</p>

                        <div className="ml-10">
                            <input class="form-control relative flex-auto px-8 py-1.5 text-base font-normal text-black bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none" 
                            type="search" name="caja_busqueda" id="caja_busqueda" 
                            placeholder="Ingresar nombre" title="Presione Esc para supender la búsqueda"  
                            onChange={(text) => this.filtrarbynombre(text)} />
                              <i class="fa fa-search ml-2" aria-hidden="true"></i>
                        </div>

                        <div class="px-2 md:px-10 md:pt-7 pb-5 overflow-y-auto">
                            <table className="w-full border-collapse table-auto my_table" id="tabla_asignaturas">
                                <thead className="h-10">
                                    <tr className=" bg-primary_2 text-white text-lg font-barlow tracking-widest">              
                                        <th className="font-semibold text-center pl-1 ">Clave_Asig</th>
                                        <th className="ml-2 font-semibold text-center pl-1 ">Créditos</th>
                                        <th className="font-semibold text-center pl-1 ">Asignatura</th>
                                        <th className="font-semibold text-center pl-1 "></th>
                                    </tr>
                                </thead>
                                    {  this.state.asignaturas.map(
                                        (asignatura, index)=>
                                        <tbody key={index}>
                                            <tr className="text-white bg-secondary_2 text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">

                                                <td className="pl-10">
                                                    <p className="font-medium text-white ">{asignatura.Clave_asignatura}</p>
                                                </td>
                                                <td className="pl-10">
                                                    <p className="font-medium text-white ">{asignatura.Creditos_Asignatura}</p>
                                                </td>
                                                <td className="pl-10">
                                                    <p className="font-medium text-left text-white ">{asignatura.nombre_asignatura}</p>
                                                </td>
                                                <td>
                                                    <div class="mr-2 transform hover:text-secondary hover:scale-110">
                                                        <button onClick={()=>this.AgregarAsignatura(asignatura.id_asignatura, asignatura.Clave_asignatura, asignatura.Creditos_Asignatura, asignatura.nombre_asignatura)} > 
                                                            <svg class="h-8 w-8 text-green-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  
                                                            stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"> 
                                                            <polyline points="13 17 18 12 13 7" /><polyline points="6 17 11 12 6 7" /></svg>                                                       
                                                        </button>  
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        )
                                    }
                            </table><br/><br/>
                        </div><br/>     
                    </div>

                    <div>
                        <div className="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
                            {
                                this.state.nombre_plantel=== "UNIVERSIDAD" ?
                                <div className="ml-8 text-lg">
                                    <strong>Especialidad seleccionado:</strong><h1 className="text-secondary">{" "+this.state.nombreEspecialidad}</h1>
                                </div> : ""
                            }
                           
                            <div className="ml-8 text-lg">
                                <strong>Docente seleccionado:</strong><h1 className="text-secondary">{" "+this.state.nombreDocente}</h1>
                               
                            </div>
                        </div><br/>
           
                        
                        <div class="px-2 md:px-10 md:pt-7 pb-5 overflow-y-auto">
                            <table className="w-full border-collapse table-auto my_table">
                                <thead className="h-10">
                                    <tr className=" bg-primary_2 justify-center text-white text-lg font-barlow tracking-widest">   
                                    <th className="font-semibold text-center pl-1 "></th>            
                                        <th className="font-semibold text-center pl-1 ">Clave_Asig</th>
                                        <th className="font-semibold text-center pl-1 ">Créditos</th>
                                        <th className="font-semibold text-center pl-1 ">Asignatura</th>
                                      
                                    </tr>
                                </thead>
                                    {  this.state.asignaturasAsignadas.map(
                                       (asignatura_Asignada, index)=>
                                        <tbody key={index}>
                                            <tr className="text-white bg-secondary_2 text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">
                                                <td className="pl-10">
                                                    <div class="mr-2 transform hover:text-purple-500 hover:scale-110">
                                                        <button onClick={()=> this.delete(asignatura_Asignada.id_asignatura, asignatura_Asignada.Clave_asignatura,asignatura_Asignada.Creditos_Asignatura,asignatura_Asignada.nombre_asignatura)} > 
                                                        <svg class="h-6 w-6 text-red-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                        </svg>
                                                   
                                                        </button>  
                                                    </div>
                                                </td>
                                                <td className="pl-10">
                                                    <p className="font-medium text-white">{asignatura_Asignada.Clave_asignatura}</p>
                                                </td>
                                                <td className="pl-10">
                                                    <p className="font-medium text-white">{asignatura_Asignada.Creditos_Asignatura}</p>
                                                </td>
                                                <td className="pl-10">
                                                    <p className="font-medium text-left text-white">{asignatura_Asignada.nombre_asignatura}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                        )
                                    }
                            </table><br/>
                        </div>

                        <div className=" flex flex-row-start sm:flex items-center flex items-center justify-center">
                            <button onClick={this.Guardar} class="bg-color1 text-lg hover:bg-secondary text-white font-bold py-3 px-5 rounded">
                                Asignar
                            </button> 
                        </div>
                    </div>
                </div><br/><br/>
            </div>
        );
    }
}