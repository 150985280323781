import axios from 'axios'; 
import {APIURL} from "./Host";


class PeriodoService{

    Lista_periodos(){
        return axios.get(APIURL + '/tipos_periodos');
    }

    Tipo_periodos(){
        return axios.get(APIURL + '/periodos_activos');
    }

    PeriodoActual(periodo_seleccionado){
        console.log(APIURL + '/periodo/'+periodo_seleccionado)
        return axios.get(APIURL + '/periodo/'+periodo_seleccionado);
    }

    AgregarPeriodo(periodo){
        return axios.post(APIURL + '/periodo',periodo);
    }

    /* CambiarStatus(status_cambiado){
        return axios.put(APIURL + '/status_parcial/'+status_cambiado);
    }*/

    CerrarPeriodo(tipo_periodo){
        return axios.get(APIURL+'/cerrar_periodo/'+tipo_periodo);
    }
    
  /*  Registros_Periodos(id_esp){
        console.log("url entrante "+ APIURL+"/registros_periodos/"+id_esp);
        return axios.get(APIURL+"/registros_periodos/"+id_esp);
    }*/

    All_fechasPeriodos(id_asig){
        console.log(APIURL+"fechas_periodos/"+id_asig);
        return axios.get(APIURL+"/fechas_periodos/"+id_asig);
    }
    
    EditarFechaParcial(id_tipo_evaluacion){
        return axios.get(APIURL+"/fecha_parcial/"+id_tipo_evaluacion);
    }

    UpdateFecha(id_tipo_evaluacion,fecha_inicio,fecha_fin){
        return axios.put(APIURL+"/update_fecha/"+id_tipo_evaluacion+"/"+fecha_inicio+"/"+fecha_fin);
    }

    Tipo_PeriodoNoActivo(){
        return axios.get(APIURL + '/periodo_no_activos');
    }

    All_periodos(){
        return axios.get(APIURL + '/all_periodos');
    }

    actualizapagos(datos){
        return axios.put(APIURL + '/actualizapagos',datos);
    }

    VerificarPeriodoById(id_periodo){
        return axios.get(APIURL+'/periodo_by_id/'+id_periodo)
    }

    PeridoActivoByPlantel(id_plantel){
        console.log(APIURL+'/periodo_actual_by_plantel/'+id_plantel)
        return axios.get(APIURL+'/periodo_actual_by_plantel/'+id_plantel)
    }


} export default new PeriodoService();