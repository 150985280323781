import axios from "axios";
import { APIURL } from "./Host";

class IndicadoresService
{
    TotalSemaforoRojo(id_periodo){
        return axios.get(APIURL+"/totalsemafororojo")
    }
    TotalSemaforoAmarillo(id_periodo){
        return axios.get(APIURL+"/totalsemaforoamarillo/"+id_periodo)
    }

    TotalSemaforoRojoUniversidad(id_periodo){
        return axios.get(APIURL+"/totalsemafororojoUniversidad")
    }

    IndicadorDetalleUniversidad(){
        const data={
            "id_usuario":1,
            "id_periodo_inicial":1,
            "id_periodo_final":1
        }
        return axios.post(APIURL+"/semaforoDetalleUniversidad",data)
    }
    IndicadorDetalle(datos){
        console.log("datos recibidos en el service",datos);
        const data={
            "id_usuario":datos.id_usuario,
            "id_periodo_inicial":datos.fecha_inicio,
            "id_periodo_final":datos.fecha_fin
        }
        return axios.post(APIURL+"/semaforoDetalle",data)
    }   
}
export default new IndicadoresService()