import { Component } from "react"
import { NavLink } from 'react-router-dom';
import AcademicoDocenteService from "services/AcademicoDocenteService";
import CalificacionesService from "services/CalificacionesService";
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class AgregarCalificacionDocente extends Component{
    constructor(props){
        super(props);
        this.state={
            id_docente:this.props.newParams? this.props.newParams.id_docente:"",
            id_asignatura:this.props.newParams? this.props.newParams.id_asignatura:"",
            id_especialidad:this.props.newParams? this.props.newParams.id_especialidad:"",
            nombre_asignatura:this.props.newParams? this.props.newParams.nombre_asignatura:"",
            nombre_especialidad:this.props.newParams? this.props.newParams.nombre_especialidad:"",
            id_asignatura_docente:this.props.newParams? this.props.newParams.id_asignatura_docente:"",
            Alumnos:[],
            periodos:[],
            modalAgregarCalif:false,
            Calificaciones:[],
            calif:'',
            total_alumnos:'',
        }
        this.ModalAgregarCalificacion=this.ModalAgregarCalificacion.bind(this);
        this.CalificarAlumnos=this.CalificarAlumnos.bind(this);
        this.change_tipoPeriodo=this.change_tipoPeriodo.bind(this);
        this.GuardarCalificaciones=this.GuardarCalificaciones.bind(this);
        this.ValidarFecha=this.ValidarFecha.bind(this);
    }

    change_tipoPeriodo=(event)=>{
        let id=document.getElementById("tipo_parcial").value;
        this.CalificarAlumnos(id);
    }



    componentDidMount(){
        CalificacionesService.CalificacionesActuales(this.state.id_asignatura).then((res)=>{
            this.setState({Alumnos:res.data.alumnos});
        });
    }

    ModalAgregarCalificacion(){
       AcademicoDocenteService.TipoParciales().then((res)=>{
            this.setState({periodos:res.data, modalAgregarCalif:true});
       })
    }

    CalificarAlumnos(){
        let id_tipo_eval=document.getElementById("tipo_parcial").value;
        let p=this.state.periodos;
        var fecha_i;
        var fecha_f;
        let nombre;
        /**
         * Recorrer el arreglo de periodos para obtener el rango de fechas que esta activo para agregar las calificaciones 
         */
       
        for(let i=0; i<p.length;i++){
            let a=p[i];
            if(a.id_tipo_evaluacion==id_tipo_eval){
               fecha_i=new Date(a.fecha_inicio);
               fecha_f=new Date(a.fecha_fin);
               nombre=a.Nombre;
            }
        }
       
      var today = new Date();
      var hoy=this.ValidarFecha(today.getFullYear(),(today.getMonth() + 1),today.getDate());
      var fecha_inicio=this.ValidarFecha(fecha_i.getFullYear() , (fecha_i.getMonth() + 1), (fecha_i.getDate()+1));
      var fecha_fin=this.ValidarFecha(fecha_f.getFullYear(), (fecha_f.getMonth() + 1), (fecha_f.getDate()+1));
   
        /**
         * Comparar si la fecha actual esta en el rango de fecha del parcial registrado en la bd
         */
            if(hoy>=fecha_inicio && hoy<=fecha_fin){
                if(nombre==="Titulo"){
                    //console.log("entra en titulo");
                    AcademicoDocenteService.FiltradoAlumnosTitulo(id_tipo_eval, this.state.id_asignatura_docente).then((res)=>{
                        let cal=res.data;

                        if(res.data!=""){
                                for(let j=0; j<=cal.length; j++){
                                    let c=cal[j];
                                    //console.log("titulo "+c.calificacion);
                                    if(c.Calificacion!=0){
                                        Swal.fire({
                                            icon: 'warning',
                                            title: 'warning',
                                            text: "Ya existen calificaciones en el tipo de evaluación",
                                        });
                                    
                                        break;
                                    }
                                    else{
                                        this.setState({Calificaciones:res.data});
                                        break;
                                    }
                                }
                        }
                        else{
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'warning',
                                    text: "No hay alumnos para calificar en titulo",
                                });
                        }
                })
            }
            else{
                AcademicoDocenteService.FiltradoAlumnosbyParcial(id_tipo_eval, this.state.id_asignatura_docente).then((res)=>{
                    let cal=res.data;
                    for(let j=0; j<=cal.length; j++){
                        let c=cal[j];
                        if(c.Calificacion!=0){
                            Swal.fire({
                                icon: 'warning',
                                title: 'warning',
                                text: "Ya existen calificaciones en el tipo de evaluación",
                             });
                             this.setState({Calificaciones:[]});
                             break;
                        }
                        else{
                            this.setState({Calificaciones:res.data});
                            break;
                        }
                    }
                })
            }
         }
         else{
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "EL registro de calificaciones no esta activo ",
             });
             this.setState({Calificaciones:[]});
         }
    }

    ValidarFecha(anio, mes, dia){
        console.log("recibe "+anio+" "+mes+" "+dia);
        var date;
        if(mes>10 && dia>10){
            date = anio + '-' + (mes) + '-' + dia; 
        }
        else if(mes<10 && dia<10){
            date = anio + '-0' + (mes) + '-0' + dia; 
        }
        else if(mes>10 && dia<10){
            date = anio + '-' + (mes) + '-0' + dia; 
        }
        else if(mes<10 && dia>10){
            date = anio + '-0' + (mes) + '-' + dia;   
        }
        return date;
    }


    GuardarCalificaciones=(event)=>{
        event.preventDefault();
        var array=[];
        var calif=this.state.Calificaciones;
        for(let i=0; i<calif.length; i++){
            array.push({id_asig_esp: document.getElementById("kardex"+i).value, calificacion:document.getElementById("id"+i).value});
        }
        AcademicoDocenteService.GuardarCalificaciones(array).then((res)=>{
            //console.log("res.data "+res.data);
            if(res.data>0){
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Calificaciones agregados correctamente',
                 });
                this.setState({modalAgregarCalif:false, Calificaciones:[]});
                this.componentDidMount();
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al agregar el registro',
                 });
            }
        })
    }


   goBack=()=> {
        this.props.history.push("/app/AcademicoDocente");
    }

    render(){
        return(
            <>
                    <div className="flex justify-end mx-10 ">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white dark:text-gray-400 ">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
                                
                                <li>
									<div class="mt-2 flex items-center">
                                            <button >
                                            <NavLink to={{
                                                pathname: '/app/AcademicoDocente',
                                                    state: { 
                                                    id_docente:this.state.id_docente,
                                                    id_especialidad:this.state.id_especialidad,
                                                    nombre_especialidad:this.state.nombre_especialidad,
                                                    }
                                                }} 
                                            className=" inline-flex sm:ml-3 mt-6 sm:mt-0 items-start justify-start px-1 py-1 bg-blue hover:bg-red focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110"> 
                                               <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                               <a  class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-400 ">Asignaturas</a>
                                            </NavLink> 
                                            </button>
									</div>
								</li>

							</ol>
						</nav>
					</div><br/>

                    <div className="relative text-center">
                        <h1 className="text-2xl md:text-3xl text-white font-bold mb-10 text-center">
                         Calificaciones en curso
                        </h1>
                    </div>

                    <div className='grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 '>
                        <div>
                            <h1 className='ml-10 mb-2 font-bold text-white text-lg'>
                                 Especialidad
                            </h1>
                            <p class='ml-10 mb-2 font-semibold text-gray-200 text-lg  '>
                                {" "}
                                {this.state.nombre_especialidad}
                            </p>                          
                        </div>

                        <div>
                            <h1 className='ml-10 mb-2 font-bold text-white text-lg'>
                                Asignatura
                            </h1>
                            <p class='ml-10 mb-2 font-semibold text-gray-200 text-lg  '>
                                {" "}
                                {this.state.nombre_asignatura}
                            </p>       
                           
                        </div>
                    </div><br/>

                   

                    <div className="flex flex-row-reverse mr-20">
                    <button onClick={()=>this.ModalAgregarCalificacion(this.state.id_especialidad)}   className="miboton">Agregar Calificación 
                    </button>                 
					</div>
                    

                <div className="mx-8 my-8 mr-8 overflow-x-auto ">
                    <div className=" px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
                    <table className="w-full whitespace-nowrap my_table">
                            <thead className="h-10">
                                    <tr>
                                        <th className="border border-slate-300">Nombre del alumno<br/>
                                        </th>
                                        <th className="border border-slate-300">Correo electrónico<br/>
                                        </th>
                                        <th className="border border-slate-300">Parcial 1</th>
                                        <th className="border border-slate-300">Parcial 2</th>
                                        <th className="border border-slate-300">Ordinario</th>
                                        <th className="border border-slate-300">Titulo</th>
                                    </tr>
                                </thead>
                                    {
                                        this.state.Alumnos.map(
                                            alumno =>
                                            <tbody >
                                                <tr className="h-20 text-sm leading-none text-gray-800 group border border-slate-300">
                                                    <td className="pl-5">
                                                    <p className="font-medium">{alumno.nombre+" "+alumno.app_paterno+" "+alumno.app_materno}</p>
                                                    </td>
                                                    <td className="pl-5">
                                                    <p className="font-medium">{alumno.correo_electronico}</p>
                                                    </td>
                                                    {
                                                        alumno.calificaciones.map(
                                                            parcial=>
                                                            <td className="pl-5 text-center">
                                                            <p className="font-medium">{parcial.calificacion}</p>
                                                            </td>    
                                                        )
                                                    }
                                                </tr>
                                        </tbody>
                                        )
                                    }
                            </table>
                        </div>
                   </div>
               

                {this.state.modalAgregarCalif ?(
                        <>
                         <form onSubmit={this.GuardarCalificaciones}>
                             <div div class="overflow-y-auto h-32">
                                <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                    <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                        {/*content*/}
                                        <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                            {/*header*/}
                                            <div className='rounded-t-3xl'>
                                                <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Calificar</p>
                                            </div>
                                            {/*body*/}
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-8 mt-5 "><div/>
                                                <div>
                                                    <label className="md:text-sm text-xs text-gray-500 text-light text-center font-semibold">
                                                        Calificar:
                                                    </label><br/>

                                                    <select class="py-2 px-3 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                                                    name="tipo_parcial"  
                                                    id="tipo_parcial" 
                                                    
                                                    onChange={this.change_tipoPeriodo}
                                                    required>
                                                         <option >---</option>
                                                        {
                                                        this.state.periodos.map(
                                                            periodo=>
                                                                <option value={periodo.id_tipo_evaluacion}>{periodo.Nombre}</option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                              
                                            </div> <br/>

                                            <div className="px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">

                                                    <div class='grid grid-cols-2 gap-2'>
                                                        <div>
                                                            <strong>Nombre del alumno</strong>
                                                        </div>
                                                        <div>
                                                            <strong>Calificación</strong>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                        {
                                                            this.state.Calificaciones.map(function(calificacion, i){
                                                                return (
                                                                    <div class='grid grid-cols-2 gap-2'>
                                                                        <div className="mt-1">
                                                                            <p> {calificacion.nombre+" "+calificacion.app_paterno+" "+calificacion.app_materno} </p>
                                                                        </div>
                                                                       <div>
                                                                        <input
                                                                                className=" rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
                                                                                type="number"
                                                                                step="0.1" 
                                                                                name="calif"
                                                                                id={"id"+i}
                                                                                placeholder={calificacion.Calificacion}
                                                                     
                                                                            />
                                                                       </div>

                                                                        <input
                                                                            className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out"
                                                                            type="number"
                                                                            step="0.1" 
                                                                            name="calif"
                                                                            id={"kardex"+i}
                                                                            hidden="hidden"
                                                                            value={calificacion.id_asig_especialidad}
                                                                            required  />

                                                                    </div>
                                                                )
                                                                
                                                            })

                                                        }
                                         
                                            </div>


                                            {/*footer*/}
                                            <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                            <div>   
                                                <button
                                                    type="submit"
                                                    className="focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110"
                                                    >
                                                    Guardar
                                                </button>
                                            </div>	
                                            <div>    
                                                <button
                                                    className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                    type='button'
                                                    onClick={() => this.setState({modalAgregarCalif:false, Calificaciones:[]})}>
                                                    Cancelar
                                                </button>   
                                            </div>			
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={() => this.setState({modalAgregarCalif:false, Calificaciones:[]})} className='opacity-25 fixed inset-0 bg-black cursor-pointer'></div>
                                </div>
                      
                                </div>
                         </form>
                        </>
                    ):null}
            </>
        )
    }
}
