import axios from 'axios'; 

import {APIURL} from "./Host";
  
class TemasService{

    SubtemasbyTema(id_tema){
        console.log("url "+APIURL + "/subtemas_by_tema/"+id_tema);
        return axios.get(APIURL + "/subtemas_by_tema/"+id_tema);
    }

    SubtemasActivosbyTema(id_tema){
        console.log("url "+APIURL + "/subtemas_activos_by_tema/"+id_tema);
        return axios.get(APIURL + "/subtemas_activos_by_tema/"+id_tema);
    }
    
    NuevoSubtema(subtema){
        console.log("nueva tema ",subtema);
        return axios.put(APIURL+"/nuevo_subtema",subtema, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }});
    }

    EditarSubtema(subtema){
        console.log("nueva tema ",subtema);
        return axios.put(APIURL+"/editar_subtema",subtema, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }});
    }

    EliminarSubtema(subtema) {
        return axios.put(APIURL + "/eliminar_subtema/" + subtema);
    }
}
export default new TemasService();
