import { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import EspecialidadService from "services/EspecialidadService";
import GradoAcademicoService from "services/GradoAcademicoService";
import PeriodosService from "services/PeriodosService";
import swal from 'sweetalert2/dist/sweetalert2.js'

class NuevaEspecialidad extends Component{
    constructor(props){
        super(props);
        this.state={
            id_especialidad:'',
            nombre_especialidad:'',
            clave_especialidad:'',
            creditos:'',
            total_asignaturas:'',
            total_horas_academico:'',
            total_horas_ind:'',
            gradoacademico_id:'',
            tipo_periodo:'',
            grado_academico:'',
            total_periodo:' ',
            Tipo_periodos:[],
            Grado_academico:[],
        }
        this.RegistrarEspecialidad=this.RegistrarEspecialidad.bind(this);
    }

    componentDidMount(){
        PeriodosService.Lista_periodos().then((res)=>{
           // console.log("periodos ", res.data);
			this.setState({Tipo_periodos:res.data});
            //console.log("peirodos ", this.state.Tipo_periodos);
		});
        GradoAcademicoService.ListaGradoAcademico().then((res)=>{
            this.setState({Grado_academico:res.data})
            //console.log("grados ", res.data);
        })
    }

    RegistrarEspecialidad=(event)=>{
        event.preventDefault();
        let id_grado=document.getElementById("gradoacademico_id").value;
        let t_periodo=document.getElementById("id_periodo").value;
        let especialidad={
            nombre_especialidad:this.state.nombre_especialidad,
            clave_especialidad:this.state.clave_especialidad,
            creditos:this.state.creditos,
            total_asignaturas:this.state.total_asignaturas,
            total_horas_academico:this.state.total_horas_academico,
            total_horas_ind:this.state.total_horas_ind,
            gradoacademico_id:id_grado,
            tipo_periodo_id:t_periodo,
            total_periodo:this.state.total_periodo
        }
        console.log("especialidad a enviar ",especialidad);
        EspecialidadService.NuevaEspecialidad(especialidad).then((res)=>{
            if(res.data===1){
                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Datos agregados correctamente',
                });
                this.props.history.push("/app/ListaEspecialidades");
            }
            else{
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'La clave de la nueva especialidad ya existe',
                });
            }
        })
    }

    render(){
        return(
            <>
                <form  onSubmit={this.RegistrarEspecialidad}>
                    <div className="relative bg-white p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
                        <div className="relative text-center">
                            <h1 className="text-2xl md:text-3xl text-color1 font-bold mb-1 text-center">
                              Nueva especialidad
                            </h1>
                        </div>
                    </div>

                      
                    <div className=" grid justify-items-center  overflow-x-auto">    
                        <div className=" w-3/4  bg-color4 shadow-sm2 px-5 py-2 rounded-3xl "><br/>
                            
                            <div className='grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 '>
                                    <div >
                                        <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                            Nombre
                                            <span className='text-red-500 text-xl'>*</span><br/>
                                        </label>
                                        <input
                                            className='w-full uppercase rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                            type='text'
                                            name='nombre'
                                            onChange={event => this.setState({nombre_especialidad:event.target.value})}
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label className='ml-10 md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                            Clave
                                            <span className='text-red-500 text-xl'>*</span><br/>
                                        </label>
                                        <input
                                            className='ml-10 w-5/6 uppercase rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                            type='text'
                                            name='clave_especialidad'  
                                            onChange={event=>this.setState({clave_especialidad:event.target.value})}
                                            required
                                        />
                                    </div>
                            </div><br/>

                            <div className='grid grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4  md:grid-cols-4 sm:grid-cols-4 '>
                                    <div>
                                        <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                            Créditos
                                            <span className='text-red-500 text-xl'>*</span><br/>
                                        </label>
                                        <input
                                            className='mt-2 w-5/6  rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                            type='number'
                                            name='creditos'
                                            onChange={event=>this.setState({creditos:event.target.value})}
                                            required
                                        />
                                    </div>
                                <div>
                                    <label className='ml-2 md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                    Total de asignaturas
                                        <span className='text-red-500 text-xl'>*</span><br/>
                                    </label>
                                    <input
                                        className='mt-2 w-5/6  ml-2 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                        type='number'
                                        name='total_asignaturas'
                                        onChange={event => this.setState({total_asignaturas:event.target.value})}
                                        required
                                    />
                                </div>

                                <div >
                                    <label className='ml-4 md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Total de horas académico
                                        <span className='text-red-500 text-xl'>*</span><br/>
                                    </label>
                                    <input
                                        className='mt-2 w-5/6 ml-4 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                        type='number'
                                        name='total_horas_academico'
                                        onChange={event=>this.setState({total_horas_academico:event.target.value})}
                                        required
                                    />
                                </div>

                                <div>
                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Total de horas independientes
                                        <span className='text-red-500 text-xl'>*</span><br/>
                                    </label>
                                    <input
                                        className='mt-2 w-5/6 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                        type='number'
                                        name='total_horas_ind'
                                        onChange={event=>this.setState({total_horas_ind:event.target.value})}
                                        required
                                    />
                                </div>

                            </div><br/>

                            <div className='grid grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-3'>
                                <div>
                                    <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                        Seleccionar el grado académico
                                        <span className='text-red-500 text-xl'>*</span><br/>
                                    </label>

                                    <select class="mt-2 w-5/6  py-2 px-3 flex items-end py-1 px-2 rounded-lg border-2 border-color1 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                                        name="gradoacademico_id"  
                                        id="gradoacademico_id" 
                                        onChange={event=>this.setState({gradoacademico_id:event.target.value})}
                                        required>
                                        {
                                        this.state.Grado_academico.map(
                                            grado=>
                                                <option value={grado.id_grado_ac}>{grado.grado_academico}</option>
                                            )
                                        }
                                    </select>
                                </div>

                                <div>
                                    <label className="flex item-start md:text-sm text-xs text-gray-500 text-black font-semibold ">
                                    Escoger tipo de periodo<span className="text-red-500 text-xl">
                                        <span className='text-red-500 text-xl'>*</span><br/></span><br/>
                                        </label>
                                    <select class="mt-2 w-5/6  ml-2 py-2 px-3 rounded-lg border-2 border-color1  focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
                                    name="id_periodo"  
                                    id="id_periodo" 
                                    onChange={event=>this.setState({})}
                                    required>
                                        {
                                        this.state.Tipo_periodos.map(
                                            tip_periodos=>
                                                <option value={tip_periodos.id_tipo_periodo}>{tip_periodos.tipo_periodo}</option>
                                            )
                                        }
                                    </select>
                                </div>
                        
                                <div >
                                    <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                        Total de periodos
                                        <span className='text-red-500 text-xl'>*</span><br/>
                                    </label>
                                    <input
                                        className='mt-2 w-5/6  ml-2 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                        type='number'
                                        name='total_periodos'
                                        onChange={event=>this.setState({total_periodo:event.target.value})}
                                        required
                                    />
                                </div>
                            </div><br/>
                        </div>
                    </div>

            
                        <div className="flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5">
                            <div>    
                                <button
                                    type="submit"
                                    className="focus:outline-none focus:ring-2 w-auto bg-primary hover:bg-primary rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" >
                                    Guardar
                                </button>
                            </div>	
                            <div>    
                                <button
                                    onClick={()=>{this.props.history.push("/app/ListaEspecialidades");}}
                                    className="focus:outline-none focus:ring-2 w-auto bg-secondary hover:bg-secondary rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110"
                                >
                                    Cancelar
                                </button>
                            </div>											
                        </div>
              
   
                </form>
            </>
        )
    }

}
export default withRouter(NuevaEspecialidad);