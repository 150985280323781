import { Component } from "react";
import VideosService from "services/VideosService";
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert2'
import open from "../images/open.png"
import { withRouter } from "react-router-dom";
import { APIURL } from "services/Host";

class ListaVideos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id_asignatura: sessionStorage.getItem("id_asignatura"),
            nombre_asignatura: sessionStorage.getItem("nombre_asignatura"),
            id_tema: sessionStorage.getItem("id_tema"),
            nombre_tema: sessionStorage.getItem("nombre_tema"),
            id_subtema: sessionStorage.getItem("id_subtema"),
            nombre_subtema: sessionStorage.getItem("nombre_subtema"),
            id_video: '',
            nombre_video: '',
            descripcion: '',
            videos: [],
            modalArchivo: false,
            modalEliminarVideo: false,
            id_video_eliminar: '',
            url: "",
            poster:""
        }

    }

    ModalVideo(id_video, poster) {
        this.state.url = VideosService.url() + "/get_video/" + id_video;
        this.setState({ modalArchivo: !this.state.modalArchivo });

    }

    ModalEliminarVideo(id_video) {
        this.setState({
            modalEliminarVideo: !this.state.modalEliminarVideo,
            id_video_eliminar: id_video
        });

    }

    EliminarVideo() {
        var video = new URLSearchParams();

        VideosService.EliminarVideo(this.state.id_video_eliminar).then((res) => {
            if (res.data === 1) {
                this.componentDidMount();
                this.setState({
                    modalEliminarVideo: false,
                    id_video_eliminar: 0
                });
            }
            else {
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Ocurrio un error intentelo de nuevo',
                });
            }

        });
    }

    componentDidMount() {
        VideosService.VideosbySubtema(this.state.id_subtema).then((res) => {

            if (res.data.length > 0) {
                this.setState({ videos: res.data, videosTemp: res.data });
            }
            else {
                swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: 'No existen videos ',
                });
                this.setState({ videos: [], videosTemp: [] });
            }
        })
    }

    goBack = () => {
        this.props.history.push("/app/ListaEspecialidadesCarga");
    }

    goBackAsignaturas = () => {
        this.props.history.push("/app/ListaAsignaturasCarga");
    }

    goBackTemas = () => {
        this.props.history.push("/app/ListaTemas");
    }

    goBackSubtemas = () => {
        this.props.history.push("/app/ListaSubtemas");
    }

    irEditarVideo = async (id_video, nombre_video) => {
        sessionStorage.setItem("id_video", id_video)
        sessionStorage.setItem("nombre_video", nombre_video)
        this.props.history.push("/app/editarVideo")
    }

    render() {
        return (
            <div className="bg-primary_2 h-auto pb-20">
                <div className="flex justify-start mx-10 mt-4">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1">
                            <li class="flex justify-end">
                                <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Home
                                </a>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de especialidades y licenciaturas</a>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBackAsignaturas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de asignaturas</a>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBackTemas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de temas</a>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a onClick={this.goBackSubtemas} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Lista de subtemas</a>
                                </div>
                            </li>
                            <li aria-current="page">
                                <div class="flex items-center">
                                    <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Lista de videos</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="mr-10  sm:flex lg:flex grid grid-cols-1 justify-between ">
                    <div className="flex justify-start mx-20">
                        <div class="xl:w-96 mt-8">
                            <div class="input-group relative flex flex-wrap items-stretch w-full mb-4 ">
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 flex flex-items-reverse px-10 py-1 ">
                        <NavLink class="flex items-reverse" to={{
                            pathname: '/app/NuevoVideo',
                        }}>
                            <div className="inline-flex sm:ml-3 sm:mt-0 px-6 py-1 bg-color1 hover:bg-color1 focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110">
                                <p className="text-sm font-medium leading-none text-white mt-1 mr-2 ml-2"><i class="fas fa-plus-circle"></i> Nuevo video</p>
                            </div>
                        </NavLink>
                    </div>
                </div>
                <div className="relative text-center">
                    <h5 className="text-2xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest">
                        {this.state.nombre_subtema}
                    </h5>
                </div>
                <div className="mx-8 my-8 mr-8 overflow-x-auto ">
                    <div className=" px-2 md:px-10 md:pt-7 pb-5 overflow-y-auto">
                        <table className="bg-color4 w-full border-collapse border border-slate-400 table-auto my_table" id="Tabla_alumnos">
                            <thead className="h-10">
                                <tr>
                                    <th className="font-semibold text-center text-lg">Video</th>

                                    <th className="font-semibold text-center text-lg">Extensión</th>

                                    <th className="font-semibold text-center text-lg">Imagen</th>

                                    <th className="font-semibold text-center text-lg">Editar</th>

                                    <th className="font-semibold text-center text-lg">Eliminar</th>

                                    <th className="font-semibold text-center text-lg">Ver</th>
                                </tr>
                            </thead>
                            {
                                this.state.videos.map(
                                    (video, index) =>
                                        <tbody key={index} >
                                            <tr>
                                                <td >
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{video.Nombre_Video}</h1>
                                                </td>
                                                <td >
                                                    <h1 className="font-semibold text-primary text-left pl-5 text-sm">{video.extension}</h1>
                                                </td>
                                                <td >
                                                    {video.ExtensionImagen != "" &&
                                                        <img class="rounded-full w-40 h-40" src={APIURL + '/video/get_imagen/' + video.ID_Video + "?" + performance.now()} alt={video.Descripcion}></img>
                                                    }
                                                </td>

                                                <td >
                                                    <button className="text-green-500 h-8 w-8 text-2xl" onClick={() => this.irEditarVideo(video.ID_Video, video.Nombre_Video)}>
                                                        <i class="fas fa-edit"></i>
                                                    </button>
                                                </td>

                                                <td >
                                                    <button className="text-green-500 h-8 w-8 text-2xl" onClick={() => this.ModalEliminarVideo(video.ID_Video)} >
                                                        <i class="fas fa-ban"></i>
                                                    </button>
                                                </td>

                                                <td >
                                                    <div class="flex justify-center items-center">
                                                        <div class="mt-2 ml-3 transform hover:text-purple-500 hover:scale-110">
                                                            <button onClick={() => this.ModalVideo(video.ID_Video, APIURL + '/video/get_imagen/' + video.ID_Video + "?" + performance.now())} title="Ver video">
                                                                <svg class="h-7 w-7 text-alternative1" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />  <circle cx="12" cy="12" r="3" /></svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>

                                            </tr>
                                        </tbody>
                                )
                            }
                        </table>
                    </div>
                </div>
                {this.state.modalArchivo ? (
                    <>
                        <div div class="overflow-y-auto h-32">
                            <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                    {/*content*/}
                                    <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                        {/*header*/}
                                        <div className='rounded-t-3xl'>
                                            <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Ver video</p>
                                        </div>
                                        {/*body*/}

                                        <div className='relative flex-auto'>
                                            <div class='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                                <div className="shadow-sm2 px-5 py-2 rounded-3xl">
                                                    <div>
                                                        <video
                                                          id="vistaVideo"
                                                          title="Video"
                                                          width="100%"
                                                          height="400"
                                                          controls
                                                          controlsList="nodownload"
                                                          oncontextmenu="return false;"
                                                          buffered
                                                        >
                                                            <source src={this.state.url} type="video/mp4"></source>
                                                        </video>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*footer*/}
                                        <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                            <button
                                                className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                type='button'
                                                onClick={() => this.setState({ modalArchivo: false })}>
                                                Cerrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div onClick={() => this.setState({ modalArchivo: false })} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
                            </div>

                        </div>
                    </>
                ) : null}
                {this.state.modalEliminarVideo ? (
                    <>
                        <div div class="overflow-y-auto h-32">
                            <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                                    {/*content*/}
                                    <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>
                                        {/*header*/}
                                        <div className='rounded-t-3xl'>
                                            <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Eliminar video</p>
                                        </div>
                                        {/*body*/}

                                        <div className='relative flex-auto'>
                                            <div class='grid grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1'>
                                                <div className="shadow-sm2 px-5 py-2 rounded-3xl">
                                                    <div>
                                                        <label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
                                                            ¿Está seguro de desea eliminar el video?
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*footer*/}
                                        <div className='flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b'>
                                            <button
                                                className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                type='button'
                                                onClick={() => this.EliminarVideo()}
                                            >
                                                Aceptar
                                            </button>
                                            <button
                                                className='text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                                                type='button'
                                                onClick={() => this.setState({ modalEliminarVideo: false })}>
                                                Cerrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div onClick={() => this.setState({ modalEliminarVideo: false })} className='opacity-25 fixed inset-0 cursor-pointer bg-black'></div>
                            </div>

                        </div>
                    </>
                ) : null}
            </div>
        )
    }
}
export default withRouter(ListaVideos);