import axios from 'axios';

import { APIURL } from "./Host";

class VideosService {

    VideosbySubtema(id_subtema) {
        console.log("url " + APIURL + "/videos_by_subtema/" + id_subtema);
        return axios.get(APIURL + "/videos_by_subtema/" + id_subtema);
    }

    VideosActivosbySubtema(id_subtema) {
        console.log("url " + APIURL + "/videos_activos_by_subtema/" + id_subtema);
        return axios.get(APIURL + "/videos_activos_by_subtema/" + id_subtema);
    }

    NuevoVideo(video) {
        return axios.put(APIURL + "/nuevo_video", video, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    EditarVideo(video) {
        return axios.put(APIURL + "/editar_video", video, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    EliminarVideo(video) {
        return axios.put(APIURL + "/eliminar_video/" + video);
    }

    url() {
        return APIURL;
    }
}
export default new VideosService();
